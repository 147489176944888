import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
    baseURL: apiUrl,
  });
  
  const authToken = localStorage.getItem('token');

  const headers = {
      'Authorization': `Bearer ${authToken}`
    };
  
/**
 * get all fformation type
 */

export const typeFormationData = async () => {

    try {
        const response = await apiService.get('/typeformations').then(res => {
            console.log(res.data)
            return res.data
        });
    
        return response;
    } catch (error) {
        throw error;
    }

}

export const typeFormationSingle = async (id) =>{
    try {
        const response = await apiService.get(`/typeformation/${id}`).then(res => {
            console.log(`single data: ${(res.data)}`)
            return (res.data)
        });
        return response;
    } catch (error) {
        console.log(`single data: ${error}`)
        return error
    }
}

export const addTypeFormationService = async (nom) =>{
    try {
        const response = await apiService.post('typeformation/store',{nom: nom},{headers}).then((res) =>{
            console.log(res.data);
            return res.data
        })
        return response
    } catch (error) {
        return error
    }
}

// update the type fo thhe formation

export const updateTypeFormationService = async (data)=>{
    try {
        const response = await apiService.put(`typeformation/update/${data.id}`,{nom: data.nom},{headers}).then(res =>{
            console.log('data update',res.data);
            return res.data
        })
        return response
    } catch (error) {
        return error
    }
}

//delete formation

export const deleteTypeFormationService = async (id) =>{

    try {
        const response = await apiService.delete(`typeformation/delete/${id}`,{headers}).then(res =>{
            console.log(res.data);
            return res.data
        })
        return response
    } catch (error) {
        return error
    }

}

//get session by 

export const sessionPasseTypeFormationService = async (id) =>{

    try {
        const response = await apiService.get(`typeformation/session/passe/${id}`).then(res =>{
            console.log(res.data);
            return res.data
        })
        return response
    } catch (error) {
        return error
    }

}

export const sessionAvenirTypeFormationService = async (id) =>{

    try {
        const response = await apiService.get(`typeformation/session/avenir/${id}`).then(res =>{
            console.log(res.data);
            return res.data
        })
        return response
    } catch (error) {
        return error
    }

}