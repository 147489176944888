import React from 'react'
import NavbarAdmin from './global-components/Navbar-admin'
import PageHeader from './global-components/page-header'
import CitationsData from './section-components/citations-data'

const  Citations =  ()=> {
  return (
    <div>
        <NavbarAdmin />
        <PageHeader headertitle="Citations" />
        <CitationsData />
    </div>
  )
}

export default Citations