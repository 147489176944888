import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CategorieContext } from '../../context/CategorieContext';
import { LazyLoadImage } from "react-lazy-load-image-component";


class FormationsData extends Component {
    static contextType = CategorieContext;
    state = {
        currentPage: 1,
        itemsPerPage: 12, // Définir le nombre d'éléments par page
    };

    render() {
        const { currentPage, itemsPerPage } = this.state;
        const { formationDatas } = this.context; // Accès directement depuis le contexte
        const apiUrl = process.env.REACT_APP_API_BASE;

        // Filtrer les formations avec showformation === 1
        const filteredFormations = formationDatas.filter(formation => formation.showformation === 1);

        // Calculer l'indice du premier et du dernier élément à afficher
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;

        // Découper le tableau des formations filtrées pour n'afficher que celles de la page actuelle
        const displayedItems = filteredFormations.slice(indexOfFirstItem, indexOfLastItem);

        // Générer les éléments à afficher
        const renderedItems = displayedItems.map((formation, index) => (
            <div className="col-lg-4 col-md-4" key={`formations${index}`}>
                <div className="single-course-inner">
                    <a href={`/course-details/${formation.id}`}>
                        <div className="thumb">
                        <LazyLoadImage
                                src={apiUrl + `${formation.image_url}`}
                                alt="img"
                                effect="blur"
                                width="100%"
                                height="auto"
                            />
                        </div>
                        <div className="details">
                            <div className="details-inner">
                                <h6>{formation.nom}</h6>
                            </div>
                            <div className="emt-course-meta">
                                <div className="row">
                                    <div className="col-8">
                                        <div className="rating">
                                            <i className="fa fa-shopping-basket" /><span>Payable par tranches: </span>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="price text-right">
                                            <span>{formation.mode_paiement ? 'OUI' : 'NON'}</span>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="rating">
                                            <i className="fa fa-clock-o" />{formation.duration}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="price text-right">
                                            voir plus
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        ));

        // Générer les numéros de page pour la pagination
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(filteredFormations.length / itemsPerPage); i++) {
            pageNumbers.push(
                <li key={i} className={currentPage === i ? 'active' : ''}>
                    <Link to="#" onClick={() => this.setState({ currentPage: i })}>
                        {i}
                    </Link>
                </li>
            );
        }

        return (
            <div className="blog-area">
                <div className="container">
                    <div className="row">
                        {renderedItems}
                        <div className="col-12">
                            <nav className="td-page-navigation text-center">
                                <ul className="pagination ">
                                    <li className="pagination-arrow">
                                        <Link to="#" onClick={() => this.setState({ currentPage: currentPage - 1 })} disabled={currentPage === 1}>
                                            <i className="fa fa-angle-double-left" />
                                        </Link>
                                    </li>
                                    {pageNumbers}
                                    <li className="pagination-arrow">
                                        <Link to="#" onClick={() => this.setState({ currentPage: currentPage + 1 })} disabled={currentPage === Math.ceil(filteredFormations.length / itemsPerPage)}>
                                            <i className="fa fa-angle-double-right" />
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        {/* Contenu de la barre latérale */}
                    </div>
                </div>
            </div>
        );
    }
}

export default FormationsData;
