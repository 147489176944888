import { useParams } from "react-router-dom";
import NavbarAdmin from "../../global-components/Navbar-admin";
import InscritData from "./inscrits-data";
import PageHeader from "../../global-components/page-header";

const ListInscrit = () => {
  const { id } = useParams();
  return (
    <div>
      <NavbarAdmin />
      <PageHeader headertitle="Inscriptions" />
      {id ? <InscritData id={id} /> : <InscritData />}
    </div>
  );
};

export default ListInscrit;
