import React, { Component } from 'react';
import { login, verifyEmail } from '../../services/login/service';
import { Navigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Button } from 'react-bootstrap';

class Signin extends Component {
  constructor(props) {
    super(props);
    // Initialize state
    this.state = {
      email: '',
      password: '',
      isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',// Add a flag to track login status
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  handleLogin = async () => {
    const { email, password } = this.state;

    try {
      const result = await login(email, password);
      const token = result.token.token;
      // Store the token in local storage or state for future requests
      localStorage.setItem('token', token);
	  // Set isLoggedIn flag to true in localStorage
      localStorage.setItem('isLoggedIn', 'true');
      // Update the state to reflect the login status
      this.setState({ isLoggedIn: true });
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  render() {
    const { isLoggedIn } = this.state;

    const verifyEmailAlert = () => {

      
      Swal.fire({
        title: "Saisir votre email",
        input: "email",
        inputAttributes: {
          autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "Verifier",
        showLoaderOnConfirm: true,
        cancelButtonText: "Annuler",
        
        preConfirm: async (inputEmail) => {
          console.log('email saisi:',inputEmail);
          try {
            const response = await verifyEmail(inputEmail);
            console.log('response :',response);
            if (!response.message) {
              throw new Error(JSON.stringify(await response.json()));
            }
            return response;
          } catch (error) {
            Swal.showValidationMessage(`Aucun compte utilisateur actif n'est associé ${inputEmail}`);
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: "success",
            title: "Veuillez le consulter pour effectuer la mise à jour.",
            showConfirmButton: false,
            timer: 4000,
            showClass: {
              popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `
            },
            hideClass: {
              popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
              `
            }
          });
        }
      });
    };
    const stylebutton = {
			fontSize: '14px',
            height: '26px',
            lineHeight: '27px',
            color: '#212529',
            backgroundColor: '#ffc600',
            borderRadius: '16px',
        
		}
    // If logged in, redirect to the admin dashboard
    if (isLoggedIn) {
      return <Navigate to="/dashboard-admin" />;
    }

    return (
      <div className="signin-page-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7">
              <form className="signin-inner">
                <div className="row">
                  <div className="col-12">
                    <div className="single-input-inner style-bg-border">
                      <input type="email" value={this.state.email} onChange={this.handleEmailChange} placeholder="Email" />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="single-input-inner style-bg-border">
                      <input type="password" value={this.state.password} onChange={this.handlePasswordChange} placeholder="Mot de passe" />
                    </div>
                  </div>
                  <div className="col-12 mb-4">
                    <button type="button" className="btn btn-base w-100" onClick={this.handleLogin}>Se connecter</button>
                  </div>
                  <div className="col-12">
                  <Button className="btn btn-base btn-radius" onClick={verifyEmailAlert} style={stylebutton}>Mot de passe oublié</Button>
                    <a className="ml-2" href="sign-up"><strong>Créer un compte</strong></a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Signin;
