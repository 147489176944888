import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { useCategorieContext } from '../../../context/CategorieContext';
import { deleteTypeFormationService, typeFormationData } from '../../../services/typeformation/service';
import Swal from 'sweetalert2';
import AddTypeFormation from './modal/add-type-formation';
import UpdateTypeFormation from './modal/update-type-formation';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default function TypeFormationData() {
    const { typeFormation } = useCategorieContext();
    const [record, setRecord] = useState([]);
    const [addTypeFormation, setAddTypeFormation] = useState(false);
    const [updateTypeFormationModal, setUpdateTypeFormationModal] = useState(false);
    const [typeFormationSelect, setTypeFormationSelect] = useState({});
    const dt = useRef(null);

    useEffect(() => {
        console.log("typeFormation:", typeFormation);  // Vérifiez les données
        setRecord(typeFormation);
    }, [typeFormation]);

    const handleDeleteFormation = async (id) => {
        try {
            await deleteTypeFormationService(id);
            const typeFormationResponse = await typeFormationData();
            setRecord(typeFormationResponse);
            Swal.fire({
                icon: 'success',
                title: 'Type de formation supprimé avec succès !',
                showConfirmButton: false,
                timer: 2000
            });
        } catch (error) {
            console.error("Erreur lors de la suppression du type formation :", error);
            Swal.fire({
                icon: 'error',
                title: 'Oups...',
                text: "Erreur lors de la suppression du type formation.",
                showConfirmButton: false,
                timer: 3000
            });
        }
    };

    const handleUpdate = (formationss) => {
        setTypeFormationSelect({ ...formationss });
        setUpdateTypeFormationModal(true);
    };

    const linkColor = {
        color: '#fdc800',
    };

    const nombreSessionPasser = (formations) => {
        const today = new Date();
        return formations.reduce((total, formation) => {
            if (formation.sessionFormation) {
                const filteredSession = formation.sessionFormation.filter(session => {
                    const sessionDate = new Date(session.date_debut);
                    return !isNaN(sessionDate) && sessionDate < today;
                });
                return total + filteredSession.length;
            }
            return total;
        }, 0);
    };

    const columns = [
        {
            field: 'nom',
            header: 'Nom',
            sortable: true,
            headerStyle: { textAlign: 'center' },
        },
        {
            field: 'formations',
            header: 'Formations',
            body: row => (
                <Button
                    label={row.formations.length}
                    className="p-button-text"
                    style={linkColor}
                    onClick={() => window.location.href = `/formation-type/${row.id}`}
                />
            ),
            sortable: true,
            headerStyle: { textAlign: 'center' },
        },
        {
            field: 'sessions passées',
            header: 'Sessions passées',
            body: row => (
                <Button
                    label={nombreSessionPasser(row.formations)}
                    className="p-button-text"
                    style={linkColor}
                    onClick={() => window.location.href = `/session-passe/${row.id}`}
                />
            ),
            sortable: true,
            headerStyle: { textAlign: 'center' },
        },
        {
            field: 'Actions',
            header: 'Actions',
            body: row => (
                <div className="flex gap-2">
                    <i className="fa fa-edit" title='Modifier' style={{ color: '#fdc800', cursor: 'pointer' }} onClick={() => handleUpdate(row)} />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <i className="fa fa-trash" title='Supprimer' style={{ color: 'red', cursor: 'pointer' }} onClick={() => handleDeleteFormation(row.id)} />
                </div>
            ),
            headerStyle: { textAlign: 'center' },
        },
    ];

    const extractInformation = (data) => {
        return data.map(item => {
            return {
                nom: item.nom,
                formations: item.formations.length,
                description: item.description,
                sessionFormationSize: nombreSessionPasser(item.formations),
            };
        });
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const exportPdf = () => {
        const doc = new jsPDF();
        doc.text('Type de Formation', 10, 10);
        doc.autoTable({
            columns: columns.map(col => ({ title: col.header, dataKey: col.field })),
            body: record.map(row => ({
                nom: row.nom,
                formations: row.formations.length,
                sessionsPassées: nombreSessionPasser(row.formations),
            })),
        });
        doc.save('type_formation.pdf');
    };

    const exportExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(extractInformation(record));
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        saveAs(new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' }), 'type_formation.xlsx');
    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2 export-buttons">
            <Tooltip target=".export-buttons>button" position="bottom" />
            <Button
                type="button"
                icon="pi pi-file"
                rounded
                onClick={exportCSV}
                data-pr-tooltip="CSV"
            />
            <Button
                type="button"
                icon="pi pi-file-excel"
                severity="success"
                rounded
                onClick={exportExcel}
                data-pr-tooltip="XLS"
            />
            <Button
                type="button"
                icon="pi pi-file-pdf"
                severity="warning"
                rounded
                onClick={exportPdf}
                data-pr-tooltip="PDF"
            />
        </div>
    );

    return (
        <div className="blog-area pd-bottom-120 go-top">

<div className="container">
                <div className="row">
                <div className="col-lg-12">
                        <div className="single-blog-inner style-border">
                           
                            <div style={{ overflowX: 'auto' }}></div>
            <DataTable
                ref={dt}
                value={record}
                header={header}
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                className="datatable"
            >
                {columns.map((col, i) => (
                    <Column key={i} field={col.field} header={col.header} body={col.body} sortable={col.sortable} headerStyle={col.headerStyle} />
                ))}
            </DataTable>
            <AddTypeFormation visible={addTypeFormation} setVisible={setAddTypeFormation} />
            <UpdateTypeFormation visible={updateTypeFormationModal} setVisible={setUpdateTypeFormationModal} typeFormationSelect={typeFormationSelect} />
            </div>
                          
                          </div>
                      </div>
                  </div>
              </div>
    );
}
