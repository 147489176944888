import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { useCategorieContext } from '../../../context/CategorieContext';
import { deleteTypeFormationService, typeFormationData } from '../../../services/typeformation/service';
import Swal from 'sweetalert2';
import AddTypeFormation from './modal/add-type-formation';
import UpdateTypeFormation from './modal/update-type-formation';
import { CSVLink } from 'react-csv';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default function TypeFormationData() {
    const { typeFormation } = useCategorieContext();  // Utilisation de typeFormation sans setTypeFormations
    const [record, setRecord] = useState([]);
    const [addTypeFormation, setAddTypeFormation] = useState(false);
    const [updateTypeFormationModal, setUpdateTypeFormationModal] = useState(false);
    const [typeFormationSelect, setTypeFormationSelect] = useState({});
    const dt = useRef(null);

    useEffect(() => {
        console.log("typeFormation:", typeFormation);  // Vérifiez les données
        setRecord(typeFormation);
    }, [typeFormation]);

    const handleDeleteFormation = async (id) => {
        try {
            await deleteTypeFormationService(id);
            const typeFormationResponse = await typeFormationData();
            setRecord(typeFormationResponse);
            Swal.fire({
                icon: 'success',
                title: 'Type de formation supprimé avec succès !',
                showConfirmButton: false,
                timer: 2000
            });
        } catch (error) {
            console.error("Erreur lors de la suppression du type formation :", error);
            Swal.fire({
                icon: 'error',
                title: 'Oups...',
                text: "Erreur lors de la suppression du type formation.",
                showConfirmButton: false,
                timer: 3000
            });
        }
    };

    const handleUpdate = (formationss) => {
        setTypeFormationSelect({ ...formationss });
        setUpdateTypeFormationModal(true);
    };

    const linkColor = {
        color: '#fdc800',
    };

    const nombreSessionPasser = (formations) => {
        const today = new Date();
        return formations.reduce((total, formation) => {
            if (formation.sessionFormation) {
                const filteredSession = formation.sessionFormation.filter(session => {
                    const sessionDate = new Date(session.date_debut);
                    return !isNaN(sessionDate) && sessionDate < today;
                });
                return total + filteredSession.length;
            }
            return total;
        }, 0);
    };

    const columns = [
        {
            field: 'nom',
            header: 'Nom',
            sortable: true,
            headerStyle: { textAlign: 'center' },
        },
        {
            field: 'formations',
            header: 'Formations',
            body: row => (
                <Button
                    label={`${row.formations.length}`}
                    className="p-button-text"
                    style={linkColor}
                    onClick={() => window.location.href = `/formation-type/${row.id}`}
                />
            ),
            sortable: true,
            headerStyle: { textAlign: 'center' },
        },
        {
            field: 'sessions passées',
            header: 'Sessions passées',
            body: row => (
                <Button
                    label={`${nombreSessionPasser(row.formations)}`}
                    className="p-button-text"
                    style={linkColor}
                    onClick={() => window.location.href = `/session-passe/${row.id}`}
                />
            ),
            sortable: true,
            headerStyle: { textAlign: 'center' },
        },
        {
            field: 'Actions',
            header: 'Actions',
            body: row => (
                <div className="flex gap-2">

                <>
                    <i className="fa fa-edit" title='Modifier' style={{ color: '#fdc800', cursor: 'pointer' }} onClick={() =>  handleUpdate(row)} />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <i className="fa fa-trash" title='Supprimer' style={{ color: 'red', cursor: 'pointer' }} onClick={() => handleDeleteFormation(row.id)} />
                </>
                   
                </div>
            ),
            headerStyle: { textAlign: 'center' },
        },
    ];

    const handleFilter = (e) => {
        const newData = typeFormation.filter(row => {
            return row.nom.toLowerCase().includes(e.target.value.toLowerCase());
        });
        setRecord(newData);
    };

    const searchStyles = {
        border: '2px solid',
        height: '45px',
        borderRadius: '26px',
    };

    const extractInformation = (data) => {
        return data.map(item => {
            return {
                nom: item.nom,
                formations: item.formations.length,
                description: item.description,
                sessionFormationSize: nombreSessionPasser(item.formations),
            };
        });
    };

    const exportCSV = () => {
        const csvData = record.map(row => ({
            Nom: row.nom,
            Formations: row.formations.length,
            'Sessions passées': nombreSessionPasser(row.formations),
        }));
        
        const csvHeaders = ['Nom', 'Formations', 'Sessions passées'];
        const csvRows = [csvHeaders.join(','), ...csvData.map(item => csvHeaders.map(header => item[header]).join(','))];
        const csvContent = csvRows.join('\n');
        
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'type_formation.csv');
    };

    const exportPdf = () => {
        const doc = new jsPDF();
        doc.text('Type de Formation', 10, 10);
        doc.autoTable({
            head: [['Nom', 'Formations', 'Sessions passées']],
            body: record.map(row => [
                row.nom,
                row.formations.length,
                nombreSessionPasser(row.formations)
            ]),
        });
        doc.save('type_formation.pdf');
    };

    const exportExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            record.map(row => ({
                Nom: row.nom,
                Formations: row.formations.length,
                'Sessions passées': nombreSessionPasser(row.formations),
            }))
        );
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        saveAs(new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' }), 'type_formation.xlsx');
    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2 export-buttons">
            <Tooltip target=".export-buttons>button" position="bottom" />
            <Button
                type="button"
                icon="pi pi-file"
                rounded
                onClick={exportCSV}
                data-pr-tooltip="CSV"
            />
            <Button
                type="button"
                icon="pi pi-file-excel"
                severity="success"
                rounded
                onClick={exportExcel}
                data-pr-tooltip="XLS"
            />
            <Button
                type="button"
                icon="pi pi-file-pdf"
                severity="warning"
                rounded
                onClick={exportPdf}
                data-pr-tooltip="PDF"
            />
        </div>
    );



    return (
        <div className="blog-area pd-bottom-120 go-top">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="single-input-inner">
                            <Button className="btn btn-secondary btn-radius" size="lg" onClick={() => setAddTypeFormation(true)}>
                                Ajouter
                            </Button>
                            <AddTypeFormation
                                addTypeFormation={addTypeFormation}
                                handleCloseAddTypeFormation={() => setAddTypeFormation(false)}
                                setRecord={setRecord}
                            />
                            <UpdateTypeFormation
                                updateTypeFormationModal={updateTypeFormationModal}
                                handleCloseUpdateTypeFormation={() => setUpdateTypeFormationModal(false)}
                                typeFormationSelect={typeFormationSelect}
                                setTypeFormationSelect={setTypeFormationSelect}
                                setRecord={setRecord}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 d-grid gap-2 d-flex justify-content-end">
                        <input type='text' placeholder='Rechercher' onChange={handleFilter} style={searchStyles} />
                    </div>
                    <div className="col-lg-12">
                        <div className="single-blog-inner style-border">
                            <div style={{ overflowX: 'auto' }}>
                                <DataTable
                                    ref={dt}
                                    value={record}
                                    header={header}
                                    paginator
                                    rows={10}
                                    dataKey="id"
                                    responsiveLayout="scroll"
                                    showGridlines
                                >
                                    {columns.map((col, index) => (
                                        <Column
                                            key={index}
                                            field={col.field}
                                            header={col.header}
                                            body={col.body}
                                            sortable={col.sortable}
                                            headerStyle={col.headerStyle}
                                        />
                                    ))}
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
