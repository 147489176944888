import React from 'react';
import NavbarAdmin from "../../global-components/Navbar-admin";
import PageHeader from '../../global-components/page-header';
import EvenementData from './evenement-data';


const Evenements = () => {
  return (
    <div>
      <NavbarAdmin />
      <PageHeader headertitle="Evenements" />
      <EvenementData />
    </div>
  );
};

export default Evenements;
