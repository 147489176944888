import React from 'react';
import { Navigate } from 'react-router-dom'


const LogoutPage = () => {
    localStorage.removeItem('isLoggedIn');

    return (
         <Navigate to='/sign-in'/>
      )
}

export default LogoutPage

