import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class CoursePage extends Component {

	

    
    render() {
		//console.log(`les types de formations que vous voulais afficher est ${this.state.formations}`);
        let publicUrl = process.env.PUBLIC_URL+'/'
		let formations = this.props.formations;
		const apiUrl = process.env.REACT_APP_API_BASE;


    return <div className="blog-area pd-top-60 pd-bottom-120">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-8 order-lg-12">
			        <div className="row go-top">
					  {
						formations.map((formation,index) =>(
							<div className="col-md-6" key={`formation${index}`}>
								<Link to={`/course-details/${formation.id}`}>
							<div className="single-course-inner">
							  <div className="thumb">
								<img src={apiUrl+`${formation.image_url}`} alt="img" />
							  </div>
							  <div className="details">
								<div className="details-inner">
								  {/* <div className="emt-user">
									<span className="u-thumb"><img src={publicUrl+"assets/img/author/1.png"} alt="img" /></span>
									<span className="align-self-center">Nancy Reyes</span>
								  </div> */}
								  <h6><Link to={`/course-details/${formation.id}`}>{formation.nom}</Link></h6>
								</div>
								<div className="emt-course-meta">
								  <div className="row">
									<div className="col-6">
									  <div className="rating">
										 <i className="fa fa-clock-o" /> Durée:
                                               <span>{formation.duration}</span>
									  </div>
									</div>
									<div className="col-6">
									  <div className="price text-right">
										Prix:<span>{formation.cout_min}CFA</span>
									  </div>
									</div>
								  </div>
								</div>
							  </div>
							</div>
							</Link>
						  </div>
						))
					  }
			         
			     
			        </div>
			       
			      </div>
			      <div className="col-lg-4 order-lg-1 col-12">
			        <div className="td-sidebar mt-5 mt-lg-0">
			          <div className="widget widget_search_course">    
			            <h4 className="widget-title">Search</h4>                               
			            <form className="search-form single-input-inner">
			              <input type="text" placeholder="Search here" />
			              <button className="btn btn-base w-100 mt-3" type="submit"><i className="fa fa-search" /> SEARCH</button>
			            </form>
			          </div> 
			          <div className="widget widget_catagory">
			            <h4 className="widget-title">Catagory</h4>                                 
			            <ul className="catagory-items go-top">
			              <li><Link to="/blog-details">Tempor lorem interdum <i className="fa fa-caret-right" /></Link></li>
			              <li><Link to="/blog-details">Auctor mattis lacus  <i className="fa fa-caret-right" /></Link></li>
			              <li><Link to="/blog-details">Dolor proin  <i className="fa fa-caret-right" /></Link></li>
			              <li><Link to="/blog-details">Pharetra amet <i className="fa fa-caret-right" /></Link></li>
			            </ul>
			          </div>  
			          <div className="widget widget_checkbox_list">
			            <h4 className="widget-title">Price</h4> 
			            <label className="single-checkbox">
			              <input type="checkbox" defaultChecked="checked" />
			              <span className="checkmark" />
			              Free Courses
			            </label> 
			            <label className="single-checkbox">
			              <input type="checkbox" />
			              <span className="checkmark" />
			              Paid Courses
			            </label> 
			            <label className="single-checkbox">
			              <input type="checkbox" />
			              <span className="checkmark" />
			              Only Subscription
			            </label>  
			          </div> 
			          <div className="widget widget_checkbox_list">
			            <h4 className="widget-title">Level</h4> 
			            <label className="single-checkbox">
			              <input type="checkbox" defaultChecked="checked" />
			              <span className="checkmark" />
			              Beginner
			            </label> 
			            <label className="single-checkbox">
			              <input type="checkbox" />
			              <span className="checkmark" />
			              Intermediate
			            </label> 
			            <label className="single-checkbox">
			              <input type="checkbox" />
			              <span className="checkmark" />
			              Advanced
			            </label>  
			          </div> 
			          <div className="widget widget_tags mb-0">
			            <h4 className="widget-title">Tags</h4>
			            <div className="tagcloud go-top">
			              <Link to="blog-details">Art</Link>
			              <Link to="blog-details">Creative</Link>
			              <Link to="blog-details">Article</Link>
			              <Link to="blog-details">Designer</Link>
			              <Link to="blog-details">Portfolio</Link>
			              <Link to="blog-details">Project</Link>
			              <Link to="blog-details">Personal</Link>
			              <Link to="blog-details">Landing</Link>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

        }
}

export default CoursePage