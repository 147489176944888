import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCurrentUser } from '../services/user/service';
import { getInscriptionData } from '../services/inscription/service';
import { getIncommingSession } from '../services/session/service';

// Créer le contexte
export const AdminContext = createContext(undefined);

// Hook personnalisé pour utiliser le contexte
export const useAdminContext = () => {
  return useContext(AdminContext);
};

// Fournisseur de contexte pour encapsuler votre application
export const AdminProvider = ({ children }) => {
  // État local pour stocker les données de l'utilisateur actuel
  const [currentUser, setCurrentUser] = useState();
  // État local pour stocker les données d'inscription
  const [inscriptionData, setInscriptionData] = useState([]);
  const [incommingSession, setIncommingSession] = useState([]);

  // Fonction pour récupérer les données de l'utilisateur actuel
  const fetchCurrentUserData = async () => {
    try {
      const response = await getCurrentUser();
      setCurrentUser(response);
    } catch (error) {
      console.error('Erreur lors de la récupération des données de l\'utilisateur actuel :', error);
    }
  };

  // Fonction pour récupérer les données d'inscription
  const fetchInscriptionData = async () => {
    try {
      const response = await getInscriptionData();
      setInscriptionData(response);
    } catch (error) {
      console.error('Erreur lors de la récupération des données d\'inscription :', error);
    }
  };

   // Fonction pour récupérer les données de l'utilisateur actuel
   const fetchIncommingSessionData = async () => {
    try {
      const response = await getIncommingSession();
      setIncommingSession(response);
    } catch (error) {
      console.error('Erreur lors de la récupération des données sur le prochain session :', error);
    }
  };

  // Utiliser useEffect pour effectuer les appels API au chargement du composant
  useEffect(() => {
    fetchCurrentUserData();
  }, []);

  // Utiliser useEffect pour effectuer les appels API au chargement du composant
  useEffect(() => {
    fetchInscriptionData();
  },[]);

  // Utiliser useEffect pour afficher les données d'inscription chaque fois qu'elles sont mises à jour
useEffect(() => {
  console.log('les session inscript de formations sur home', inscriptionData);
}, [inscriptionData]);

 // Utiliser useEffect pour effectuer les appels API au chargement du composant
 useEffect(() => {
  fetchIncommingSessionData();
}, []);


  // Valeur du contexte à fournir à l'ensemble de l'application
  const contextValue = {
    currentUser,
    inscriptionData,
    incommingSession,
    setIncommingSession
  };

  // Rendre le contexte disponible pour les composants enfants
  return (
    <AdminContext.Provider value={contextValue}>
      {children}
    </AdminContext.Provider>
  );
};
