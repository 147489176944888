import React, { useEffect, useState } from 'react';
import Blog from "./blog-components/blog";
import NavbarV3 from "./global-components/navbar-v3";
import Footer from './global-components/footer';
import PageHeader from './global-components/page-header';
import { useAdminContext } from "../context/AdminContext";
import { Navigate } from 'react-router-dom';
import { getCurrentUser } from '../services/user/service';

const Dashbord_Admin = () => {

    const [currentUser, setCurrentUser] = useState();

   // Fonction pour récupérer les données de l'utilisateur actuel
  const fetchCurrentUserData = async () => {
    try {
      const response = await getCurrentUser();
      setCurrentUser(response);
    } catch (error) {
      console.error('Erreur lors de la récupération des données de l\'utilisateur actuel :', error);
    }
  };


  // Utiliser useEffect pour effectuer les appels API au chargement du composant
  useEffect(() => {
    fetchCurrentUserData();
  }, [currentUser]);

   



    return (
        <div>
            {!currentUser ? <div>Loading...</div> : currentUser.profils[0].role === "administrateur" ? <Navigate to="/type-formation" replace /> :<Navigate to="/" replace />}
            {/* Render other components */}
        </div>
    );
}

export default Dashbord_Admin;
