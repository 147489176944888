import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { RingLoader } from "react-spinners";
import { useCategorieContext } from '../../../../context/CategorieContext';
import { addSession, getsessionformationByFormation } from '../../../../services/session/service';
import Swal from 'sweetalert2';
import { useAdminContext } from '../../../../context/AdminContext';
import { formations, updateFormation } from '../../../../services/formation/service';

import  ReactQuill  from  "react-quill";
import  "react-quill/dist/quill.snow.css";


const  modules  = {
  toolbar:[
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ],
};

const  modules1  = {
  toolbar:[
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ],
};

const  modules2  = {
  toolbar:[
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ],
};

const  modules3  = {
  toolbar:[
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ],
};

const  modules4  = {
  toolbar:[
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ],
};




const UpdateFormation = (props) => {
    const { handleCloseUpdateFormation, showUpdateFormation,formation,setRecord,setFormation } = props;
    const { typeFormation,setFormationDatas } = useCategorieContext();
   
  const [selectedOption, setSelectedOption] = useState(0);
  const [canSend, setCanSend] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isCreated, setIsCreated] = useState(false);
  const [isError, setIsError] = useState({value:false,message:""});
  const apiUrl = process.env.REACT_APP_API_BASE;

  const [imageUrl,setImageUrl] = useState(null)
  const [imageUrlDetail,setImageUrlDetail] = useState(null)


  
  // États pour stocker les noms des images sélectionnées
  const [imageName, setImageName] = useState("");
  const [imageDetailName, setImageDetailName] = useState("");


  const showSuccessAlert = () => {
    Swal.fire({
      icon: "success",
      title: "La formation a été modifié avec succès !",
      showConfirmButton: false,
      timer: 5000
    });
    setIsCreated(false)
    handleCloseUpdateFormation()
    
  };

  const showErrorAlert = () =>{
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: isError.message,
      showConfirmButton: false,
      timer: 5000
    });
    setIsError({...isError,value:false})
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormation(prevFormation => ({
        ...prevFormation,
        [name]: value,
    }));
};


  const handleSubmit = async () => {
   
    setCanSend(true);
    setLoading(true)
   

    try {
        const response = await updateFormation(formation);
            const updatedFormationData = await formations();
            setFormationDatas(updatedFormationData);

        if (response) {
            setIsCreated(true)
        }
    } catch (error) {
        console.error('Erreur lors de l\'envoi des données de formation :', error);
        setIsError({value: true,message: `Erreur lors de l'envoi des données de formation : ${error}`})
    } finally {
        setLoading(false)
    }
};


  const buttonStyles = {
    width: "100%",
    border: "2px solid rgba(8, 76, 148, 0.2)",
    height: "58px",
    borderRadius: "26px",
    padding: "0 18px",
  };

  const handleSelectChange = (event) => {
    // setSelectedOption(event.target.value);
    setFormation({
      ...formation,
      type_formation_id: event.target.value})
  };

  const fileInputRef = useRef(null);
  const fileInputDetailsRef = useRef(null);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileDetailButtonClick = () => {
    fileInputDetailsRef.current.click();
  };

  const handleFileChange = (e) => {
    setFormation({
      ...formation,
      image_url: e.target.files[0] // Mettre à jour l'état avec le fichier sélectionné
    });
    setImageUrl(URL.createObjectURL(e.target.files[0]))
    setImageName(e.target.files[0].name);

  };
  
  const handleFileDetailChange = (e) => {
    setFormation({
      ...formation,
      image_url_detail: e.target.files[0] // Mettre à jour l'état avec le fichier sélectionné
    });
    setImageDetailName(e.target.files[0].name);
    setImageUrlDetail(URL.createObjectURL(e.target.files[0]))
  };

  const handleChangeTextEditor =(value) =>{
    const name = 'description'
    setFormation({
      ...formation,
      [name]: value,
    });
  }

  const handleChangeTextEditorObjectifGeneral =(value) =>{
    const name = 'general_objectif'
    setFormation({
      ...formation,
      [name]: value,
    });
  }

  const handleChangeTextEditorObjectifSpefifique =(value) =>{
    const name = 'specifique_objectif'
    setFormation({
      ...formation,
      [name]: value,
    });
  }

  const handleChangeTextEditorPrerequis =(value) =>{
    const name = 'prerequis'
    console.log(value);
    setFormation({
      ...formation,
      [name]: value,
    });
  }

  const handleChangeTextEditorContenue =(value) =>{
    const name = 'contenue'
    setFormation({
      ...formation,
      [name]: value,
    });
  }

  useEffect(() => {
    

    
  }, []);

  const handleDOMNodeInserted = () => {
    console.warn("DOMNodeInserted event detected. Consider using MutationObserver instead.");
  };
    return (
       <>
       { isCreated &&  showSuccessAlert()}
       {isError.value && showErrorAlert()}
        <Modal show={showUpdateFormation} onHide={handleCloseUpdateFormation}  backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Modification de {formation && formation.nom} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="border d-flex align-items-center justify-content-center">
                        <RingLoader color="#fdc800" size='140' loading={loading} />
                    </div>
                ) : (
                    <Form>
                    <div className="row">
                      <div className="col-md-12">
                        <div className={formation.correctNom ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                          <input type="text" name="nom" placeholder="Nom formation" onChange={handleChange} value={formation && formation.nom} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className={formation.correctNom ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                          <input type="number" name="ordre" placeholder="Ordre  affichage" onChange={handleChange} value={formation && formation.ordre} min={0}/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className={formation.correctDuration ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                          <input type="text" name="duration" placeholder="Durée de la formation" onChange={handleChange} value={formation && formation.duration}  />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className={formation.correctCoutMin ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                          <input type="number" name="cout_min" placeholder="cout minimal de la formation" onChange={handleChange} value={formation && formation.cout_min} min={0} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className={formation.correctNom ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                            <input type="text" name="mode_paiement" placeholder="Mode de paiement formation" value={formation && formation.mode_paiement} onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className={"single-input-inner style-bg-border"}>
                        <ReactQuill  modules={modules} theme="snow" value={formation.description} onChange={handleChangeTextEditor} placeholder="une description de la formation..." defaultValue={formation && formation.description} />

                        </div>
                      </div>
                       {/* Textarea pour l'objectif général */}
                       {/* <div className="col-md-12">
                        <ReactQuill className='single-input-inner style-bg-border'  modules={modules1} theme="snow" onChange={handleChangeTextEditorObjectifGeneral} placeholder="une general_objectif de la formation..." defaultValue={formation && formation.general_objectif} />

                      </div>
              
                      {/* Textarea pour l'objectif spécifique 
                      <div className="col-md-12">
                      <ReactQuill className='single-input-inner style-bg-border'  modules={modules2} theme="snow" onChange={handleChangeTextEditorObjectifSpefifique} placeholder="une specifique_objectif de la formation..." defaultValue={formation && formation.specifique_objectif} />
                      </div> */}
                       {/* Textarea pour prerequis */}
                      <div className="col-md-12">
                          <ReactQuill className='single-input-inner style-bg-border'  modules={modules3} theme="snow" value={formation && formation.prerequis} onChange={handleChangeTextEditorPrerequis} placeholder="Prerequis de la formation..." defaultValue={formation && formation.prerequis}/>
                      </div>

                      
                      {/* Textarea pour contenue */}
                      <div className="col-md-12">
                          <ReactQuill className='single-input-inner style-bg-border'  modules={modules4} theme="snow" onChange={handleChangeTextEditorContenue} placeholder="Contenue de la formation..."defaultValue={formation && formation.contenue}/>
                      </div>

                      <div className="col-md-6">
                        <div className={"single-input-inner-custome"}>
                          <Button className="btn btn-base btn-radius" onClick={handleFileButtonClick} style={buttonStyles}>Choisir image 370X200</Button><br/><br/>
                          <input type="file" name="image_url" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
                          {imageUrl !== null ? <img src={imageUrl} alt="img" /> : formation && <img src={apiUrl+`${formation.image_url}`} alt="img" /> }
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className={"single-input-inner-custome"}>
                          <Button className="btn btn-base btn-radius" onClick={handleFileDetailButtonClick} style={buttonStyles}>Choisir image 870X520</Button> <br/><br/>
                          <input type="file" name="image_url_detail" ref={fileInputDetailsRef} style={{ display: "none" }} onChange={handleFileDetailChange} />
                          {imageUrlDetail !== null ? <img src={imageUrlDetail} alt="img" /> : formation && <img src={apiUrl+`${formation.image_url_detail}`} alt="img" /> }
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className={"single-input-inner-custome"}>
                          <Form.Select aria-label="Default select example" 
                                       onChange={handleSelectChange} 
                                       value={formation.type_formation_id} 
                                       name="type_formation_id"
                                       >
                            <option value={formation.type_formation_id}>{formation && formation.type_formation.nom} </option>
                            {(typeFormation && formation) &&
                              typeFormation.map((typeFormation, index) => (
                                typeFormation.id !== formation.type_formation_id &&
                                <option value={typeFormation.id} key={`typetab${index}`}>
                                  {typeFormation.nom}
                                </option>
                              ))}
                          </Form.Select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="single-input-inner">
                          <Button className="btn btn-secondary btn-radius" onClick={handleCloseUpdateFormation} size="lg" style={buttonStyles}>
                            Fermer
                          </Button>
                        </div>
                      </div>
                      <div className="col-md-6 d-grid gap-2">
                        <Button className="btn btn-base btn-radius" onClick={handleSubmit} size="lg" style={buttonStyles} >
                          Modifier
                        </Button>
                      </div>
                    </div>
                  </Form>
                
                )}
            </Modal.Body>
        </Modal>
       </>
    );
};

export default UpdateFormation;
