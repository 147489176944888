import React from 'react';
import { useParams } from "react-router-dom";
import NavbarAdmin from "../../global-components/Navbar-admin";
import SessionData from "./session-data";
import PageHeader from '../../global-components/page-header';


const SessionFormation = () => {
  const { id } = useParams();
  return (
    <div>
      <NavbarAdmin />
      <PageHeader headertitle="Session de formations" />
      {id ? (
        <SessionData id={id} />
      ) : (
        <SessionData />
      )}
    </div>
  );
};

export default SessionFormation;
