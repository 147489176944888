import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
    baseURL: apiUrl,
  });
  

export const questionStore = async (questionData)=>{

    try {
        const response = await apiService.post("question/store",questionData).then(res =>{
            console.log(res);
            return res
        })
        return response.data
    } catch (error) {
        console.log(error);
        return error
    }

}