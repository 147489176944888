import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { resetPassword, verifyResetToken } from "../../services/login/service";
import { Navigate, useParams, useNavigate } from "react-router-dom";

const ResetPassword = () => {
    const { token } = useParams();
    const navigate = useNavigate(); // Using useNavigate hook to get the navigation function
    const [isValidToken, setIsValidToken] = useState(false);
    const [password, setPassword] = useState('');
    const [isUpdated, setIsUpdated] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [correctPassword, setCorrectPassword] = useState({ password: true, confirmPassword: true });

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handlePasswordConfirmChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const showSuccessAlert = () => {
        Swal.fire({
            icon: "success",
            title: `Modification`,
            text: "Votre mot de passe a été réinitialisé avec succès.",
            showConfirmButton: false,
            timer: 4000
        })
    };

    const handleUpdatePassword = async (e) => {
        e.preventDefault();
        if (password === confirmPassword) {
            try {
                const response = await resetPassword(token, password);
                console.log('response', response);
                showSuccessAlert();
                setCorrectPassword({ password: true, confirmPassword: true });
                setIsUpdated(true);
            } catch (error) {
                Swal.fire({
                    title: 'Erreur',
                    text: 'Une erreur s\'est produite lors de la réinitialisation du mot de passe.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } else {
            setCorrectPassword({ password: false, confirmPassword: false });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await verifyResetToken(token);
                setIsValidToken(true);
            } catch (error) {
                Swal.fire({
                    title: 'Erreur',
                    text: 'Jeton de réinitialisation invalide ou expiré.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        };

        fetchData();
    }, [token]);

    if (isUpdated) {
        return <Navigate to="/sign-in" replace />;
    }

    return (
       <>
       {
        
        isValidToken &&(
            <div className="signup-page-area pd-top-120 pd-bottom-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-7">
                            <div className="section-title text-center">
                                <h2 className="title">Changer de mot de passe</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-xl-6 col-lg-6">
                            <form className="signin-inner">
                                <div className="row">
                                    <div className="col-12">
                                        <div className={correctPassword.password ? 'single-input-inner style-bg-border' : 'single-input-inner-custome-error'}>
                                            <input type="password" name="password" value={password} onChange={handlePasswordChange} placeholder="Mot de passe" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className={correctPassword.confirmPassword ? 'single-input-inner style-bg-border' : 'single-input-inner-custome-error'}>
                                            <input type="password" name="confirmPassword" value={confirmPassword} onChange={handlePasswordConfirmChange} placeholder="Confirmer le mot de passe" />
                                        </div>
                                    </div>
                                    <div className="col-12 mb-4">
                                        <button className="btn btn-base w-100" onClick={handleUpdatePassword}>Modifier</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )       
       }
       </>
    );
};

export default ResetPassword;
