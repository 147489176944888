import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { login, verifyEmail } from '../../services/login/service';
import Swal from 'sweetalert2';

const LoginInscription = (props) => {
    const { handleCloseLogin, showlogin, handleShowRegister, handleShowSubscribe } = props;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isCorrect, setIsCorrect] = useState({ correctPassword: true, correctEmail: true });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'email') {
            setEmail(value);
            setIsCorrect({ ...isCorrect, correctEmail: true });
        } else if (name === 'password') {
            setPassword(value);
            setIsCorrect({ ...isCorrect, correctPassword: true });
        }
    };

    const handleLogin = async () => {
        try {
            const result = await login(email, password);
            const token = result.token;
            localStorage.setItem('token', token.token);
            localStorage.setItem('isLoggedIn', 'true');
            setIsCorrect({ correctEmail: true, correctPassword: true });
            handleCloseLogin();
            handleShowSubscribe();
        } catch (error) {
            console.error('Erreur de connexion:', error);
            setIsCorrect({ correctEmail: false, correctPassword: false });
        }
    };

    const buttonStyles = {
        width: '100%',
        border: '2px solid rgba(8, 76, 148, 0.2)',
        height: '58px',
        borderRadius: '26px',
        padding: '0 18px',
    };

    const styleLink = {
        color: '#FDC800',
    };

    const verifyEmailAlert = (e) => {
        e.preventDefault(); // Empêcher le comportement par défaut du lien
        handleCloseLogin()
        Swal.fire({
            title: "Saisir votre email",
            input: "email",
            inputAttributes: {
                autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: "Verifier",
            showLoaderOnConfirm: true,
            cancelButtonText: "Annuler",
            preConfirm: async (inputEmail) => {
                try {
                    const response = await verifyEmail(inputEmail);
                    if (!response.message) {
                        throw new Error(JSON.stringify(await response.json()));
                    }
                    return response;
                } catch (error) {
                    Swal.showValidationMessage(`Aucun compte utilisateur actif n'est associé à ${inputEmail}`);
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: "success",
                    title: "Veuillez le consulter pour effectuer la mise à jour.",
                    showConfirmButton: false,
                    timer: 4000,
                    showClass: {
                        popup: `
                            animate__animated
                            animate__fadeInUp
                            animate__faster
                        `
                    },
                    hideClass: {
                        popup: `
                            animate__animated
                            animate__fadeOutDown
                            animate__faster
                        `
                    }
                });
            }
        });
    };

    return (
        <Modal show={showlogin} onHide={handleCloseLogin}>
            <Modal.Header closeButton>
                <Modal.Title>Connectez-vous à votre compte Bakhbadé Academy</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicEmail" className={!isCorrect.correctEmail ? 'single-input-inner-custome-error' : 'single-input-inner-custome'}>
                        <Form.Label>Adresse Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email" // Correction: Le nom devrait être "email"
                            placeholder="Entrez votre email"
                            value={email}
                            onChange={handleInputChange}
                        />
                        {!isCorrect.correctEmail &&
                            <Form.Text className="text-muted">
                                L'email que vous avez saisi est incorrect.
                            </Form.Text>}
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword" className={!isCorrect.correctPassword ? 'single-input-inner-custome-error' : 'single-input-inner-custome'}>
                        <Form.Label>Mot de passe</Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            placeholder="Mot de passe"
                            value={password}
                            onChange={handleInputChange}
                        />
                        {!isCorrect.correctPassword &&
                            <Form.Text className="text-muted">
                                Le mot de passe que vous avez saisi est incorrect.
                            </Form.Text>}
                    </Form.Group>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-left">
                                <Button className="single-input-inner btn btn-secondary btn-radius" onClick={handleCloseLogin} size="lg" style={buttonStyles}>
                                    Fermer
                                </Button>
                            </div>
                            <div className="col-md-6 text-right">
                                <Button className="btn btn-base btn-radius" onClick={handleLogin} size="lg" style={buttonStyles}>
                                    Se connecter
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div style={styleLink}>
                                    <a href="#" onClick={verifyEmailAlert}>Mot de passe oublié</a>
                                </div>
                                <div>
                                    <span>Vous n'avez pas de compte ?</span>
                                    <a className="ml-1" href="#" onClick={() => { handleCloseLogin(); handleShowRegister(); }}><strong style={styleLink}>Inscrivez-vous</strong></a>
                                </div>
                                <div>
                                    <span>En vous inscrivant, vous acceptez nos <a href="#" style={styleLink}>Conditions d'utilisation</a> et notre <a href="#"  style={styleLink}>Politique de confidentialité</a>.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default LoginInscription;
