import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { CategorieContext } from '../../context/CategorieContext';

class EventPage extends Component {
	static contextType = CategorieContext;
	state = {
        currentPage: 1,
        itemsPerPage: 12, // Define the number of items per page
    };
    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
		const { currentPage, itemsPerPage } = this.state;
		const { evenementData } = this.context; // Accessing directly from context
		// Calculate the index of the first and last item to display
		if (!evenementData) {
			return null; // Or you can return a loading indicator
		}
	
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;

        // Slice the array of items to display only those for the current page
        const displayedItems = evenementData.slice(indexOfFirstItem, indexOfLastItem);



		const convertData = (dateTo) => {
			const [year, month, day] = dateTo.split('-');
			const months = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'];
			const formattedDate = `${day} ${months[parseInt(month) - 1]} ${year}`;
			return formattedDate;
		}

		const convertDay = (dateTo) => {
			const [year, month, day] = dateTo.split('-');
			const formattedDate = day;
			return formattedDate;
		}
		const convertMonth = (dateTo) => {
			const [year, month, day] = dateTo.split('-');
			const months = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'];
			const formattedDate = months[parseInt(month) - 1];
			return formattedDate;
		}

		const convertYear = (dateTo) => {
			const [year, month, day] = dateTo.split('-');
			const formattedDate = year;
			return formattedDate;
		}

		const renderedItems = displayedItems.map((evenement ,index) => (
			<Link to={`/event-details/${evenement.id}`} key={index}  className="col-lg-6">
				<div >
					<div className="media single-event-inner">
					<div className="media-left date">
						<span>{evenement.date_debut ? convertMonth(evenement.date_debut) : ''}  </span><br/>
						{evenement.date_debut ? convertDay(evenement.date_debut) : ''}
					</div>
					<div className="media-body details">
						<ul className="blog-meta">
						<li><i className="fa fa-clock-o" /> {evenement.heure ? evenement.heure : ''}</li>
						<li><i className="fa fa-map-marker" />{evenement.lieu ? evenement.lieu : ''}</li>
						</ul>
						<h5><Link to={`/event-details/${evenement.id}`}>{evenement.titre}</Link></h5>
						<p>{evenement.description}</p>
					</div>
					</div>
				</div>
	        </Link>
        ));

		 // Pagination logic
		 const pageNumbers = [];
		 for (let i = 1; i <= Math.ceil(evenementData.length / itemsPerPage); i++) {
			 pageNumbers.push(
				 <li key={i} className={currentPage === i ? 'active' : ''}>
					 <Link to="#" onClick={() => this.setState({ currentPage: i })}>
						 {i}
					 </Link>
				 </li>
			 );
		 }

    return  <div className="event-area pd-top-120 pd-bottom-120 go-top">
			  <div className="container">
			  <div className="row justify-content-center">
		      <div className="col-xl-6 col-lg-7">
		        <div className="section-title text-center">
		          <h6 className="sub-title double-line">Dernières nouvelles </h6>
		          <h2 className="title">Nos événements</h2>
		        </div>
		      </div>
		    </div>
			    <div className="row">
				{renderedItems && renderedItems}
			     
			      
			      <div className="col-12">
			        <nav className="td-page-navigation text-center">
			          <ul className="pagination">

					  <li className="pagination-arrow">
						<Link to="#" onClick={() => this.setState({ currentPage: currentPage - 1 })} disabled={currentPage === 1}>
								<i className="fa fa-angle-double-left" />
						</Link>
					  </li>
						{pageNumbers}
					  <li className="pagination-arrow">
						<Link to="#" onClick={() => this.setState({ currentPage: currentPage + 1 })} disabled={currentPage === Math.ceil(evenementData.length / itemsPerPage)}>
							<i className="fa fa-angle-double-right" />
						</Link>
					  </li>
			          </ul>

			        </nav>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default EventPage