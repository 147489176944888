import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
    baseURL: apiUrl,
  });
  const authToken = localStorage.getItem('token');

const headers = {
    'Authorization': `Bearer ${authToken}`,
    'Content-Type': 'multipart/form-data'
  };

export const addEvenementService =  async (eventData) =>{
console.log("evenData: ",eventData);
    try {
        const response = await apiService.post('/evenement/store',eventData,{headers}).then(res =>{
            console.log('evenement data',res.data);
            return res.data
        })
        return response
    } catch (error) {
        console.log('error evenement: ',error);
    }
}

export const getEvenements = async() =>{

    try {
        const response = await apiService.get('/evenements').then(res =>{
            console.log('evenement data',res.data);
            return res.data
        })
        return response
    } catch (error) {
        console.log('error evenement: ',error);
    }
}


export const getEvenById = async (id) =>{
    try {
        const response = await apiService.get(`evenement/${id}`).then(res =>{
            console.log("response for single even: ",res.data);
            return res.data
        })
        return response
    } catch (error) {
        console.log('error evenement: ',error);
    }
}

export const updateEven = async (eventData) =>{
    try {
        const response = await apiService.put(`/evenement/update/${eventData.id}`,eventData,{
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'multipart/form-data' 
            }
        }).then(res =>{
            console.log('evenement data',res.data);
            return res.data
        })
        return response
    } catch (error) {
        console.log('error evenement: ',error);
    }
}

/***
 * participation dans un evenements
 */

export const participation = async (id,formattedDate) =>{

    try {
        const response = apiService.post(`participation/store/${id}`,formattedDate).then(res =>{
            console.log(res.data.participation);
            return res.data.participation
        })

        return response
        
    } catch (error) {
        console.error('Inscription echou2:', error);
        return error
    }

} 


export const deleteArticle = async( id) =>{

    try {
        const response = await apiService.post(`article/delete/${id}`);
        return response.data
    } catch (error) {
        console.error('Inscription echou2:', error);
        return error
    }
}