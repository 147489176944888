import React from 'react';
import { MegaMenu } from 'primereact/megamenu';
import { Link, Router } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useCategorieContext } from '../context/CategorieContext';


export default function TemplateDemo() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    const navigate = useNavigate();
    const {typeFormation} = useCategorieContext()

    
    const itemRenderer = (item, options) => {
        const onItemClick = (e) => {
            if (options.onClick) {
                options.onClick(e);
            }
        };


        if (item.root) {
            return (
                <a className="flex align-items-center cursor-pointer px-3 py-2 overflow-hidden relative font-semibold text-lg uppercase p-ripple hover:surface-ground" style={{ borderRadius: '2rem' }} onClick={(e) => onItemClick(e)}>
                    <span className={item.icon} />
                    <span className="ml-2">{item.label}</span>
                    
                </a>
            );
        } else if (!item.image) {
            return (
                <a className="flex align-items-center p-3 cursor-pointer mb-2 gap-2 " onClick={onItemClick}>
                    <span className="inline-flex align-items-center justify-content-center border-circle bg-primary w-3rem h-3rem">
                        <i className={`${item.icon} text-lg`}></i>
                    </span>
                    <span className="inline-flex flex-column gap-1">
                        <span className="font-medium text-lg text-900">{item.label}</span>
                        <span className="white-space-nowrap">{item.subtext}</span>
                    </span>
                </a>
            );
        } else {
            return (
                <div className="flex flex-column align-items-start gap-3" onClick={onItemClick}>
                    <img alt="megamenu-demo" src={item.image} className="w-full" />
                    <span>{item.subtext}</span>
                    <Button className="p-button p-component p-button-outlined" label={item.label} />
                </div>
            );
        }
    };
    const formationItems = typeFormation.map(formationType => ({
        label: formationType.nom,
        icon: 'pi pi-fw pi-star',
        id: formationType.id,
        template: itemRenderer,
        items: formationType.formations.map(formation => ({
          label: formation.nom,
          id: formation.id,
          template: itemRenderer 
          // You can add sub-items here if needed
        }))
      }));
    const itemConexion = (item, options) => {
        const onItemClick = (e) => {
            if (options.onClick) {
                options.onClick(e);
            }
        };
        if (item.root) {
            return (
               <>
                <Link className="signin-btn" to="/sign-in">Sign In</Link>
                <Link className="btn btn-base" to="/sign-up">Sign Up</Link>
               </>
            );
        } 

    }

    const items = [
        {
            label: 'Accueil',
            root: true,
            template: itemRenderer,
            
            command: () => {
                navigate("/");
            }
           
        },
        {
            label: 'Formation',
            root: true,
            template: itemRenderer,
            items: [
                [
                    {
                        items: [...formationItems]
                    }
                ],
                [
                    {
                        items: [
                            { label: 'Solutions', icon: 'pi pi-shield', subtext: 'Subtext of item', template: itemRenderer },
                            { label: 'Faq', icon: 'pi pi-question', subtext: 'Subtext of item', template: itemRenderer },
                            { label: 'Library', icon: 'pi pi-search', subtext: 'Subtext of item', template: itemRenderer }
                        ]
                    }
                ],
                
                
            ]
        },
        {
            label: 'Apropos',
            root: true,
            template: itemRenderer,
            command: () => {
                navigate("/about");
            }
        },
        {
            label: 'Contact',
            root: true,
            template: itemRenderer
        },
        {
           
            root: true,
            template: itemConexion,
           
        },
    ];

    const end =  (
        <div>
            <Link className="signin-btn" to="/sign-in">Sign In</Link>
            <Link className="btn btn-base" to="/sign-up">Sign Up</Link>
            <a className="search-bar" href="#"><i className="fa fa-search" /></a>
        </div>
    );

    return (
        <div className="collapse navbar-collapse go-top">
            <MegaMenu model={items} orientation="horizontal"  className="navbar-nav menu-open" />
        </div>
    );
}

