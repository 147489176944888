import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { register } from '../../services/login/service';

class SignUP extends Component {
  constructor(props) {
    super(props);
    // Initialize state
    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      prenom: '',
      nom: '',
      numero_telephone: '',
      correctNom: null,
      correctPhoneNumber: null,
      correctPrenom: null,
      correctEmail: null,
      correctPassword: null,
      correctPasswordConfirm: null,
      isRegisterIn: false, // Add a flag to track login status
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleRegistrer = this.handleRegistrer.bind(this);
    this.handleNomChange = this.handleNomChange.bind(this);
    this.handlePrenomChange = this.handlePrenomChange.bind(this);
    this.handleNumerTelephoneChange = this.handleNumerTelephoneChange.bind(this);
    this.handlePasswordConfirmChange = this.handlePasswordConfirmChange.bind(this);
  }

  handleEmailChange = (e) => {
    const email = e.target.value;
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    this.setState({ email, correctEmail: isValidEmail });
  };

  handleNumerTelephoneChange = (e) => {
    const phoneNumber = e.target.value;
    const isValidPhoneNumber = /^(\+221|0)?(77|78|76|70|75)[0-9]{7}$/.test(phoneNumber);
    this.setState({ numero_telephone: phoneNumber, correctPhoneNumber: isValidPhoneNumber });
  };

  handlePasswordChange = (e) => {
    const password = e.target.value;
    const isValidPassword = password.length >= 8;
    this.setState({ password, correctPassword: isValidPassword });
  };

  handleNomChange = (e) => {
    const nom = e.target.value;
    const isValidNom = nom.length >= 2;
    this.setState({ nom, correctNom: isValidNom });
  };

  handlePrenomChange = (e) => {
    const prenom = e.target.value;
    const isValidPrenom = prenom.length >= 2;
    this.setState({ prenom, correctPrenom: isValidPrenom });
  };

  handlePasswordConfirmChange = (e) => {
    const confirmPassword = e.target.value;
    const isValidConfirmPassword = confirmPassword === this.state.password;
    this.setState({ confirmPassword, correctPasswordConfirm: isValidConfirmPassword });
  };

  handleRegistrer = async (e) => {
	e.preventDefault();
	const {
	  email,
	  password,
	  confirmPassword,
	  nom,
	  prenom,
	  numero_telephone,
	  correctEmail,
	  correctPassword,
	  correctNom,
	  correctPrenom,
	  correctPasswordConfirm,
	  correctPhoneNumber,
	} = this.state;
  
	if (correctEmail && correctPassword && correctNom && correctPrenom && correctPasswordConfirm && correctPhoneNumber) {
	  try {
		// Call the register API function
		const response = await register(nom, prenom, email, numero_telephone, password);
		// Assuming the API returns success
		this.setState({ isRegisterIn: true });
	  } catch (error) {
		console.error('Registration failed', error);
	  }
	} else {
	  // Set correct flags for invalid fields
	  this.setState({
		correctEmail: !!correctEmail,
		correctPassword: !!correctPassword,
		correctNom: !!correctNom,
		correctPrenom: !!correctPrenom,
		correctPasswordConfirm: !!correctPasswordConfirm,
		correctPhoneNumber: !!correctPhoneNumber,
	  });
	}
  };
  

  render() {

	const { isRegisterIn } = this.state;

    // If logged in, redirect to the admin dashboard
    if (isRegisterIn) {
      return <Navigate to="/sign-in" replace />;
    }

    return (
      <div className="signup-page-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-12 col-lg-12">
              <form className="signin-inner">
                <div className="row">
                  <div className="col-6">
                    <div className={this.state.correctPrenom === false ? 'single-input-inner-custome-error' : 'single-input-inner style-bg-border'}>
                      <input type="text" name="prenom" value={this.state.prenom} onChange={this.handlePrenomChange} placeholder="Prénom" />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className={this.state.correctNom === false ? 'single-input-inner-custome-error' : 'single-input-inner style-bg-border'}>
                      <input type="text" name="nom" value={this.state.nom} onChange={this.handleNomChange} placeholder="Nom" />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className={this.state.correctEmail === false ? 'single-input-inner-custome-error' : 'single-input-inner style-bg-border'}>
                      <input type="email" name="email" value={this.state.email} onChange={this.handleEmailChange} placeholder="Email" />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className={this.state.correctPhoneNumber === false ? 'single-input-inner-custome-error' : 'single-input-inner style-bg-border'}>
                      <input type="text" name="numero_telephone" value={this.state.numero_telephone} onChange={this.handleNumerTelephoneChange} placeholder="Numéro de téléphone" />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className={this.state.correctPassword === false ? 'single-input-inner-custome-error' : 'single-input-inner style-bg-border'}>
                      <input type="password" name="password" value={this.state.password} onChange={this.handlePasswordChange} placeholder="Mot de passe" />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className={this.state.correctPasswordConfirm === false ? 'single-input-inner-custome-error' : 'single-input-inner style-bg-border'}>
                      <input type="password" name="confirmPassword" value={this.state.confirmPassword} onChange={this.handlePasswordConfirmChange} placeholder="Confirmer le mot de passe" />
                    </div>
                  </div>
                  <div className="col-12 mb-4">
                    <button className="btn btn-base w-100" onClick={this.handleRegistrer}>Créer un compte</button>
                  </div>
                  <div className="col-12">
                    <span>Vous avez déjà un compte ?</span>
                    <Link className="ml-2" to="/sign-in"><strong>Se connecter</strong></Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignUP;
