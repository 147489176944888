import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
    baseURL: apiUrl,
  });
/**
 * 
 * @returns all formation sites
 */
export const lieuFormation = () =>{
    try {
        const response = apiService.get('/lieuformations').then(res =>{
            console.log(`les lieu de formations sont : ${res.data}`);
            return res.data
        })
        return response
    } catch (error) {
        throw error;
    }
}