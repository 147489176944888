import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoutes = () => {
  const isAuth = localStorage.getItem('isLoggedIn') === 'true'
  

return (
    isAuth ? <Outlet/> : <Navigate to='/sign-in'/>
  )
}

export default PrivateRoutes;
