import React from 'react';
import { useParams } from "react-router-dom";
import NavbarAdmin from "../../global-components/Navbar-admin";
import SessionData from "./session-data";
import PageHeader from '../../global-components/page-header';
import SessionDataPasse from './session-data-passe';
import SessionDataAvenir from './session-data-avenir';


const SessionAvenir = () => {
  const { id } = useParams();
  return (
    <div>
      <NavbarAdmin />
      <PageHeader headertitle="Sessions à venir" />
      <SessionDataAvenir id={id} />
      
    </div>
  );
};

export default SessionAvenir;
