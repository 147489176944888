/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar-v4';
import PageHeader from './global-components/page-header';
import Course from './section-components/course-page';
import Footer from './global-components/footer';
import { useParams, Link } from 'react-router-dom';
import { useCategorieContext } from '../context/CategorieContext';
import { typeFormationSingle } from '../services/typeformation/service';
import Formations_Data from './global-components/formations-all';

const AboutPage = () => {
  const { id } = useParams();
  const [selectType, setSelectType] = useState({ formations: [] });
  const { typeFormation } = useCategorieContext();
  const publicUrl = process.env.PUBLIC_URL + '/';

  const styleFormation = {
    paddingTop: '172px',
  }
  

  useEffect(() => {
    async function fetchTypeformation() {
      try {
        const result = await typeFormationSingle(id);
        setSelectType(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchTypeformation();
  }, [id]);

  return (
    <div>
       <Navbar />
      {id ? (
        <>
         
          
            <PageHeader headertitle={selectType.nom} />
            <Course formations={selectType.formations} />
         
          
         
        </>
      ) : (
        <>
        <p style={styleFormation}></p>
        <Formations_Data/>
        </>
        
      )}
       <Footer />
    </div>
  );
};

export default AboutPage;
