import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { useAdminContext } from '../../../context/AdminContext';
import FormationAddModal from '../formation-add-flow';
import { DeleteSessionFormation, getsessionformationByFormation } from '../../../services/session/service';
import AddSession from './modal/add-session';
import { Link } from 'react-router-dom';
import UpdateSession from './modal/update-session';
import Swal from 'sweetalert2';
import { useCategorieContext } from '../../../context/CategorieContext';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
//import 'primeicons/primeicons.css';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

export default function SessionData(props) {
    const { id } = props;
    const { incommingSession, currentUser } = useAdminContext();
    const [record, setRecord] = useState([]);
    const [showUpdateSession, setShowUpdateSession] = useState(false);
    const [formation, setFormation] = useState('');
    const { formationDatas } = useCategorieContext();
    const dt = useRef(null);

    const handleCloseUpdateSsession = () => { setShowUpdateSession(false) };
    const handleShowUpdateSsession = () => { setShowUpdateSession(true) };

    const [sessionformation, setSessionformation] = useState({
        date_debut: "",
        date_fin: "",
        cout_formation: "",
        formation_id: "",
        est_payant: 1,
        lieu_formation_id: "",
        modalites: [],
        paymentMode: {
            inscription: '',
            mensualite: '',
            nombre_moi: ''
        },
        correctdatDebut: true,
        correctDateFin: true,
        CorrectCoutFormation: true,
        correctFormatiom: true,
        correctLieuFormation: true
    });

    const fetchSessionByFormation = async () => {
        try {
            const response = await getsessionformationByFormation(id);
            setRecord(response);

            const formationLog = formationDatas.find(item => item.id == id);
            console.log('formationLog :', formationLog);
            setFormation(formationLog);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (id) {
            fetchSessionByFormation();
        } else {
            setRecord(incommingSession);
        }
    }, [id, incommingSession]);

    const handleUpdateSession = (session) => {
        setSessionformation({
            id: session.id,
            date_debut: session.date_debut,
            date_fin: session.date_fin,
            cout_formation: session.cout_formation,
            formation_id: session.formation_id,
            est_payant: session.est_payant,
            lieu_formation_id: session.lieu_formation.id,
            lieu_formation: session.lieu_formation,
            formation: session.formation,
            modalites: [...session.modalites],
            paymentMode: session.paymentMode,
            correctdatDebut: true,
            correctDateFin: true,
            CorrectCoutFormation: true,
            correctFormatiom: true,
            correctLieuFormation: true
        });
        handleShowUpdateSsession();
    };

    const handleDeleteConfirmation = (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
        });
        swalWithBootstrapButtons.fire({
            title: "Êtes-vous sûr(e) ?",
            text: "Vous ne pourrez pas revenir en arrière !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui, supprimez !",
            cancelButtonText: "Non, annulez !",
            confirmButtonColor: "#fdc800",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteFormation(id);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire({
                    title: "Annulé",
                    text: "La session est en sécurité :)",
                    icon: "error"
                });
            }
        });
    };

    const handleDeleteFormation = async (id) => {
        try {
            await DeleteSessionFormation(id);
            if (id) {
                setRecord(record.filter(session => session.id !== id));
            }
            Swal.fire({
                icon: "success",
                title: "session supprimée avec succès !",
                showConfirmButton: false,
                timer: 2000
            });
        } catch (error) {
            console.error("Erreur lors de la suppression de la formation :", error);
            Swal.fire({
                icon: "error",
                title: "Oups...",
                text: "Une erreur s'est produite lors de la suppression de la formation.",
                showConfirmButton: false,
                timer: 3000
            });
        }
    };

    const linkColor = { color: '#fdc800' };
    const colorEdit = { color: '#fdc800' };
    const colorDrop = { color: 'red' };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <i className="fa fa-edit" title='modifier' style={colorEdit} onClick={() => handleUpdateSession(rowData)} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <i className="fa fa-trash" title='supprimer' style={colorDrop} onClick={() => handleDeleteConfirmation(rowData.id)} />
            </>
        );
    };

    const inscritsBodyTemplate = (rowData) => {
        return (
            <Link to={`/list-inscrit/${rowData.id}`} style={linkColor}>
                {rowData.sessionFormationUsers ? rowData.sessionFormationUsers.length : 0}
            </Link>
        );
    };

    const handleFilter = (e) => {
        const newData = incommingSession.filter(row => {
            return row.formation.nom.toLowerCase().includes(e.target.value.toLowerCase());
        });
        setRecord(newData);
    };

    const searchStyles = {
        border: '2px solid',
        height: '45px',
        borderRadius: '26px',
    };
    const stylePadding_top = {
        paddingTop: '37px',
    };

    const [showAddSsession, setShowAddSsession] = useState(false);

    const handleCloseAddSsession = () => { setShowAddSsession(false) };
    const handleShowAddSsession = () => setShowAddSsession(true);

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const exportPdf = () => {
        const doc = new jsPDF();
        const exportColumns = [
            { title: 'Debut Session', dataKey: 'date_debut' },
            { title: 'Fin Session', dataKey: 'date_fin' },
            { title: 'Formation', dataKey: 'formation' },
            { title: 'LieuFormation', dataKey: 'lieu_formation' },
            { title: 'Horaire', dataKey: 'modalites' },
            { title: 'Nombre Inscrits', dataKey: 'sessionFormationUsers' },
        ];
        const data = record.map(row => ({
            date_debut: row.date_debut,
            date_fin: row.date_fin,
            formation: row.formation.nom,
            lieu_formation: row.lieu_formation.region,
            modalites: row.modalites[0] ? row.modalites[0].modalite : '',
            sessionFormationUsers: row.sessionFormationUsers ? row.sessionFormationUsers.length : 0,
        }));
        doc.autoTable(exportColumns, data);
        doc.save('sessions.pdf');
    };

    const exportExcel = () => {
        const data = record.map(row => ({
            date_debut: row.date_debut,
            date_fin: row.date_fin,
            formation: row.formation.nom,
            lieu_formation: row.lieu_formation.region,
            modalites: row.modalites[0] ? row.modalites[0].modalite : '',
            sessionFormationUsers: row.sessionFormationUsers ? row.sessionFormationUsers.length : 0,
        }));
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });
        saveAsExcelFile(excelBuffer, 'sessions');
    };

    const saveAsExcelFile = (buffer, fileName) => {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], { type: EXCEL_TYPE });
        saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file" rounded onClick={exportCSV} data-pr-tooltip="CSV" />
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
            <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
        </div>
    );

    return (
        <div className="blog-area pd-bottom-120 go-top">
            <div className="container">
                <div className="row" style={stylePadding_top}>
                    <div className="col-md-6">
                        <div className="single-input-inner">
                            <Button className="btn btn-secondary btn-radius" size="lg" onClick={handleShowAddSsession}>
                                ajourer
                            </Button>
                            <AddSession
                                handleCloseAddSsession={handleCloseAddSsession}
                                showAddSsession={showAddSsession}
                                id={id}
                                setRecord={setRecord}
                                record={record}
                                formation={formation}
                                type='formation'
                            />
                            <UpdateSession
                                handleCloseUpdateSsession={handleCloseUpdateSsession}
                                showUpdateSession={showUpdateSession}
                                sessionformation={sessionformation}
                                setRecord={setRecord}
                                id={id}
                                setSessionformation={setSessionformation}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 d-grid gap-2 d-flex justify-content-end">
                        <input type='text' placeholder='     rechercher' onChange={handleFilter} style={searchStyles} />
                    </div>
                    <div className="col-lg-12">
                        <div className="single-blog-inner style-border">
                            <Tooltip target=".export-buttons>button" position="bottom" />
                            
                            <div style={{ overflowX: 'auto' }}>
                                <DataTable ref={dt} value={record} header={header} tableStyle={{ minWidth: '50rem' }} paginator rows={10}>
                                    <Column field="date_debut" header="Debut Session" sortable />
                                    <Column field="date_fin" header="Fin Session" sortable />
                                    <Column field="formation.nom" header="Formation" sortable />
                                    <Column field="lieu_formation.region" header="LieuFormation" sortable />
                                    <Column field="modalites[0].modalite" header="Horaire" sortable />
                                    <Column body={inscritsBodyTemplate} header="Nombre Inscrits" sortable />
                                    <Column body={actionBodyTemplate} header="Actions" />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}