import React, { useState, useRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useCategorieContext } from "../../../../context/CategorieContext";
import { addFormation, formations } from "../../../../services/formation/service";
import { RingLoader } from "react-spinners";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { subscribeByAdmin, updateSubscribUserSession } from "../../../../services/inscription/service";
import { Navigate } from "react-router-dom";


const UpdateInscription = (props)=> {
    const {
        updateUserSession,
        handleCloseUpdateUserSession,
        setUserSession,
        userSession,
        formation,
        id,
      } = props;
    
     
      const [loading, setLoading] = useState(false);
      const [isCorrect, setIsCorrect] = useState(false);
      const [isDisabled, setIsDisabled] = useState(true);
      const [canRedirect,setCanRedirect] = useState(false)
    
      const [sessionUser, setSessionUser] = useState({
        nom: "",
        prenom: "",
        email: "",
        session: "",
        modalite: "",
        numero_telephone: "",
        status: "",
        niveau: "",
        date_naissance: null,
        source: "",
        lieu_formation_id: "",
        modalites: [],
        correctNom: true,
        correctPrenom: true,
        correctTelephone: true,
        correctEmail: true,
      });
      const [isCreated, setIsCreated] = useState(false);
      const [isError, setIsError] = useState({ value: false, message: "" });
      const [sessionformation, setSessionFormation] = useState({ id: 0 });
      const [modalite, setModalite] = useState([]);
      const [modaliteId, setModaliteId] = useState(0);
    
      const handleChange = (e) => {
        // Mettre à jour les valeurs du formulaire
        setUserSession({
            ...userSession,
            user:{
                ...userSession.user,
                [e.target.name]: e.target.value,
            }
        })
        updateIsCorrectState();
      };
    
      const handleTelephoneChange = (e) => {
        const value = e.target.value;
        setSessionUser({
          ...sessionUser,
          [e.target.name]: value,
          correctTelephone: /^(\+221|0)?(77|78|76|70|75)[0-9]{7}$/.test(value),
        });
        setUserSession({
            ...userSession,
            user:{
                ...userSession.user,
                [e.target.name]: e.target.value,
            }
        })
        updateIsCorrectState();
      };
    
      const handleEmailChange = (e) => {
        const value = e.target.value;
        setSessionUser({
          ...sessionUser,
          [e.target.name]: value,
          correctEmail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
        });
        setUserSession({
            ...userSession,
            user:{
                ...userSession.user,
                [e.target.name]: e.target.value,
            }
        })
        updateIsCorrectState();
      };
    
      const handleSelectChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (value === 0) {
            setIsDisabled(true)
        }else{
          setIsDisabled(false)
        }
        const sessionf =
        formation.sessionFormation.find((session) => session.id === value) || {
          id: 0,
          modalites: [],
        };
      console.log("sessionf : ", sessionf);
      setSessionUser((prevSessionUser) => ({
        ...prevSessionUser,
        session: value,
        modalite: sessionf.modalites.length > 0 ? sessionf.modalites[0].id : 0,
      }));
      setUserSession((prevSessionUser) => ({
        ...prevSessionUser,
        session:{
            ...sessionf,
        }
      }));

      setSessionFormation(sessionf);
      setModalite(sessionf.modalites);
      setModaliteId(sessionf.modalites.length > 0 ? sessionf.modalites[0].id : 0);
    
          console.log('sessionUser: ',userSession);
          console.log('modalite: ',modalite);
    
        updateIsCorrectState();
      };
    
      const handleChangeModalite = (e) => {
        const value = parseInt(e.target.value, 10);

        setModaliteId(value);
        setSessionUser({
          ...sessionUser,
          modalite: value,
        });
        const modalited = modalite.find((id) => id === value) || {
            id: 0,
            modalites: [],
          };
          setUserSession((prevSessionUser) => ({
            ...prevSessionUser,
            modalite:{
                ...modalited,
            }
          }));
        updateIsCorrectState();
      };
    
      const buttonStyles = {
        width: "100%",
        border: "2px solid rgba(8, 76, 148, 0.2)",
        height: "58px",
        borderRadius: "26px",
        padding: "0 18px",
      };
    
      const showSuccessAlert = () => {
        handleCloseUpdateUserSession();
        Swal.fire({
          icon: "success",
          title: "Mise a jour de l'inscription a été faite avec succès !",
          showConfirmButton: false,
          timer: 5000,
        });
        setIsCreated(false);
      };
    
      const showErrorAlert = () => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: isError.message,
          showConfirmButton: false,
          timer: 5000,
        });
        setIsError({ ...isError, value: false });
      };
      const handleUpdateInscription = async () => {
        try {
          setLoading(true);
          const response = await updateSubscribUserSession(userSession);
          if (response) {
            setIsCreated(true);
          }
        } catch (error) {
            console.log('error: ',error.response.data);
          setIsError({
            value: true,
            message: `${error.response.data}`,
          });
        } finally {
          setLoading(false);
          setIsCorrect(false);
          if (isCreated) {
            setCanRedirect(true)
          }
        }
      };


    
      const updateIsCorrectState = () => {
        const isCorrectvalu =
          sessionUser.nom.length > 1 &&
          sessionUser.prenom.length > 1 &&
          /^(\+221|0)?(77|78|76|70|75)[0-9]{7}$/.test(sessionUser.numero_telephone) &&
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(sessionUser.email);
          setIsDisabled(!isCorrectvalu);
      };
      const convertDate = (dates)=>{
        if (dates) {
            const [day, month, year] = dates.split('-');
            const formattedDate = `${year}-${month}-${day}`;
            return formattedDate
        }
        return null;
    }

    if (canRedirect) {
      return <Navigate to={`/list-inscrit/${id}`}  replace={true}  />;
    }
      return (
        <>
        { isCreated &&  showSuccessAlert()}
           {isError.value && showErrorAlert()}
          <Modal show={updateUserSession} onHide={handleCloseUpdateUserSession} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Modification de l'inscription à la formation </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {loading ? (
                <div className="border d-flex align-items-center justify-content-center">
                  <RingLoader color="#fdc800" size="140" loading={loading} />
                </div>
              ) : (
                <Form>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Nom</Form.Label>
                        <div className={sessionUser.correctNom ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                          <input type="text" name="nom" onChange={handleChange} className="form-control" value={userSession && userSession.user && userSession.user.nom}/>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Prenom</Form.Label>
                        <div className={sessionUser.correctPrenom ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                          <input type="text" name="prenom" onChange={handleChange} className="form-control" value={userSession && userSession.user && userSession.user.prenom} />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <div className={sessionUser.correctEmail ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                          <input type="email" name="email" onChange={handleEmailChange} className="form-control" value={userSession && userSession.user && userSession.user.email} />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Téléphone</Form.Label>
                        <div className={sessionUser.correctTelephone ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                          <input type="tel" name="numero_telephone" onChange={handleTelephoneChange} className="form-control" value={userSession && userSession.user && userSession.user.numero_telephone}/>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Status</Form.Label>
                        <div className={sessionUser.correctNom ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                          <input type="text" name="status" onChange={handleChange} className="form-control" value={userSession && userSession.user && userSession.user.status}/>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Niveau d'etude</Form.Label>
                        <div className={sessionUser.correctPrenom ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                          <input type="text" name="niveau" onChange={handleChange} className="form-control" value={userSession && userSession.user && userSession.user.niveau}/>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>date naissance</Form.Label>
                        <div className={sessionUser.correctNom ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                          <input type="date" name="date_naissance" onChange={handleChange} className="form-control" value={userSession && userSession.user && convertDate(userSession.user.date_naissance)}/>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Source d'information ?</Form.Label>
                        <div className={sessionUser.correctPrenom ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                          <input type="text" name="source" onChange={handleChange} className="form-control" value={userSession && userSession.user && userSession.user.source}/>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <Form.Group>
                        <Form.Label>Lieu de formation</Form.Label>
                        <div className="single-input-inner-custome">
                          <Form.Select
                            aria-label="Sélectionner une session"
                            onChange={handleSelectChange}
                            
                            name="session"
                          >
                            {userSession && userSession.session && userSession.session.lieu_formation && <option value={userSession.session.id}>{userSession.session.lieu_formation.region}/{userSession.session.lieu_formation.commune}</option>}
                             
                            
                            {formation && userSession && userSession.session &&
                              formation.sessionFormation.map((sessionItem, index) => {
                                if (sessionItem.id !== userSession.session.id) {
                                    return (
                                        <option key={index} value={sessionItem.id}>
                                        {sessionItem.lieu_formation.region}/{sessionItem.lieu_formation.commune}
                                      </option>
                                    )
                                }
                              })}
                          </Form.Select>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <Form.Group>
                        <Form.Label>Horaire de formation</Form.Label>
                        <div className="single-input-inner-custome">
                          <Form.Select
                            aria-label="Sélectionner une modalité"
                            name="mode"
                            onChange={handleChangeModalite}
                            value={modaliteId}
                          >
                            {modalite.length > 0 ? (
                              modalite.map((modal, index) => (
                                <option key={index} value={modal.id}>
                                  {modal.modalite} - {modal.jour} - {modal.heure}
                                </option>
                              ))
                            ) : (
                                userSession && userSession.modalite && <option value={0}>{userSession.modalite.modalite} - {userSession.modalite.jour} - {userSession.modalite.heure}</option>
                            )}
                          </Form.Select>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6 text-left">
                        <Button className="single-input-inner btn btn-secondary btn-radius" size="lg" style={buttonStyles} onClick={handleCloseUpdateUserSession}>
                          Fermer
                        </Button>
                      </div>
                      <div className="col-md-6 text-right">
                        <Button className="btn btn-base btn-radius" size="lg" style={buttonStyles} onClick={handleUpdateInscription} >
                          Inscrire
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Modal.Body>
          </Modal>
        </>
      );
}

export default UpdateInscription