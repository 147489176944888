import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Modal } from 'react-bootstrap';
import { useAdminContext } from '../../../context/AdminContext';
import FormationAddModal from '../formation-add-flow';
import { DeleteSessionFormation, getsessionformationByFormation } from '../../../services/session/service';
import AddSession from './modal/add-session';
import { Link } from 'react-router-dom';
import UpdateSession from './modal/update-session';
import Swal from 'sweetalert2';
import { sessionAvenirTypeFormationService, sessionPasseTypeFormationService, sessionTypeFormationService } from '../../../services/typeformation/service';

export default function SessionDataAvenir(props) {
    const {id} = props
    const { incommingSession,currentUser } = useAdminContext();
    const [record, setRecord] = useState([]);
    const [showUpdateSession, setShowUpdateSession] = useState(false)
    const [formation,setFormation] = useState('')


    const handleCloseUpdateSsession = () => {setShowUpdateSession(false)};
    const handleShowUpdateSsession = () =>   {setShowUpdateSession(true)};

    const [sessionformation, setSessionformation] = useState({
        date_debut: "",
        date_fin: "",
        cout_formation: "",
        formation_id: "",
        est_payant: 1,
        lieu_formation_id: "",
        modalites: [],
        paymentMode: {
            inscription: '',
            mensualite: '',
            nombre_moi: ''
        },
        correctdatDebut: true,
        correctDateFin: true,
        CorrectCoutFormation: true,
        correctFormatiom: true,
        correctLieuFormation: true
    });

    const fetchSessionByFormation = async (id) =>{

        try {
             const response = await sessionAvenirTypeFormationService(id)

             setRecord(response)
             setFormation(response[0].formation)
             console.log('response: ',response);
        } catch (error) {
            
        }
    }

    const linkColor = {
        color: '#fdc800',
    }

    useEffect(() => {
       
            fetchSessionByFormation(id);
        
    }, [id]);

 

    const handleUpdateSession = (session) =>{

        setSessionformation({
            id: session.id,
            date_debut: session.date_debut,
            date_fin: session.date_fin,
            cout_formation: session.cout_formation,
            formation_id: session.formation_id,
            est_payant: session.est_payant,
            lieu_formation_id: session.lieu_formation.id,
            lieu_formation: session.lieu_formation,
            formation: session.formation,
            modalites: [...session.modalites],
            paymentMode: session.paymentMode,
            correctdatDebut: true,
            correctDateFin: true,
            CorrectCoutFormation: true,
            correctFormatiom: true,
            correctLieuFormation: true
        })
        handleShowUpdateSsession();

       

    }

    const colorEdit = {
        color: '#fdc800',
      }

      const handleDeleteConfirmation = (id) =>{
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
          });
        swalWithBootstrapButtons.fire({
            title: "Êtes-vous sûr(e) ?",
            text: "Vous ne pourrez pas revenir en arrière !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui, supprimez !",
            cancelButtonText: "Non, annulez !",
            confirmButtonColor: "#fdc800",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                // Gérer la logique de suppression ici
                handleDeleteFormation(id);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire({
                    title: "Annulé",
                    text: "La session est en sécurité :)",
                    icon: "error"
                });
            }
        });
      
    }

    const handleDeleteFormation = async (id) => {
        try {
            // Effectuer la logique de suppression ici, comme appeler un service deleteFormation
            console.log(`Suppression de la session avec l'ID ${id}`);
            // Une fois la suppression réussie, mettez à jour les données
            await DeleteSessionFormation(id);
            if (id) {
                setRecord(record.filter(session => session.id !== id)); // Remove deleted session from the table data
            }
            // Facultatif : afficher un message de réussite
            Swal.fire({
                icon: "success",
                title: "session supprimée avec succès !",
                showConfirmButton: false,
                timer: 2000
            });
        } catch (error) {
            console.error("Erreur lors de la suppression de la formation :", error);
            // Afficher un message d'erreur si la suppression échoue
            Swal.fire({
                icon: "error",
                title: "Oups...",
                text: "Une erreur s'est produite lors de la suppression de la formation.",
                showConfirmButton: false,
                timer: 3000
            });
        }
    };

    const columns = [
       
        {
            name: 'Debut Session',
            selector: row => row.date_debut,
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, date_debut) => {
                return { textAlign: "center" };   // removed partial line here
              },
        },
        {
            name: 'Fin Session',
            selector: row => row.date_fin,
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, date_fin) => {
                return { textAlign: "center" };   // removed partial line here
              },
        },
        {
            name: 'Formation',
            selector: row => row.formation.nom,
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, nom) => {
                return { textAlign: "center" };   // removed partial line here
              },
        },
        {
            name: 'LieuFormation',
            selector: row => row.lieu_formation.region,
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, region) => {
                return { textAlign: "center" };   // removed partial line here
              },
        },
       
        {
            name: 'Horaire',
            selector: row => row.modalites[0] ? row.modalites[0].modalite : '',
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, modalite) => {
                return { textAlign: "center" };   // removed partial line here
              },
        }
        ,
        {
            name: 'Nombre Inscrits',
            cell: row => (
                <Link to={`/list-inscrit/${row.id}`} style={linkColor}>
                    { row.sessionFormationUsers ? row.sessionFormationUsers.length: 0}
                </Link>
            ),
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, Inscrits) => {
                return { textAlign: "center" };   // removed partial line here
              },
        }
        ,
        {
            name: 'Actions', // Title of the column
            cell: row => (
                <>
                <i className="fa fa-edit" title='modifier' style={colorEdit} onClick={() => handleUpdateSession(row)}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <i className="fa fa-trash" title='supprimer' style={colorDrop} onClick={() => handleDeleteConfirmation(row.id)}/>
                </>
            ),
            allowOverflow: true,
            button: true
        },
    ];

    const handleFilter = (e) => {
        const newData = incommingSession.filter(row => {
            return row.formation.nom.toLowerCase().includes(e.target.value.toLowerCase());
        });
        setRecord(newData);
    };


    const searchStyles = {
        border: '2px solid',
        height: '45px',
        borderRadius: '26px',
      };
      const stylePadding_top = {
        paddingTop: '37px',
      }
      const [showAddSsession,setShowAddSsession] = useState(false)
      const colorDrop = {
        color: 'red',
      }

      const handleCloseAddSsession = () => {setShowAddSsession(false)};
	  const handleShowAddSsession = () => setShowAddSsession(true);


    return (
        <div className="blog-area pd-bottom-120 go-top">
            <div className="container">
                <div className="row" style={stylePadding_top}>
                <div className="col-md-6">
                                <div className="single-input-inner">
                                <Button className="btn btn-secondary btn-radius"  size="lg" onClick={handleShowAddSsession}>
                                    ajourer
                                </Button>
                                <AddSession  handleCloseAddSsession={handleCloseAddSsession}
                                             showAddSsession={showAddSsession}
                                             id={id}
                                             setRecord={setRecord}
                                             record={record}
                                             formation={formation}
                                             type='avenir'
                                             />
                                <UpdateSession handleCloseUpdateSsession={handleCloseUpdateSsession}
                                               showUpdateSession={showUpdateSession}
                                               sessionformation={sessionformation}
                                               setRecord={setRecord}
                                               id={id}
                                               setSessionformation={setSessionformation} 
                                               />

                                </div>
                            </div>
                            <div className="col-md-6 d-grid gap-2 d-flex justify-content-end">

                            <input type='text'   placeholder='     rechercher' onChange={handleFilter} style={searchStyles}/>

                            
                            </div>
                    <div className="col-lg-12">
                        <div className="single-blog-inner style-border">
                           
                            <div style={{ overflowX: 'auto' }}>
                            <DataTable
                                columns={columns} 
                                data={record}
                                pagination
                               
                                
                            />
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
