import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { questionStore } from '../../services/question/service';

class Contact extends Component {

	constructor(props) {
		super(props);
		this.state = {
			question: {
				nom_complet: '',
				email: '',
				telephone: '',
				objet: '',
				message: '',
				correctNomcomplete: true, 
				correctObjet: true, 
				correctMessage: true, 
				correctEmail: true, 
				correctTelephone: true 
			}
			
		};
	  }

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

		const handleOnChange = (e) => {
			const { name, value } = e.target;
			let correctValue = true;
			if (name === 'nom_complet' || name === 'message') {
				correctValue = value.length >= 2;
			} else if (name === 'email') {
				correctValue = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
			} else if (name === 'telephone') {
				correctValue = /^(\+221|0)?(77|78|76|70|75)[0-9]{7}$/.test(value);
			}
			this.setState({
				question: {
					...this.state.question,
					[name]: value,
					[`correct${name.charAt(0).toUpperCase() + name.slice(1)}`]: correctValue
				}
			});
		}

		const handleQuestion = async (e) =>{
			if (this.state.question.correctEmail && this.state.question.correctNomcomplete && this.state.question.correctMessage && this.state.question.email.length > 0 && this.state.question.nom_complet.length > 0 && this.state.question.message.length > 0) {
				try {
					const response = await questionStore(this.state.question)
					Swal.fire({
						position: "top-end",
						icon: "success",
						title: "Votre inscription a été enregistré avec succés !",
						showConfirmButton: false,
						timer: 2000
					  });
					  this.setState({
						subscribe: {
							nom_complet: '',
							email: '',
							telephone: '',
							objet: '',
							message: '',
							correctNomcomplete: true, 
							correctObjet: true, 
							correctMessage: true, 
							correctEmail: true, 
							correctTelephone: true 
						}
					});
					document.querySelectorAll('input').forEach(input => input.value = '');

				} catch (error) {

					this.setState({
						subscribe: {
							nom_complet: '',
							email: '',
							telephone: '',
							objet: '',
							message: '',
							correctNomcomplete: true, 
							correctObjet: true, 
							correctMessage: true, 
							correctEmail: true, 
							correctTelephone: true 
						}
					});
					document.querySelectorAll('input').forEach(input => input.value = '');

					Swal.fire({
						icon: "warning",
						title: error.response.data,
						showConfirmButton: false,
						timer: 4000
					  });

				}

				
			} else {
				
			}
			
		}



    return  <div className="contact-area bg-overlay mt-200 pd-bottom-90" style={{backgroundImage: 'url("'+publicUrl+'assets/img/banner/2.png")'}}>
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-8">
			        <form className="contact-form-inner mt-mn-200 style-shadow">
			          <div className="section-title">
			            <h2 className="title">Poser nous vos questions</h2>
			            <p>Nous serons heureux de répondre à vos questions.</p>
			          </div>
			          <div className="row">
			            <div className="col-md-6">
			              <div className={this.state.question.correctNomcomplete ? "single-input-inner-custome style-right-icon" : "single-input-inner-custome-error style-right-icon"}>
			                <input type="text" placeholder="nom cmplete" name='nom_complet'onChange={handleOnChange}/>
			              </div>
			            </div>
			            <div className="col-md-6">
						<div className={this.state.question.correctTelephone ? "single-input-inner-custome style-right-icon" : "single-input-inner-custome-error style-right-icon"}>
			                <input type="text" placeholder="numero téléphone" name='telephone' onChange={handleOnChange}/>
			              </div>
			            </div>
			            <div className="col-md-6">
						<div className={this.state.question.correctEmail ? "single-input-inner-custome style-right-icon" : "single-input-inner-custome-error style-right-icon"}>
			                <input type="text" placeholder="Adresse email" name='email'onChange={handleOnChange}/>
			              </div>
			            </div>
			            <div className="col-md-6">
						<div className={this.state.question.correctObjet ? "single-input-inner-custome style-right-icon" : "single-input-inner-custome-error style-right-icon"}>
			                <input type="text" placeholder="Objet"  name='objet'onChange={handleOnChange}/>
			              </div>
			            </div>
			            <div className="col-12">
						<div className={this.state.question.correctMessage ? "single-input-inner-custome style-right-icon" : "single-input-inner-custome style-right-icon"}>
			                <textarea placeholder="Votre message" defaultValue={""}  name='message' onChange={handleOnChange}/>
			              </div>
			            </div>
			            <div className="col-sm-6 align-self-center">
			              <div className="single-input-inner style-checkbox">
			                <input type="checkbox" />
			                S'incrire
			              </div>
			            </div>
			            <div className="col-sm-6 text-sm-right">
			              <Button className="btn btn-base" onClick={handleQuestion}>Envoyer</Button>
			            </div>
			          </div>
			        </form>
			      </div>
			      <div className="col-lg-4 align-self-end">
			        <div className="mt-5 mt-lg-0">
			          <ul className="single-list-wrap">
			            <li className="single-list-inner style-white style-check-box-grid-2">
			              <div className="media">
			                <div className="media-left">
			                  <img src={publicUrl+"assets/img/icon/16.png"} alt="img" />
			                </div>
			                <div className="media-body align-self-center">
			                  <h5>Nos adresses</h5>
			                  <p>Dakar, Médina Rue 17 angle 20. Immeuble R+6.</p>
			                  <p>Tivaouane près du Lycée Ababacar Sy</p>
			                </div>
			              </div>
			            </li>
			            <li className="single-list-inner style-white style-check-box-grid-2">
			              <div className="media">
			                <div className="media-left">
			                  <img src={publicUrl+"assets/img/icon/17.png"} alt="img" />
			                </div>
			                <div className="media-body align-self-center">
			                  <h5>Nos contacts</h5>
			                  <p>+221 77 361 13 04</p>
			                  <p>+221 76 749 90 96</p>
			                </div>
			              </div>
			            </li>
			            <li className="single-list-inner style-white style-check-box-grid-2">
			              <div className="media">
			                <div className="media-left">
			                  <img src={publicUrl+"assets/img/icon/18.png"} alt="img" />
			                </div>
			                <div className="media-body align-self-center">
			                  <h5>nos Email</h5>
			                  <p>contact@bakhbade.com</p>
			                </div>
			              </div>
			            </li>
			          </ul>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

        }
}

export default Contact