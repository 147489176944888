/* eslint-disable react/jsx-pascal-case */
// routes.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeV1 from './components/home-v1';
import HomeV2 from './components/home-v2';
import HomeV3 from './components/home-v3';
import Course from './components/course';
import CourseDetails from './components/course-details';
import About from './components/about';
import Event from './components/event';
import EventDetails from './components/event-details';
import Instructor from './components/instructor';
import InstructorDetails from './components/instructor-details';
import Pricing from './components/pricing';
import Gallery from './components/gallery';
import SignIn from './components/sign-in';
import SignUp from './components/sign-up';
import Contact from './components/contact';
import Blog from './components/blog';
import BlogDetails from './components/blog-details';
import Dashbord_Admin from "./components/dashbord-admin";
import PrivateRoutes from "./privateRoute";
import Admin_Page from "./components/Admin_Page.";
import SessionFormation from "./components/section-components/administration/session-formation";
import ListInscrit from "./components/section-components/administration/list-inscrit";
import Evenements from "./components/section-components/administration/evenement";
import ResetPassword from "./components/section-components/reset-password";
import EvenParticipant from "./components/section-components/administration/even-participants";
import InscriptionPage from "./components/inscription-page";
import OrangeQrcode from "./components/section-components/paiement-section/orange-qrcode";
import TypeFormation from "./components/section-components/administration/type-formation";
import FormationTypeFormation from "./components/formation-type";
import Citations from "./components/citations";
import Temoignages from "./components/temoignages";
import SessionPasse from "./components/section-components/administration/session-passe";
import SessionAvenir from "./components/section-components/administration/session-avenir";
import LogoutPage from "./components/logout";
import EventParticipation from "./components/EventParticipation";



const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<HomeV1 />} />
    <Route path="/home-v2" element={<HomeV2 />} />
    <Route path="/home-v3" element={<HomeV3 />} />
    <Route path="/course/:id?" element={<Course />} />
    <Route path="/course-details/:id" element={<CourseDetails />} />
    <Route path="/inscription/session/:id" element={<InscriptionPage />} />
    <Route path="/getQrcode/session/:qrcode" element={<OrangeQrcode />} />
    <Route path="/about" element={<About />} />
    <Route path="/event" element={<Event />} />
    <Route path="/event-details/:id" element={<EventDetails />} />
    <Route path="/event/participation/:id" element={<EventParticipation />} />
    <Route path="/instructor" element={<Instructor />} />
    <Route path="/instructor-details" element={<InstructorDetails />} />
    <Route path="/pricing" element={<Pricing />} />
    <Route path="/gallery" element={<Gallery />} />
    <Route path="/sign-in" element={<SignIn />} />
    <Route path="/sign-up" element={<SignUp />} />
    <Route path="/log-out" element={<LogoutPage />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/blog" element={<Blog />} />
    <Route path="/verifyemail/:token" element={<ResetPassword />} />
    <Route path="/blog-details" element={<BlogDetails />} />
    <Route element={<PrivateRoutes/>}>
         <Route path="/dashboard-admin" element={<Dashbord_Admin />} />
         <Route path="/formation-type/:id" element={<FormationTypeFormation />} />
         <Route path="/admin-page" element={<Admin_Page />} />
         <Route path="/session-formation/:id?" element={<SessionFormation />} /> {/* Changement ici */}
         <Route path="/session-passe/:id?" element={<SessionPasse />} /> {/* Changement ici */}
         <Route path="/session-avenir/:id?" element={<SessionAvenir />} /> {/* Changement ici */}
         <Route path="/list-inscrit/:id?" element={<ListInscrit />} /> {/* Changement ici */}
         <Route path="/even-participant/:id" element={<EvenParticipant />} />
         <Route path="/evenements" element={<Evenements />} /> {/* Changement ici */}
         <Route path="/type-formation" element={<TypeFormation />} /> {/* Changement ici */}
         <Route path="/citation" element={<Citations />} /> {/* Changement ici */}
         <Route path="/temoignages" element={<Temoignages />} />

     </Route>
    
    <Route path="*" element={<p>There's nothing here: 404!</p>} />

    {/* Add more routes as needed */}
  </Routes>
);

export default AppRoutes;
