import React from 'react';
import Navbar from './global-components/navbar-v4';
import PageHeader from './global-components/page-header';
import About from './section-components/about-v2';
import FunFact from './section-components/fun-fact';
import Team from './section-components/team-v3';
import SpecialArea from './section-components/special-area';
import Testimonial from './section-components/testimonial-v4';
import Event from './section-components/upcoming-event';
import Footer from './global-components/footer';
import BlogAbout from './blog-components/bloc-about';

const AboutPage = () => {

    const aboutStyles = {
        padding_top: '50px',
      };

      const padding_top={
        paddingTop: '80px'
      }
    return <div>
        <Navbar />
        <div style={padding_top}></div>
        <About canSee={false}/>
        <BlogAbout />
        {/* <FunFact />
        <Team />
        <SpecialArea />
        <Testimonial />
        <Event /> */}
        <Footer />
    </div>
}

export default AboutPage

