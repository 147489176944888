import React, { useState, useRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { RingLoader } from "react-spinners";
import Swal from "sweetalert2";
import "react-quill/dist/quill.snow.css";
import { addTemoignageService, getTemoignagesService, updateTemoignageService } from "../../../../services/temoignage/service";
import ReactQuill from "react-quill";

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean']
  ]
};

const UpdateTemoignageModal = (props) => {
  const { showUpdateTemoignage, handleCloseUpdateTemoignage, setRecord ,temoignage,setTemoignage} = props;

  const [loading, setLoading] = useState(false);
 
  const [imageUrlDetail, setImageUrlDetail] = useState(null);
  const [isCreated, setIsCreated] = useState(false);
  const [isError, setIsError] = useState({ value: false, message: "" });

  const handleChange = (e) => {
    setTemoignage({
      ...temoignage,
      [e.target.name]: e.target.value,
    });
  };

  const fileInputDetailsRef = useRef(null);
  const apiUrl = process.env.REACT_APP_API_BASE;

  const showSuccessAlert = () => {
    handleCloseUpdateTemoignage();
    Swal.fire({
      icon: "success",
      title: "Modification du temoignage a été ajoutée avec succès !",
      showConfirmButton: false,
      timer: 5000,
    });
    setIsCreated(false);
  };

  const showErrorAlert = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: isError.message,
      showConfirmButton: false,
      timer: 5000,
    });
    setIsError({ ...isError, value: false });
  };

  const handleUpdateTemoignage = async () => {
    try {
      setLoading(true);
      const response = await updateTemoignageService(temoignage);
      const temoignageResponse = await getTemoignagesService();
      setRecord(temoignageResponse);
      if (response) {
        setIsCreated(true);
      }
    } catch (error) {
      console.log('error: ', error.response.data);
      setIsError({
        value: true,
        message: `${error.response.data}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFileDetailButtonClick = () => {
    fileInputDetailsRef.current.click();
  };

  const handleFileDetailChange = (e) => {
    const name = 'avatar';
    setTemoignage({
      ...temoignage,
      [name]: e.target.files[0],
    });
    setImageUrlDetail(URL.createObjectURL(e.target.files[0]));
  };

  const handleChangeTextEditor = (value) => {
    const name = 'temoignage';
    setTemoignage({
      ...temoignage,
      [name]: value,
    });
  };
  const buttonStyles = {
    width: "100%",
    border: "2px solid rgba(8, 76, 148, 0.2)",
    height: "58px",
    borderRadius: "26px",
    padding: "0 18px",
  };
  return (
    <>
      {isCreated && showSuccessAlert()}
      {isError.value && showErrorAlert()}
      <Modal show={showUpdateTemoignage} onHide={handleCloseUpdateTemoignage} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Modifier le Temoignage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="border d-flex align-items-center justify-content-center">
              <RingLoader color="#fdc800" size="140" loading={loading} />
            </div>
          ) : (
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Label>Nom</Form.Label>
                    <div className={temoignage.correctNom ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                      <input type="text" name="nom" onChange={handleChange} className="form-control" value={temoignage && temoignage.nom}/>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Label>Fonction de l'auteur</Form.Label>
                    <div className={temoignage.correctTemoignage ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                      <input type="text" name="fonction" onChange={handleChange} className="form-control" value={temoignage && temoignage.fonction} />
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Label>Temoignages</Form.Label>
                    <div className={temoignage.correctFonction ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                      <div className={"single-input-inner style-bg-border"}>
                        <ReactQuill modules={modules} theme="snow" onChange={handleChangeTextEditor} placeholder="Temoignage..." defaultValue={temoignage && temoignage.temoignage}/>
                      </div>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Label>Image de l'auteur</Form.Label>
                    <div className={temoignage.correctAuteur ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                      <Button className="btn btn-base btn-radius" onClick={handleFileDetailButtonClick} style={buttonStyles}>Choisir avatar 80X80</Button>
                      <br /><br />
                      <input type="file" name="avatar" ref={fileInputDetailsRef} style={{ display: "none" }} onChange={handleFileDetailChange} />
                      {imageUrlDetail !== null ? <img src={imageUrlDetail} alt="img" /> : temoignage.avatar  && <img src={apiUrl+`${temoignage.avatar}`} alt="img" /> }

                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-6 text-left">
                    <Button className="single-input-inner btn btn-secondary btn-radius" size="lg" style={buttonStyles} onClick={handleCloseUpdateTemoignage}>
                      Fermer
                    </Button>
                  </div>
                  <div className="col-md-6 text-right">
                    <Button className="btn btn-base btn-radius" size="lg" style={buttonStyles} onClick={handleUpdateTemoignage}>
                      Modifier
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateTemoignageModal;
