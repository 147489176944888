import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { CategorieContext } from '../../context/CategorieContext';

class AboutV2 extends Component {
	static contextType = CategorieContext;
    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
		const centerTextStyle = {
			textAlign: 'center',
			fontSize: '34px',
		  };

           let SectionClass = this.props.sectionclass ? this.props.sectionclass : ''

    return <div className={"about-area pd-top-40 go-top "+SectionClass}>
			  <div className="container">
			    <div className="about-area-inner">
			      <div className="row">
			        <div className="col-lg-6 col-md-10">
			          <div className="about-thumb-wrap after-shape" style={{backgroundImage: 'url("'+publicUrl+'assets/img/about/19.png")'}}>
			          </div>
			        </div>
			        <div className="col-lg-6">
			          <div className="about-inner-wrap">  
			            <div className="section-title mb-0">
			              <h6 className="sub-title right-line">Qui sommes nous ?</h6>
			              <h2 className="title">BAKHBADÉ Academy.</h2>
			              <p className="content">BakhBaDé Academy est une structure de formation visant à promouvoir la formation et l'accompagnement des jeunes, adultes, associations,... 
                                                 Elle promeut l'utilisation du numérique dans l'éducation, la formation et l'entrepreneuriat à travers l'approche par les compétences 
                                                 Notre Mission est de participer à la formation et à l'accompagnement des talens et leaders de demain.</p>

						  <br />
						  <h6 className="title" style={centerTextStyle}>Nos formations</h6>

						  <div className="row">
			                <div className="col-sm-6">
			                  <ul className="single-list-wrap">
							  <li className="single-list-inner style-check-box" >
											<i className="fa fa-check" /> 100% pratiques
							  </li>
							  <li className="single-list-inner style-check-box" >
											<i className="fa fa-check" /> Faible coût
							  </li>
							  <li className="single-list-inner style-check-box" >
											<i className="fa fa-check" /> Paiement par tranche
							  </li>
			                  </ul>
			                </div>
			                <div className="col-sm-6">
			                  <ul className="single-list-wrap">
							  <li className="single-list-inner style-check-box" >
											<i className="fa fa-check" /> Formateurs experts
							  </li>
							  <li className="single-list-inner style-check-box" >
											<i className="fa fa-check" /> effectif reduit
							  </li>
							  <li className="single-list-inner style-check-box" >
											<i className="fa fa-check" /> Des heures flexibles
							  </li>
			                  </ul>
			                </div>
			              </div>
						  {this.props.canSee && <Link className="btn btn-border-black " to="/about">Voir plus</Link>} 
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default AboutV2