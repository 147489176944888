import React, { useState, useRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { RingLoader } from "react-spinners";
import Swal from "sweetalert2";
import "react-quill/dist/quill.snow.css";
import { addTypeFormationService, typeFormationData, updateTypeFormationService } from "../../../../services/typeformation/service";
import { useCategorieContext } from "../../../../context/CategorieContext";

const UpdateTypeFormation = (props) => {
  const {
    updateTypeFormationModal,
    handleCloseUpdateTypeFormation,
    typeFormationSelect,
    setTypeFormationSelect,
    setRecord
  } = props;

  const { typeFormation,setTypeFormations, } = useCategorieContext();
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  
  const [isCreated, setIsCreated] = useState(false);
  const [isError, setIsError] = useState({ value: false, message: "" });

  const handleChange = (e) => {
    // Mettre à jour les valeurs du formulaire
    setTypeFormationSelect({
      ...typeFormationSelect,
      [e.target.name]: e.target.value,
    });
  };

  
  

  

  const buttonStyles = {
    width: "100%",
    border: "2px solid rgba(8, 76, 148, 0.2)",
    height: "58px",
    borderRadius: "26px",
    padding: "0 18px",
  };

  const showSuccessAlert = () => {
    handleCloseUpdateTypeFormation();
    Swal.fire({
      icon: "success",
      title: "modification du type de formation a été faite avec succès !",
      showConfirmButton: false,
      timer: 5000,
    });
    setIsCreated(false);
  };

  const showErrorAlert = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: isError.message,
      showConfirmButton: false,
      timer: 5000,
    });
    setIsError({ ...isError, value: false });
  };
  const handleUpdateTypeFormation = async () => {
    try {
      setLoading(true);
      const response = await updateTypeFormationService(typeFormationSelect)
      const typeFormationResponse = await typeFormationData();
      setRecord(typeFormationResponse);
      if (response) {
        setIsCreated(true);
      }
    } catch (error) {
        console.log('error: ',error.response.data);
      setIsError({
        value: true,
        message: `${error.response.data}`,
      });
    } finally {
      setLoading(false);
    }
  };

 
  return (
    <>
    { isCreated &&  showSuccessAlert()}
       {isError.value && showErrorAlert()}
      <Modal show={updateTypeFormationModal} onHide={handleCloseUpdateTypeFormation} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Modifier un nouveau type de formation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="border d-flex align-items-center justify-content-center">
              <RingLoader color="#fdc800" size="140" loading={loading} />
            </div>
          ) : (
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Label>Nom</Form.Label>
                    <div className="single-input-inner-custome">
                      <input type="text" name="nom" onChange={handleChange} className="form-control" value={typeFormationSelect && typeFormationSelect.nom}/>
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-6 text-left">
                    <Button className="single-input-inner btn btn-secondary btn-radius" size="lg" style={buttonStyles} onClick={handleCloseUpdateTypeFormation}>
                      Fermer
                    </Button>
                  </div>
                  <div className="col-md-6 text-right">
                    <Button className="btn btn-base btn-radius" size="lg" style={buttonStyles} onClick={handleUpdateTypeFormation}>
                      Modifer
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateTypeFormation;
