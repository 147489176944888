import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { CategorieContext } from '../../context/CategorieContext';

class Testimonial extends Component {
	static contextType = CategorieContext;
    render() {
        // Définir les données des témoignages
		let publicUrl = process.env.PUBLIC_URL+'/'
        const testDemo = this.props.citaionData
        const testimonialData = [
            {
                quote: "La seule source de connaissance est l'expérience.",
                author: "Albert Einstein",
                position: "physicien théoricien",
                image: "https://cdn.futura-sciences.com/sources/images/scientist/persov6/Einstein-1000.jpg"
            },
            {
                quote: "La formation professionnelle est la voie vers l'excellence dans le domaine numérique.",
                author: "Sheryl Sandberg",
                position: "Directrice des opérations de Facebook",
                image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQr0e_D2v-c3uETatQ0Bb9_hhGpQfkD9u2CHMBh0NcERg&s"
            },
            {
                quote: "Formez-vous, armez-vous de sciences jusqu’aux dents (…) et arrachez votre patrimoine culturel.",
                author: "Cheikh Anta Diop",
                position: "anthropologue, historien, et physicien sénégalais",
                image: "https://prod.cdn-medias.jeuneafrique.com/cdn-cgi/image/q=auto,f=auto,metadata=none,width=1920,height=1080,fit=cover/https://prod.cdn-medias.jeuneafrique.com/medias/2023/12/22/jad20231222-cm-serie-episode-1-cheikhantadiop.jpg"
            },
            {
                quote: "Dans l'économie numérique d'aujourd'hui, l'investissement dans le développement des compétences est essentiel pour rester compétitif.",
                author: "Satya Nadella",
                position: "PDG de Microsoft",
                image: "https://media.lesechos.com/api/v1/images/view/5cc1de3c3e45462fff6d38a5/1280x720/0601139083517-web-tete.jpg"
            }
        ];

        return (
            <div className="testimonial-area pd-top-100">
                <div className="container">
                    <div className="testimonial-area-inner bg-cover" style={{backgroundImage: 'url("' + publicUrl + 'assets/img/other/2.png")'}}>
                        <img className="testimonial-right-img" src={publicUrl + "assets/img/other/11.png"} alt="img" />
                        <div className="testimonial-slider owl-carousel">
                            {/* Utilisation de la boucle map pour afficher les témoignages */}
                            {testimonialData && testimonialData.map((item, index) => (
                                <div className="item" key={index}>
                                    <div className="single-testimonial-inner style-white">
                                        <span className="testimonial-quote"><i className="fa fa-quote-left" /></span>
                                        <p className="mb-4">{item.quote}</p>
                                        <div className="media testimonial-author">
                                            <div className="media-left">
                                                <img src={item.image} alt="img" />
                                                <i className="fa fa-quote-left" />
                                            </div>
                                            <div className="media-body align-self-center">
                                                <h6>{item.author}</h6>
                                                <p>{item.positions}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Testimonial;
