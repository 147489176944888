import React, { useEffect, useState } from 'react'
import { useCategorieContext } from '../../context/CategorieContext';
import { deleteCiataionService, getCitationsService } from '../../services/citation/service';
import Swal from 'sweetalert2';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import AddCitationModal from './administration/modal/add-citation-modal';
import UpdateCitationModal from './administration/modal/update-citation-modal';
import { deleteTemoignageService, getTemoignagesService } from '../../services/temoignage/service';
import AddTemoignageModal from './administration/modal/add-temoignage-modal';
import UpdateTemoignageModal from './administration/modal/update-temoignage-modal';

const TemoignageData = () =>{
    const { temoignageData,setTemoignageData } = useCategorieContext();
    const [record, setRecord] = useState    ([]);
    const [showUpdateTemoignage,setShowUpdateTemoignage] = useState(false)
    const [showAddTemoignage,setShowAddTemoignage] = useState(false)

    const [selectedFormation,setSelectedFormation] = useState(undefined)

    const handleCloseUpdateTemoignage = () =>{
        setShowUpdateTemoignage(false)
    }

    const handleShowUpdateTemoignage = () =>{
        setShowUpdateTemoignage(true)
    }

    const handleCloseAddTemoignage = () => setShowAddTemoignage(false)
    const handleShowAddTemoignage = () => setShowAddTemoignage(true)


    const [temoignage, setTemoignage] = useState({
        id: "" ,
        nom:  "",
        temoignage: "",
        fonction: "",
        avatar: "",
      });
      
    useEffect(() => {
        setRecord(temoignageData);
    }, [temoignageData]);

    const handleUpdateFormation = async () => {
        // Logique pour mettre à jour les données de formation
        // Suppose que les nouvelles données sont stockées dans newFormationData
        // Passer les nouvelles données à la fonction de mise à jour du contexte
        const formationData = await getCitationsService();
        setTemoignageData(formationData);
      };

     

    const handleDeleteConfirmation = (id) =>{
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
          });
        swalWithBootstrapButtons.fire({
            title: "Êtes-vous sûr(e) ?",
            text: "Vous ne pourrez pas revenir en arrière !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui, supprimez !",
            cancelButtonText: "Non, annulez !",
            confirmButtonColor: "#fdc800",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                // Gérer la logique de suppression ici
                handleDeleteTemoignage(id);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire({
                    title: "Annulé",
                    text: "Votre fichier annulation a ete fait !)",
                    icon: "error"
                });
            }
        });
      
    }

    const handleDeleteTemoignage = async (id) => {
        try {
            // Effectuer la logique de suppression ici, comme appeler un service deleteFormation
            console.log(`Suppression de la formation avec l'ID ${id}`);
            // Une fois la suppression réussie, mettez à jour les données
            const updatedFormationData = await deleteTemoignageService(id);
            const temoignageResponse = await getTemoignagesService() 
            setRecord(temoignageResponse)
           
            // Facultatif : afficher un message de réussite
            Swal.fire({
                icon: "success",
                title: "Formation supprimée avec succès !",
                showConfirmButton: false,
                timer: 2000
            });
        } catch (error) {
            console.error("Erreur lors de la suppression  du temoignage :", error);
            // Afficher un message d'erreur si la suppression échoue
            Swal.fire({
                icon: "error",
                title: "Oups...",
                text: "Une erreur s'est produite lors de la suppression du temoignage.",
                showConfirmButton: false,
                timer: 3000
            });
        }
    };

    const handleUpdate = (temoignageValue) =>{
        
        setTemoignage({
            id:  temoignageValue.id ,
            nom: temoignageValue.nom,
            temoignage: temoignageValue.temoignage,
            fonction: temoignageValue.fonction,
            avatar: temoignageValue.avatar,
            correctNom: true,
            correctTemoignage: true,
            correctFonction: true,
          })
        handleShowUpdateTemoignage()
    }

    
 

    const linkColor = {
        color: '#fdc800',
    }

    const columns = [
       
        {
            name: 'Nom',
            selector: row => row.nom,
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, nom) => {
                return { textAlign: "center" };   // removed partial line here
              },

        },
        {
            name: 'Temoignage',
            selector: row => row.temoignage,
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, temoignage) => {
                return { textAlign: "center" };   // removed partial line here
              },
        },
        {
            name: 'Fonction',
            selector: row => row.fonction,
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, fonction) => {
                return { textAlign: "center" };   // removed partial line here
              },
        },
        {
            name: 'Actions', // Title of the column
            cell: row => (
                <>
                <i className="fa fa-edit" title='modifier' style={colorEdit} onClick={() => handleUpdate(row)}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <i className="fa fa-trash" title='supprimer' style={colorDrop} onClick={() => handleDeleteConfirmation(row.id)}/>
                </>
            ),
            allowOverflow: true,
            button: true
        },
    ];

    const handleFilter = (e) => {
        const newData = temoignageData.filter(row => {
            return row.nom.toLowerCase().includes(e.target.value.toLowerCase());
        });
        setRecord(newData);
    };


    const searchStyles = {
        border: '2px solid',
        height: '45px',
        borderRadius: '26px',
      };
      const stylePadding_top = {
        paddingTop: '37px',
      }
      const [show,setShow] = useState(false)
      const colorDrop = {
        color: 'red',
      }

      const colorEdit = {
        color: '#fdc800',
      }

    return (
        <div className="blog-area pd-bottom-120 go-top">
            <div className="container">
                <div className="row" style={stylePadding_top}>
                <div className="col-md-6">
                                <div className="single-input-inner">
                                <Button className="btn btn-secondary btn-radius"  size="lg" onClick={handleShowAddTemoignage}>
                                    ajouter
                                </Button>
                                 <AddTemoignageModal  showAddTemoignage={showAddTemoignage}
                                                      handleCloseAddTemoignage={handleCloseAddTemoignage}
                                                      setRecord={setRecord}
                                                      />
                               <UpdateTemoignageModal showUpdateTemoignage={showUpdateTemoignage}
                                                     handleCloseUpdateTemoignage={handleCloseUpdateTemoignage}
                                                     temoignage={temoignage}
                                                     setTemoignage={setTemoignage}
                                                     setRecord={setRecord}
                                                     />
                               
                                </div>
                            </div>
                            <div className="col-md-6 d-grid gap-2 d-flex justify-content-end">

                            <input type='text'   placeholder='     rechercher' onChange={handleFilter} style={searchStyles}/>

                            
                            </div>
                    <div className="col-lg-12">
                        <div className="single-blog-inner style-border">
                           
                            <div style={{ overflowX: 'auto' }}>
                            <DataTable
                                columns={columns} 
                                data={record}
                                pagination
                                
                            />
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default TemoignageData