/* eslint-disable react/jsx-pascal-case */
import React, { useState, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { useCategorieContext } from "../../context/CategorieContext";
import { addFormation, formations, getFormationByTypeformation } from "../../services/formation/service";
import { RingLoader } from "react-spinners";
import Swal from 'sweetalert2';
import  ReactQuill  from  "react-quill";
import  "react-quill/dist/quill.snow.css";


const  modules  = {
  toolbar:[
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ],
};

const FormationAddModal = (props) => {
  const { handleClose,handleUpdateFormation ,setRecord} = props;
  const { typeFormation,formationDatas,setFormationDatas } = useCategorieContext();

  const [selectedOption, setSelectedOption] = useState(0);
  const [canSend, setCanSend] = useState(false);
  const [loading, setLoading] = useState(false);

  const [imageUrl,setImageUrl] = useState(null)
  const [imageUrlDetail,setImageUrlDetail] = useState(null)

  const [isCreated, setIsCreated] = useState(false);
  const [isError, setIsError] = useState({value:false,message:""});

  const [formation, setFormation] = useState({
    nom: "",
    description: "",
    duration: "",
    mode_paiement: "",
    general_objectif: "",
    specifique_objectif: "",
    image_url: "",
    ordre: "",
    image_url_detail: "",
    type_formation_id: "",
    cout_min: "",
    prerequis: "",
    contenue: "",
    correctNom: true,
    correctDuration: true,
    correctCoutMin: true,
    correctImageUrl: true,
    correctImageUrlDetail: true,
    correctTypeFormationId: true,
  });

  // États pour stocker les noms des images sélectionnées
  const [imageName, setImageName] = useState("");
  const [imageDetailName, setImageDetailName] = useState("");

  const [value, setValue] =  useState("");
    console.log(value);

  const showSuccessAlert = () => {
    Swal.fire({
      icon: "success",
      title: "La formation a été créée avec succès !",
      showConfirmButton: false,
      timer: 5000
    });
    setIsCreated(false)
    handleClose()
    
  };

  const showErrorAlert = () =>{
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: isError.message,
      showConfirmButton: false,
      timer: 5000
    });
    setIsError({...isError,value:false})
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormation({
      ...formation,
      [name]: value,
    });

    if (name === "nom") {
      setFormation((prevState) => ({
        ...prevState,
        correctNom: value.length >= 2,
      }));
    }

    if (name === "duration") {
      setFormation((prevState) => ({
        ...prevState,
        correctDuration: value.length >= 2,
      }));
    }

    if (name === "cout_min") {
      setFormation((prevState) => ({
        ...prevState,
        correctCoutMin: value.length >= 2,
      }));
    }
  };

  const handleSubmit = async () => {
    if (formation.nom.length < 1 || formation.duration.length < 1 || formation.cout_min.length < 1 || !formation.cout_min || !formation.image_url || !formation.image_url_detail) {
      setCanSend(false);
    } else {

      setCanSend(true);
      setLoading(true)
     
      try {

        const response = await addFormation(formation)
        const formationData = await formations();
        const typeFormationResponse = await getFormationByTypeformation(props.id);
        setRecord(typeFormationResponse);

        setFormationDatas(formationData);

        if (response) {
          setIsCreated(true)
        }
        
      } catch (error) {
        console.error('Erreur lors de l\'envoi des données de formation :', error);
        setIsError({value: true,message: `Erreur lors de l'envoi des données de formation : ${error}`})

      }finally{
        setLoading(false)
        
        //setIsCreated(true)
      }
    }
  };

  const buttonStyles = {
    width: "100%",
    border: "2px solid rgba(8, 76, 148, 0.2)",
    height: "58px",
    borderRadius: "26px",
    padding: "0 18px",
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    setFormation({
      ...formation,
      type_formation_id: event.target.value})
  };

  const fileInputRef = useRef(null);
  const fileInputDetailsRef = useRef(null);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileDetailButtonClick = () => {
    fileInputDetailsRef.current.click();
  };

  const handleFileChange = (e) => {
    setImageName(e.target.files[0].name);
    setFormation({
      ...formation,
      image_url: e.target.files[0] // Mettre à jour l'état avec le fichier sélectionné
    });
    setImageUrl(URL.createObjectURL(e.target.files[0]))
  };
  
  const handleFileDetailChange = (e) => {
    setImageDetailName(e.target.files[0].name);
    setFormation({
      ...formation,
      image_url_detail: e.target.files[0] // Mettre à jour l'état avec le fichier sélectionné
    });
    setImageUrlDetail(URL.createObjectURL(e.target.files[0]))

  };

  const handleChangeTextEditor =(value) =>{
    setValue(value)
    const name = 'description'
    setFormation({
      ...formation,
      [name]: value,
    });
  }

  const handleChangeTextEditorObjectifGeneral =(value) =>{
    const name = 'general_objectif'
    setFormation({
      ...formation,
      [name]: value,
    });
  }

  const handleChangeTextEditorObjectifSpefifique =(value) =>{
    const name = 'specifique_objectif'
    setFormation({
      ...formation,
      [name]: value,
    });
  }

  const handleChangeTextEditorPrerequis =(value) =>{
    const name = 'prerequis'
    setFormation({
      ...formation,
      [name]: value,
    });
  }

  const handleChangeTextEditorContenue =(value) =>{
    const name = 'contenue'
    setFormation({
      ...formation,
      [name]: value,
    });
  }

  return (
    <>
    {loading ?  
    (
      <div className="border d-flex align-items-center justify-content-center">
        <RingLoader color="#fdc800" size="140" loading={loading} />
      </div>
    ) : isCreated ? (
      showSuccessAlert()
    ) : (
      <>
        {isError.value && showErrorAlert()}
        <Form>
      <div className="row">
        <div className="col-md-12">
          <div className={formation.correctNom ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
            <input type="text" name="nom" placeholder="Nom formation" onChange={handleChange} />
          </div>
        </div>

        <div className="col-md-6">
          <div className={formation.correctNom ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
            <input type="number" name="ordre" placeholder="Ordre affichage" onChange={handleChange} min={0}/>
          </div>
        </div>

        <div className="col-md-6">
          <div className={formation.correctDuration ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
            <input type="text" name="duration" placeholder="Durée de la formation" onChange={handleChange} />
          </div>
        </div>
        <div className="col-md-6">
          <div className={formation.correctNom ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
            <input type="text" name="mode_paiement" placeholder="Mode de paiement formation" onChange={handleChange} />
          </div>
        </div>
        <div className="col-md-6">
          <div className={formation.correctCoutMin ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
            <input type="number" name="cout_min" placeholder="cout minimal de la formation" onChange={handleChange} />
          </div>
        </div>
        <div className="col-md-12">
          <ReactQuill className={"single-input-inner-custome"} modules={modules} theme="snow" onChange={handleChangeTextEditor} placeholder="une description de la formation..." />
        </div>
         
        {/* <div className="col-md-12">
          <ReactQuill className='single-input-inner style-bg-border'  modules={modules} theme="snow" onChange={handleChangeTextEditorObjectifGeneral} placeholder="une general_objectif de la formation..." />

        </div>

        {/* Textarea pour l'objectif spécifique 
        <div className="col-md-12">
            <ReactQuill className='single-input-inner style-bg-border'  modules={modules} theme="snow" onChange={handleChangeTextEditorObjectifSpefifique} placeholder="une specifique_objectif de la formation..." />
        </div> */}

        
        {/* Textarea pour prerequis */}
        <div className="col-md-12">
            <ReactQuill className='single-input-inner style-bg-border'  modules={modules} theme="snow" onChange={handleChangeTextEditorPrerequis} placeholder="Prerequis de la formation..." />
        </div>

        
        {/* Textarea pour contenue */}
        <div className="col-md-12">
            <ReactQuill className='single-input-inner style-bg-border'  modules={modules} theme="snow" onChange={handleChangeTextEditorContenue} placeholder="Contenue de la formation..."/>
        </div>

        

        <div className="col-md-6">
          <div className={"single-input-inner-custome"}>
            <Button className="btn btn-base btn-radius" onClick={handleFileButtonClick} style={buttonStyles}>Choisir image 370X200</Button>
            <input type="file" name="image_url" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
            {imageName && <img src={imageUrl} alt="img" />}
          </div>
        </div>
        <div className="col-md-6">
          <div className={"single-input-inner-custome"}>
            <Button className="btn btn-base btn-radius" onClick={handleFileDetailButtonClick} style={buttonStyles}>Choisir image 870X520</Button>
            <input type="file" name="image_url_detail" ref={fileInputDetailsRef} style={{ display: "none" }} onChange={handleFileDetailChange} />
            {imageDetailName && <img src={imageUrlDetail} alt="img" />}
          </div>
        </div>
        <div className="col-md-12">
          <div className={"single-input-inner-custome"}>
            <Form.Select aria-label="Default select example" 
                         onChange={handleSelectChange} 
                         value={selectedOption} 
                         name="type"
                         >
              <option value={0}>Choisir la Catégorie de formation</option>
              {typeFormation &&
                typeFormation.map((typeFormation, index) => (
                  <option value={typeFormation.id} key={`typetab${index}`}>
                    {typeFormation.nom}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="single-input-inner">
            <Button className="btn btn-secondary btn-radius" onClick={handleClose} size="lg" style={buttonStyles}>
              Fermer
            </Button>
          </div>
        </div>
        <div className="col-md-6 d-grid gap-2">
          <Button className="btn btn-base btn-radius" onClick={handleSubmit} size="lg" style={buttonStyles} >
            Sauvegarder
          </Button>
        </div>
      </div>
        </Form>
      </>
    
    )
    }
    
    </>
  );
};

export default FormationAddModal;
