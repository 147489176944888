import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useAdminContext } from '../../../context/AdminContext';
import FormationAddModal from '../formation-add-flow';
import { deleteSubscribUserSession, getInscriptionDataBySession, updateStatusSubscribe } from '../../../services/inscription/service';
import AddUserSessionModal from './modal/add-user-session';
import {formationSession } from '../../../services/formation/service';
import { useParams } from 'react-router-dom';
import UpdateInscription from './modal/update-inscription';
import Swal from 'sweetalert2';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const InscritData = (props) => {
    const { id } = useParams();
    const { inscriptionData,currentUser } = useAdminContext();
    const [record, setRecord] = useState([]);
    const [formation,setFormation] = useState()
    const [adUserSession,setAdUserSession] = useState(false)
    const [updateUserSession,setUpdateUserSession] = useState(false)
    const [userSession,setUserSession] = useState()
    const [modalites,setModalites] = useState()
    const dt = useRef(null);

    const fetchInscriptionBySession = async () =>{

        try {
             const response = await getInscriptionDataBySession(id)
             setRecord(response.sessionFormationUsers)
             setModalites(response.modalites)
        } catch (error) {
            
        }
    }

    const fetchInscriptionByFormationBySession = async () =>{

        try {
             const responseformation = await formationSession(id)
             setFormation(responseformation)
             console.log('formation: ',responseformation);
        } catch (error) {
            
        }
    }


    useEffect(() => {
            fetchInscriptionBySession()
    }, [id]);

    useEffect(() => {
        
        fetchInscriptionByFormationBySession()
   
}, [id]);
const handleStatusConfirmation = (id) =>{
    Swal.fire({
        title: "Validation de l'inscription",
        text: "Êtes-vous sûr(e) de vouloir valider l'inscription ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Oui, Valider !",
        cancelButtonText: "Non, annulez !",
        confirmButtonColor: "#fdc800",
        reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
            handleStatusInscription(id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire({
                title: "Annulé",
                text: "Annulation faite avec succès",
                icon: "error"
            });
        }
    });
}

const handleStatusInscription = async (idInscription) => {
    try {
        console.log(`Suppression de la session avec l'idInscription ${idInscription}`);
        await updateStatusSubscribe(idInscription);
        const response = await getInscriptionDataBySession(id)
        setRecord(response.sessionFormationUsers)
        setModalites(response.modalites)
        Swal.fire({
            icon: "success",
            title: "inscription validée avec succès !",
            showConfirmButton: false,
            timer: 2000
        });
    } catch (error) {
        console.error("Erreur lors de la validation !:", error);
        Swal.fire({
            icon: "error",
            title: "Oups...",
            text: "Une erreur s'est produite lors de la validation!.",
            showConfirmButton: false,
            timer: 3000
        });
    }
};


const handleDeleteConfirmation = (id) => {
    Swal.fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimez !",
        cancelButtonText: "Non, annulez !",
        confirmButtonColor: "#fdc800",
        reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
            handleDeleteInscription(id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire({
                title: "Annulé",
                text: "La session est en sécurité :)",
                icon: "error"
            });
        }
    });
}

const handleDeleteInscription = async (idInscription) => {
    try {
        console.log(`Suppression de la session avec l'idInscription ${idInscription}`);
        await deleteSubscribUserSession(idInscription);
        if (id) {
            setRecord(record.filter(item => item.id !== idInscription));
        }
        Swal.fire({
            icon: "success",
            title: "inscription supprimée avec succès !",
            showConfirmButton: false,
            timer: 2000
        });
    } catch (error) {
        console.error("Erreur lors de la suppression !:", error);
        Swal.fire({
            icon: "error",
            title: "Oups...",
            text: "Une erreur s'est produite lors de la suppression!.",
            showConfirmButton: false,
            timer: 3000
        });
    }
};

    //update the user information
    const handleUpdateInscription = (userSelected) =>{
    
        setUserSession(userSelected)
        console.log(userSession);
        handleShowUpdateUserSession()
    }
    const colorEdit = {
        color: '#fdc800',
      }
      const stylePay = {
        background: '#32CD32'
    }
    const columns = [
        {
            header: 'Nom',
            body: row => row.user.nom,
            sortable: true,
            headerStyle: { textAlign: "center" },
            style: { maxWidth: "270px" },
        },
        {
            header: 'Prénom',
            body: row => row.user.prenom,
            sortable: true,
            headerStyle: { textAlign: "center" },
            style: { maxWidth: "270px" },
        },
        {
            header: 'Numéro de téléphone',
            body: row => row.user.numero_telephone,
            sortable: true,
            headerStyle: { textAlign: "center" },
            style: { maxWidth: "270px" },
        },
        {
            header: 'Email',
            body: row => row.user.email,
            sortable: true,
            headerStyle: { textAlign: "center" },
            style: { maxWidth: "270px" },
        },
        {
            header: 'Formation',
            body: row => row.session.formation.nom,
            sortable: true,
            headerStyle: { textAlign: "center" },
            style: { maxWidth: "270px" },
        },
        {
            header: 'Lieu de Formation',
            body: row => row.session.lieu_formation.region,
            sortable: true,
            headerStyle: { textAlign: "center" },
            style: { maxWidth: "270px" },
        },
        {
            header: 'Début de Session',
            body: row => row.session.date_debut,
            sortable: true,
            headerStyle: { textAlign: "center" },
            style: { maxWidth: "270px" },
        },
        {
            header: 'Horaire',
            body: row => row.modalite.modalite,
            sortable: true,
            headerStyle: { textAlign: "center" },
            style: { maxWidth: "270px" },
        },
        {
            header: 'Statut',
            body: row => (row.status === 1) ? <span style={stylePay}>Complété</span> : 'Incomplet',
            sortable: true,
            headerStyle: { textAlign: "center" },
            style: { maxWidth: "270px" },
        },
        {
            header: 'Statut Inscription',
            body: row => (row.status === 1) ? <span style={stylePay}>Complété</span> : (row.status_inscription === 1) ? <span style={stylePay}>Complété</span> : 'Pas encore',
            sortable: true,
            headerStyle: { textAlign: "center" },
            style: { maxWidth: "270px" },
        },
        {
            header: 'Actions',
            body: row => (
                <>
                    <i className="fa fa-edit" title='Modifier' style={colorEdit} onClick={() => handleUpdateInscription(row)} />
                    
                    <i className="fa fa-money" title='Payer' style={colorEdit} onClick={() => handleStatusConfirmation(row.id)} />
                    
                    <i className="fa fa-trash" title='Supprimer' style={colorDrop} onClick={() => handleDeleteConfirmation(row.id)} />
                </>
            ),
            headerStyle: { textAlign: "center" },
            style: { maxWidth: "270px" },
        },
    ];

    const handleFilter = (e) => {
        const newData = inscriptionData.filter(row => {
            return row.user.nom.toLowerCase().includes(e.target.value.toLowerCase());
        });
        setRecord(newData);
    };


    const exportCSV = () => {
        const csvData = record.map(row => ({
            Nom: row.user.nom,
            Prénom: row.user.prenom,
            Numéro_téléphone: row.user.numero_telephone,
            Email: row.user.email,
            Formation: row.session.formation.nom,
            'Lieu de Formation': row.session.lieu_formation.region,
            'Début de Session': row.session.date_debut,
            Horaire: row.modalite.modalite,
            Statut: row.status === 1 ? 'Complété' : 'Incomplet',
            'Statut Inscription': row.status_inscription === 1 ? 'Complété' : 'Pas encore'
        }));
        
        const csvHeaders = ['Nom', 'Prénom', 'Numéro de téléphone', 'Email', 'Formation', 'Lieu de Formation', 'Début de Session', 'Horaire', 'Statut', 'Statut Inscription'];
        const csvRows = [csvHeaders.join(','), ...csvData.map(item => csvHeaders.map(header => item[header]).join(','))];
        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'type_formation.csv');
    };

    // Export data to PDF
    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.text('Liste des inscriptions', 14, 22);
        doc.autoTable({
            startY: 30,
            head: [['Nom', 'Prénom', 'Numéro de téléphone', 'Email', 'Formation', 'Lieu de Formation', 'Début de Session', 'Horaire', 'Statut', 'Statut Inscription']],
            body: record.map(row => [
                row.user.nom,
                row.user.prenom,
                row.user.numero_telephone,
                row.user.email,
                row.session.formation.nom,
                row.session.lieu_formation.region,
                row.session.date_debut,
                row.modalite.modalite,
                row.status === 1 ? 'Complété' : 'Incomplet',
                row.status_inscription === 1 ? 'Complété' : 'Pas encore'
            ]),
            theme: 'striped',
        });
        doc.save('inscriptions.pdf');
    };

    // Export data to Excel
    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(record.map(row => ({
            Nom: row.user.nom,
            Prénom: row.user.prenom,
            Numéro_téléphone: row.user.numero_telephone,
            Email: row.user.email,
            Formation: row.session.formation.nom,
            'Lieu de Formation': row.session.lieu_formation.region,
            'Début de Session': row.session.date_debut,
            Horaire: row.modalite.modalite,
            Statut: row.status === 1 ? 'Complété' : 'Incomplet',
            'Statut Inscription': row.status_inscription === 1 ? 'Complété' : 'Pas encore'
        })));
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Inscriptions');
        XLSX.writeFile(wb, 'inscriptions.xlsx');
    };
    const header = (
        <div className="flex align-items-center justify-content-end gap-2 export-buttons">
            <Tooltip target=".export-buttons>button" position="bottom" />
            <Button
                type="button"
                icon="pi pi-file"
                rounded
                onClick={exportCSV}
                data-pr-tooltip="CSV"
            />
            <Button
                type="button"
                icon="pi pi-file-excel"
                severity="success"
                rounded
                onClick={exportToExcel}
                data-pr-tooltip="XLS"
            />
            <Button
                type="button"
                icon="pi pi-file-pdf"
                severity="warning"
                rounded
                onClick={exportToPDF}
                data-pr-tooltip="PDF"
            />
        </div>
    );

    const searchStyles = {
        border: '2px solid',
        height: '45px',
        borderRadius: '26px',
      };
      const stylePadding_top = {
        paddingTop: '37px',
      }
      const colorDrop = {
        color: 'red',
      }

      const handleCloseAddUserSession = () => {setAdUserSession(false)};
	  const handleShowAddUserSession = () => setAdUserSession(true);

      const handleCloseUpdateUserSession = () => {setUpdateUserSession(false)};
	  const handleShowUpdateUserSession = () => setUpdateUserSession(true);


    return (
       <>
       {id ? ( <div className="blog-area pd-bottom-120 go-top">
            <div className="container">
                <div className="row" style={stylePadding_top}>
                <div className="col-md-6">
                                <div className="single-input-inner">
                                <Button className="btn btn-secondary btn-radius"  size="lg" onClick={handleShowAddUserSession}>
                                    ajourer
                                </Button>
                                <AddUserSessionModal  adUserSession={adUserSession}
                                                      handleCloseAddUserSession={handleCloseAddUserSession}
                                                      formation={formation}
                                                      id={id}
                                                      modalites={modalites}
                                                      setRecord={setRecord}
                                                      setModalites={setModalites}
                                                     />
                                <UpdateInscription   updateUserSession={updateUserSession}
                                                     handleCloseUpdateUserSession={handleCloseUpdateUserSession}
                                                     userSession={userSession}
                                                     setUserSession={setUserSession}
                                                     formation={formation}
                                                     id={id}
                                                     />              
                               
                                </div>
                            </div>
                            <div className="col-md-6 d-grid gap-2 d-flex justify-content-end">

                            <input type='text'   placeholder='     rechercher' onChange={handleFilter} style={searchStyles}/>

                            
                            </div>
                    <div className="col-lg-12">
                        <div className="single-blog-inner style-border">
                           
                            <div style={{ overflowX: 'auto' }}>
                            <DataTable
                                    ref={dt}
                                    value={record}
                                    paginator
                                    header={header}
                                    rows={10}
                                    dataKey="id"
                                    responsiveLayout="scroll"
                                    showGridlines
                                >
                                    {columns.map((col, index) => (
                                        <Column
                                            key={index}
                                            field={col.field}
                                            header={col.header}
                                            body={col.body}
                                            sortable={col.sortable}
                                            headerStyle={col.headerStyle}
                                        />
                                    ))}
                                </DataTable>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>):(<div>is loading ...</div>)}
       </>
    );
}
export default InscritData