import React, { useState, useRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { RingLoader } from "react-spinners";
import Swal from "sweetalert2";
import "react-quill/dist/quill.snow.css";
import { addTypeFormationService } from "../../../../services/typeformation/service";
import { addCitationService, getCitationsService } from "../../../../services/citation/service";

const AddCitationModal = (props) => {
  const {
    showAddCitation,
    handleCloseAddCitation,
    setRecord
  } = props;

 
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const [citation, setCitation] = useState({
    auteur:  "",
    message: "",
    fonction: "",
    image: "",
    correctAuteur: true,
    correctMessage: true,
    correctFonction: true,
  });
  const [isCreated, setIsCreated] = useState(false);
  const [isError, setIsError] = useState({ value: false, message: "" });

  const handleChange = (e) => {
    // Mettre à jour les valeurs du formulaire
    setCitation({
      ...citation,
      [e.target.name]: e.target.value,
    });
  };

  
  

  

  const buttonStyles = {
    width: "100%",
    border: "2px solid rgba(8, 76, 148, 0.2)",
    height: "58px",
    borderRadius: "26px",
    padding: "0 18px",
  };

  const showSuccessAlert = () => {
    handleCloseAddCitation();
    Swal.fire({
      icon: "success",
      title: "une nouvelle citation a été faite avec succès !",
      showConfirmButton: false,
      timer: 5000,
    });
    setIsCreated(false);
  };

  const showErrorAlert = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: isError.message,
      showConfirmButton: false,
      timer: 5000,
    });
    setIsError({ ...isError, value: false });
  };
  const handleAddCitation = async () => {
    try {
      setLoading(true);
      const response = await addCitationService(citation)
      const citationResponse = await getCitationsService() 
      setRecord(citationResponse)
      if (response) {
        setIsCreated(true);
      }
    } catch (error) {
        console.log('error: ',error.response.data);
      setIsError({
        value: true,
        message: `${error.response.data}`,
      });
    } finally {
      setLoading(false);
    }
  };

 
  return (
    <>
    { isCreated &&  showSuccessAlert()}
       {isError.value && showErrorAlert()}
      <Modal show={showAddCitation} onHide={handleCloseAddCitation} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Ajouter une nouvelle citation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="border d-flex align-items-center justify-content-center">
              <RingLoader color="#fdc800" size="140" loading={loading} />
            </div>
          ) : (
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Label>Auteur</Form.Label>
                    <div className={citation.correctAuteur ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                      <input type="text" name="auteur" onChange={handleChange} className="form-control" />
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Label>Citation</Form.Label>
                    <div className={citation.correctMessage ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                      <input type="text" name="message" onChange={handleChange} className="form-control" />
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Label>Fonction de l'auteur</Form.Label>
                    <div className={citation.correctAuteur ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                      <input type="text" name="fonction" onChange={handleChange} className="form-control" />
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Label>Image de l'auteur</Form.Label>
                    <div className={citation.correctAuteur ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                      <input type="text" name="image" onChange={handleChange} className="form-control" />
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-6 text-left">
                    <Button className="single-input-inner btn btn-secondary btn-radius" size="lg" style={buttonStyles} onClick={handleCloseAddCitation}>
                      Fermer
                    </Button>
                  </div>
                  <div className="col-md-6 text-right">
                    <Button className="btn btn-base btn-radius" size="lg" style={buttonStyles} onClick={handleAddCitation}>
                      Ajouter
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCitationModal;
