
import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { Tag } from 'primereact/tag';
import { ProductService } from '../services/user/service';
import { Link } from 'react-router-dom';

export default function CircularDemo() {
    const [products, setProducts] = useState([]);
    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const getSeverity = (product) => {
        switch (product.inventoryStatus) {
            case 'INSTOCK':
                return 'success';

            case 'LOWSTOCK':
                return 'warning';

            case 'OUTOFSTOCK':
                return 'danger';

            default:
                return null;
        }
    };

    useEffect(() => {
        ProductService.getProductsSmall().then((data) => setProducts(data.slice(0, 9)));
    }, []);
    let publicUrl = process.env.PUBLIC_URL+'/'

    const productTemplate = (product) => {
        return (
            <a href={product.link}>
                <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
                                <div className="mb-3">
                                    <img src={publicUrl+product.image}  alt={product.name} className="w-6 shadow-2" />
                                </div>
                                <div>
                                    <h4 className="mb-1">{product.name}</h4>
                                    <a className="btn btn-base b-animate-3 mr-sm-3 mr-2" href={product.link}>Voir plus</a>
                                </div>
                </div>
            </a>
            
        );
    };

    return (
        <div className="card">
            <div className="section-title text-center">
		          <h3 className="sub-title">BakhBaDé Academy propose des ordinateurs à bas prix. </h3>
		        </div>
            <Carousel value={products} numVisible={3} numScroll={3} responsiveOptions={responsiveOptions} className="custom-carousel" circular
            autoplayInterval={3000} itemTemplate={productTemplate} />
        </div>
    )
}
        