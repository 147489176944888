import Navbar from './global-components/navbar-v4';
import Banner from './section-components/banner';
import About from './section-components/about-v2';
import CourseFilter from './section-components/course-filter';
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Contact from './section-components/contact';
import Footer from './global-components/footer';
import Event from './section-components/event-page';
import LatestPost from './blog-components/latest-news-v2';
import BlogPresentation from './blog-components/bloc-prentation';
import CircularDemo from './site-vente';
import { useCategorieContext } from '../context/CategorieContext';
import TestimonialV2 from './section-components/testimonial-v2';
import { FloatingWhatsApp } from 'react-floating-whatsapp';


const Home_V1 = () => {
    let publicUrl = process.env.PUBLIC_URL+'/'
    const { citaionData,setCitationData } = useCategorieContext();


    return (
        <div>
           <Navbar/>
            <Banner />
            <FloatingWhatsApp
						phoneNumber="+221767499096"
						accountName="Bakhbadé Academy"
						avatar={publicUrl+"assets/img/logowatshapp.png"}
						statusMessage="Formation & Accompagnement"
						chatMessage="Bonjour ! Comment pouvons-nous vous aider aujourd'hui ?"
            			placeholder="Écrivez un message..."
    />
            <CourseFilter />
            {/*      */}
            
            {/* <HowToWork /> */}
            <About  canSee={true}/>
            {/* <FunFact /> */}
            <LatestPost />
            <CircularDemo />

            <BlogPresentation />
            <Testimonial/>
            
            <TestimonialV2 />

            {/* <Team /> */}
            {/* <LatestPost /> */}
            <Contact />
            <Footer />
        </div>
    );
}

export default Home_V1;
