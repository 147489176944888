import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV3 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="about-area">
			  <div className="container">
			    <div className="about-area-inner">
			      <div className="row">
			        <div className="col-lg-6 col-md-10">
			          <div className="about-thumb-wrap style-two" style={{backgroundImage: 'url("'+publicUrl+'assets/img/about/14.png")'}}>
			          </div>
			        </div>
			        <div className="col-lg-6">
			          <div className="about-inner-wrap">  
			            <div className="section-title mb-0">
			              <h6 className="sub-title style-btn">Qui sommes nous ?</h6>
			              <p className="content">Chez BakhBaDé Academy, plongez dans le monde du numérique et des nouvelles technologies : nous concrétisons vos idées en projets et vos connaissances en compétences pratiques. Nos formations sont 100% pratiques, avec un nombre limité de places et la possibilité de payer en plusieurs fois. Transformez votre avenir avec nous ! 🚀</p>
			              <ul className="single-list-wrap">
						  <h6 className="sub-title style-btn">Avec BakhBaDé Academy</h6>
			                <li className="single-list-inner style-box-bg">
			                  <div className="media">
			                    <div className="media-left">
			                      <img src={publicUrl+"assets/img/icon/7.png"} alt="img" />
			                    </div>
			                    <div className="media-body align-self-center">
			                      <h5 className="mb-2">Participer à la formation des leaders de demain.</h5>
			                    </div>
			                  </div>
			                </li>
							<li className="single-list-inner style-box-bg">
			                  <div className="media">
			                    <div className="media-left">
			                      <img src={publicUrl+"assets/img/icon/22.png"} alt="img" />
			                    </div>
			                    <div className="media-body align-self-center">
			                      <h5 className="mb-2">Acompagner les entreprises dans leurs transformation digitale.</h5>
			                    </div>
			                  </div>
			                </li>
			                <li className="single-list-inner style-box-bg">
			                  <div className="media">
			                    <div className="media-left">
			                      <img src={publicUrl+"assets/img/icon/24.png"} alt="img" />
			                    </div>
			                    <div className="media-body align-self-center">
			                      <h5 className="mb-2">Incubateur pour les porteurs de projet.</h5>
			                    </div>
			                  </div>
			                </li>
							<li className="single-list-inner style-box-bg">
			                  <div className="media">
			                    <div className="media-left">
			                      <img src={publicUrl+"assets/img/icon/23.png"} alt="img" />
			                    </div>
			                    <div className="media-body align-self-center">
			                      <h5 className="mb-2">Promouvoir l'utilisation du numérique dans l'éducation,la formation et l'entrepreneuriat.</h5>
			                    </div>
			                  </div>
			                </li>
			              </ul>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default AboutV3