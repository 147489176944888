import React, { useEffect, useState } from 'react';
import Navbar from '../../global-components/navbar-v4';
import Footer from '../../global-components/footer';
import { useParams } from 'react-router-dom';

const OrangeQrcode = () => {
  const { qrcode } = useParams();


 
  return (
    <div>
      <Navbar />
     
       
          <div className="page-content container">
            <div className="row">
              <div className="col-sm-6 col-sm-offset-3">
                <div className="panel panel-default">
                  <div className="panel-body" id="containerid">
                    <h4>
                      Afin de finaliser le règlement des frais liés à votre
                      voyage, veuillez utiliser votre application Orange Money
                      pour scanner le code QR affiché ci-dessous.
                    </h4>
                    <div
                      className="d-flex"
                      style={{ justifyContent: 'center', display: 'flex' }}
                    >
                      <img src={`data:image/png;base64, ${qrcode}`} alt="QR Code" />
                    </div>
                    <a href="#" data-method="POST" className="btn btn-block btn-primary">
                      Rafraîchir pour vérifier l'état de paiement.
                      <i className="fa fa-save"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
      <Footer />
    </div>
  );
};

export default OrangeQrcode;
