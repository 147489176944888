import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Modal } from 'react-bootstrap';
import { useAdminContext } from '../../../context/AdminContext';
import FormationAddModal from '../formation-add-flow';
import { getInscriptionDataBySession } from '../../../services/inscription/service';
import AddEvenement from './modal/add-evenement';
import { useCategorieContext } from '../../../context/CategorieContext';
import UpdateEven from './modal/update-even';
import { Link } from 'react-router-dom';
import { getEvenById } from '../../../services/evenement/service';

const EvenParticipantData = (props) => {
    const {id} = props
    const {participant,setParticipant} = useState();
    const [record, setRecord] = useState([]);
    const [even,setEven] = useState({});
    const [showUpdateEven, setShowUpdateEven] = useState(false);

    const fetchInscriptionBySession = async () =>{

        try {
             const response = await getEvenById(id)
             setRecord(response.participants)
             setParticipant(response.participants)
        } catch (error) {
            
        }
    }

   

    useEffect(() => {
        fetchInscriptionBySession()
    }, [id]);

    const handleDeleteEven = (id) =>{

    }


    const columns = [
       
        {
            name: 'nom',
            selector: row => row.nom,
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, nom) => {
                return { textAlign: "center" };   // removed partial line here
              },

        },
        {
            name: 'prenom',
            selector: row => row.prenom,
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, prenom) => {
                return { textAlign: "center" };   // removed partial line here
              },
        },
        {
            name: 'email',
            selector: row => row.email,
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, email) => {
                return { textAlign: "center" };   // removed partial line here
              },
        },
        {
            name: 'telephone',
            selector: row => row.telephone,
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, telephone) => {
                return { textAlign: "center" };   // removed partial line here
              },
        },
        {
            name: 'Actions', // Title of the column
            cell: row => (
                <>
                    <i className="fa fa-trash" title='supprimer' style={colorDrop} onClick={() => handleDeleteEven(row.id)}/>
                </>
            ),
            allowOverflow: true,
            button: true
        },
    ];

    const handleFilter = (e) => {
        const newData = participant.filter(row => {
            return row.nom.toLowerCase().includes(e.target.value.toLowerCase());
        });
        setRecord(newData);
    };


    const searchStyles = {
        border: '2px solid',
        height: '45px',
        borderRadius: '26px',
      };
      const stylePadding_top = {
        paddingTop: '37px',
      }
      const [showAddEvenement,setShowAddEvenement] = useState(false)
      const colorDrop = {
        color: 'red',
      }

      const hancleCloseAddEvenement = () => {setShowAddEvenement(false)};
	  const handleShowAddEvenement = () => setShowAddEvenement(true);

      const handleShowUpdateEven = () => setShowUpdateEven(true);
      const handleCloseUpdateEven = () => setShowUpdateEven(false);


    return (
        <div className="blog-area pd-bottom-120 go-top">
            <div className="container">
                <div className="row" style={stylePadding_top}>
                <div className="col-md-6">
                                <div className="single-input-inner">
                                <Button className="btn btn-secondary btn-radius"  size="lg" onClick={handleShowAddEvenement}>
                                    ajourer
                                </Button>
                                <AddEvenement showAddEvenement={showAddEvenement}
                                              hancleCloseAddEvenement={hancleCloseAddEvenement}
                                />
                                <UpdateEven   showUpdateEven={showUpdateEven}
                                              handleCloseUpdateEven={handleCloseUpdateEven}
                                              even={even}
                                              setEven={setEven}
                                />
                               
                                </div>
                            </div>
                            <div className="col-md-6 d-grid gap-2 d-flex justify-content-end">

                            <input type='text'   placeholder='     rechercher' onChange={handleFilter} style={searchStyles}/>

                            
                            </div>
                    <div className="col-lg-12">
                        <div className="single-blog-inner style-border">
                           
                            <div style={{ overflowX: 'auto' }}>
                            <DataTable
                                columns={columns} 
                                data={record}
                                pagination
                               
                                
                            />
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default EvenParticipantData