import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CategorieContext } from '../../context/CategorieContext';
import { v4 as uuidv4 } from 'uuid';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Dropdown } from 'bootstrap';
import { DropdownItem, DropdownMenu, DropdownToggle } from 'react-bootstrap';

import '../../mystyle.css';

class NavbarV4 extends Component {
  static contextType = CategorieContext;

  constructor(props) {
    super(props);

    this.toggle1 = this.toggle1.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.state = {
      dropdownOpen1: false,
      isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
    };
  }

   handleLogout = () => {
    // Remove the 'isLoggedIn' item from localStorage
    localStorage.removeItem('isLoggedIn');
    this.setState({isLoggedIn: false})
    window.location.reload()
    // Redirect the user to the login page or perform any other action
    // For example, you can use React Router's history object to navigate to the login page
  };
  
  toggle1() {
    this.setState(prevState => ({
      dropdownOpen1: !prevState.dropdownOpen1
    }));
  }

  onMouseEnter() {
    this.setState({ dropdownOpen1: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen1: false });
  }
    componentDidMount() {

     const $ = window.$;

     $( 'body' ).removeClass( 'home-3' );

   }
   
    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'logo'
        let anchor = '#'
        return (
			<div className="navbar-area">
        {/* <div className="navbar-top">
          <div className="container">
            <div className="row">
              <div className="col-md-10 text-md-left text-center">
                <ul>
                  <li>
                    <p>
                      <i className="fa fa-map-marker"> Dakar, Médina Rue 17 angle 20. Immeuble R+6.</i> 
                      <i className="fa fa-map-marker"> Tivaouane près du Lycée Ababacar Sy  </i>
                      <i className="fa fa-phone-square">  +221 77 361 13 04  </i>
                      <i className="fa fa-envelope-o">  contact@bakhbade.com </i>
                  </p>
                  </li>
                
                </ul>
              </div>
              <div className="col-md-2">
                <ul className="topbar-right text-md-right text-center">
                  <li className="social-area">
                    <a href="https://www.facebook.com/profile.php?id=100092514552603"><i className="fa fa-facebook" aria-hidden="true" /></a>
                    <a href="https://www.linkedin.com/in/bakhbad%C3%A9-acad%C3%A9mie-baaa6228a/"><i className="fa fa-linkedin" aria-hidden="true" /></a>
                    <a href="https://www.instagram.com/bbdacademy"><i className="fa fa-instagram" aria-hidden="true" /></a>
                  </li>
                </ul>
              </div>
            </div> 
          </div>
        </div> */}
        <nav  className="navbar bg-white navbar-area-1 navbar-area navbar-expand-lg go-top" >
          <div className="container nav-container">
            <div className="responsive-mobile-menu">
              <button className="menu toggle-btn d-block d-lg-none" data-target="#edumint_main_menu" aria-expanded="false" aria-label="Toggle navigation">
                <span className="icon-left" />
                <span className="icon-right" />
              </button>
            </div>
            <div className="logo">
              <Link to="/"><img src={publicUrl+"assets/img/logo.png"} alt="img" /></Link>
            </div>
            <div className="nav-right-part nav-right-part-mobile">
              {this.state.isLoggedIn ?  <button onClick={this.handleLogout}>Se deconnecter</button> :<>
              <Link className="signin-btn" to="/sign-in">Connexion</Link>
              <Link className="btn btn-base" to="/sign-up">Creer  un compte</Link>
              </>}
             
            </div>
            <div className="collapse navbar-collapse" id="edumint_main_menu">
              <ul className="navbar-nav menu-open">
                <li className="current-menu-item">
                  <Link to="/">Accueil</Link>
                </li>
               
                <li className="menu-item-has-children">

                    <a href={`/course`}>Formations </a>
                      <ul className="sub-menu">
                      {

                           this.context.typeFormation.map(item => (

                            
                          
                            <li key={uuidv4()} className="dropdown">
                              <a href={`/course/${item.id}`} className="dropbtn">{item.nom}  <b>+</b>
                              <ul className="dropdown-content">
                                  {item.formations.map((formation,index) =>(
                                    <li key={`formation${index}`}>
                                      <a href={`/course-details/${formation.id}`}>{formation.nom}</a>
                                    </li>
                                  ))}
                                 </ul>
                             
                                 
                                 </a>
                            </li> 
                           
                            

                        ))
                        }
                      </ul>
                    </li>
                    <li><Link to="/event">Evenements</Link></li>
                    <li>
                    
                    </li>
                    <li><Link to="/about">Apropos</Link></li>
                    {this.state.isLoggedIn && <li><Link to="/dashboard-admin">Mon espace <i class="fa fa-user" aria-hidden="true"></i></Link></li>}
               
              </ul>
            </div>
            <div className="nav-right-part nav-right-part-desktop">
            {this.state.isLoggedIn ?  <button onClick={this.handleLogout} className="btn btn-base">Se deconnecter</button> :<>
              <Link className="signin-btn" to="/sign-in">Connexion</Link>
              <Link className="btn btn-base" to="/sign-up">Creer  un compte</Link>
              </>}
              <a className="search-bar" href="#"><i className="fa fa-search" /></a>
            </div>
          </div>
        </nav>
      </div>

        )
    }
}


export default NavbarV4