import React from 'react';
import NavbarAdmin from "../../global-components/Navbar-admin";
import PageHeader from '../../global-components/page-header';
import EvenParticipantData from './even-participant-data';
import { useParams } from 'react-router-dom';

const EvenParticipant = () =>{
    const { id } = useParams();

    return (
        <div>
        <NavbarAdmin />
        <PageHeader headertitle="Participants" />
        <EvenParticipantData id={id} />
      </div>
    )
}

export default EvenParticipant