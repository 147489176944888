import React from 'react'
import NavbarAdmin from '../../global-components/Navbar-admin'
import PageHeader from '../../global-components/page-header'
import TypeFormationData from './type-formation-data'

function TypeFormation () {
  return (
    <div>
        <NavbarAdmin />
        <PageHeader headertitle="TypeFormation" />
        <TypeFormationData />
    </div>
  )
}

export default TypeFormation