import React, { useState, useEffect, useRef } from 'react';
import { useCategorieContext } from '../../context/CategorieContext';
import { Modal } from 'react-bootstrap';
import FormationAddModal from './formation-add-flow';
import { DeleteFormation, formations, getFormationByTypeformation, hideFormationService, showFormationService } from '../../services/formation/service';
import { Link } from 'react-router-dom';
import UpdateFormation from './administration/modal/update-formation';
import Swal from 'sweetalert2';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default function FormationSameTypeData(props) {
    
    
    const [record, setRecord] = useState([]);
    const [showUpdateFormation,setShowUpdateFormation] = useState(false)
    const [selectedFormation,setSelectedFormation] = useState(undefined)
    const [formationDatas,setFormationDatas] = useState([])
    const dt = useRef(null);

    const handleCloseUpdateFormation = () =>{
        setShowUpdateFormation(false)
    }

    const handleShowUpdateFormation = () =>{
        setShowUpdateFormation(true)
    }
    const { id } = props;

    const [formation, setFormation] = useState({
        id: "" ,
        nom:  "",
        description: "",
        duration: "",
        general_objectif: "",
        specifique_objectif: "",
        image_url: "",
        image_url_detail:  "",
        type_formation_id: "",
        type_formation: "",
        cout_min: "",
        prerequis: "",
        contenue: "",
        ordre: "",
        correctNom: true,
        correctDuration: true,
        correctCoutMin: true,
        correctImageUrl: true,
        correctImageUrlDetail: true,
        correctTypeFormationId: true,
      });
     

      useEffect(() => {
        fetchData();
    }, [props.id]);

    const fetchData = async () => {
        try {
            const typeFormationResponse = await getFormationByTypeformation(props.id);
            setFormationDatas(typeFormationResponse);
            setRecord(typeFormationResponse);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleUpdateFormation = async () => {
        // Logique pour mettre à jour les données de formation
        // Suppose que les nouvelles données sont stockées dans newFormationData
        // Passer les nouvelles données à la fonction de mise à jour du contexte
        const formationData = await formations();
        setFormationDatas(formationData);
      };

     

    const handleDeleteConfirmation = (id) =>{
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
          });
        swalWithBootstrapButtons.fire({
            title: "Êtes-vous sûr(e) ?",
            text: "Vous ne pourrez pas revenir en arrière !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui, Cacher !",
            cancelButtonText: "Non, annulez !",
            confirmButtonColor: "#fdc800",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                // Gérer la logique de suppression ici
                handleDeleteFormation(id);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire({
                    title: "Annulé",
                    text: "Votre fichier imaginaire est en sécurité :)",
                    icon: "error"
                });
            }
        });
      
    }

    const handleDeleteFormation = async (id) => {
        try {
            // Effectuer la logique de suppression ici, comme appeler un service deleteFormation
            console.log(`Suppression de la formation avec l'ID ${id}`);
            // Une fois la suppression réussie, mettez à jour les données
            const updatedFormationData = await DeleteFormation(id);
            const formationData = await formations();
            setFormationDatas(formationData);
            // Facultatif : afficher un message de réussite
            Swal.fire({
                icon: "success",
                title: "Formation supprimée avec succès !",
                showConfirmButton: false,
                timer: 2000
            });
        } catch (error) {
            console.error("Erreur lors de la suppression de la formation :", error);
            // Afficher un message d'erreur si la suppression échoue
            Swal.fire({
                icon: "error",
                title: "Oups...",
                text: "Une erreur s'est produite lors de la suppression de la formation.",
                showConfirmButton: false,
                timer: 3000
            });
        }
    };

    const handleShowConfirmation = (id) =>{
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
          });
        swalWithBootstrapButtons.fire({
            title: "Êtes-vous sûr(e) ?",
            text: "Cette formation  sera  visible pour les autres utilisateurs !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui, Afficher !",
            cancelButtonText: "Non, annulez !",
            confirmButtonColor: "#fdc800",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                // Gérer la logique de suppression ici
                handleShowFormation(id);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire({
                    title: "Annulé",
                    text: "Votre fichier imaginaire est en sécurité :)",
                    icon: "error"
                });
            }
        });
      
    }

    const handleShowFormation = async (id) => {
        try {
            // Effectuer la logique de suppression ici, comme appeler un service deleteFormation
            console.log(`Suppression de la formation avec l'ID ${id}`);
            // Une fois la suppression réussie, mettez à jour les données
            const updatedFormationData = await showFormationService(id);
            fetchData()
            // Facultatif : afficher un message de réussite
            Swal.fire({
                icon: "success",
                title: "Formation affichée avec succès !",
                showConfirmButton: false,
                timer: 2000
            });
        } catch (error) {
            console.error("Erreur est survenue :", error);
            // Afficher un message d'erreur si la suppression échoue
            Swal.fire({
                icon: "error",
                title: "Oups...",
                text: "Une erreur s'est produite lors de la suppression de la formation.",
                showConfirmButton: false,
                timer: 3000
            });
        }
    };


    const handleHideConfirmation = (id) =>{
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
          });
        swalWithBootstrapButtons.fire({
            title: "Êtes-vous sûr(e) ?",
            text: "Cette formation ne sera plus visible pour les autres utilisateurs !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui, Caché !",
            cancelButtonText: "Non, annulez !",
            confirmButtonColor: "#fdc800",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                // Gérer la logique de suppression ici
                handleHideFormation(id);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire({
                    title: "Annulé",
                    text: "Votre fichier imaginaire est en sécurité :)",
                    icon: "error"
                });
            }
        });
      
    }

    const handleHideFormation = async (id) => {
        try {
            // Effectuer la logique de suppression ici, comme appeler un service deleteFormation
            // Une fois la suppression réussie, mettez à jour les données
            const updatedFormationData = await hideFormationService(id);
            fetchData()
            // Facultatif : afficher un message de réussite
            Swal.fire({
                icon: "success",
                title: "Formation cachée avec succès !",
                showConfirmButton: false,
                timer: 2000
            });
        } catch (error) {
            console.error("Erreur lors de la suppression de la formation :", error);
            // Afficher un message d'erreur si la suppression échoue
            Swal.fire({
                icon: "error",
                title: "Oups...",
                text: "Une erreur s'est produite lors de la suppression de la formation.",
                showConfirmButton: false,
                timer: 3000
            });
        }
    };

    const handleUpdate = (formationss) =>{
        
        setFormation({
            id:  formationss.id ,
            nom: formationss.nom,
            description: formationss.description,
            duration: formationss.duration,
            general_objectif: formationss.specifique_objectif,
            specifique_objectif: formationss.specifique_objectif,
            image_url: formationss.image_url,
            image_url_detail: formationss.image_url_detail,
            type_formation_id: formationss.type_formation_id,
            type_formation: formationss.type_formation,
            mode_paiement: formationss.mode_paiement,
            cout_min: formationss.cout_min,
            prerequis: formationss.prerequis,
            contenue: formationss.contenue,
            ordre: formationss.ordre,
            correctNom: true,
            correctDuration: true,
            correctCoutMin: true,
            correctImageUrl: true,
            correctImageUrlDetail: true,
            correctTypeFormationId: true,
          })
        handleShowUpdateFormation()
    }

    const truncateAndEscapeHTML = (text, maxLength) => {
        const truncateText = text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
        return truncateText.replace(/&/g, '&amp;')
                           .replace(/</g, '&lt;')
                           .replace(/>/g, '&gt;')
                           .replace(/"/g, '&quot;')
                           .replace(/'/g, '&#039;');
    };

    const truncateAndEscapeHTMLExport = (text, maxLength) => {
        const truncateText = text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
        return truncateText.replace(/&/g, ' ')
                           .replace(/</g, ' ')
                           .replace(/>/g, ' ')
                           .replace(/"/g, ' ')
                           .replace(/'/g, ' ');
    };
    
 

    const extractInformation = (data) => {
        return data.map(item => {
            return {
                Nom: item.nom,
                Description: <div dangerouslySetInnerHTML={{ __html: truncateAndEscapeHTML(item.description, 30) }} /> ,
                Cout_minimal: item.cout_min,
                Duree: item.duration,
                sessionFormation: item.sessionFormation.length,
                Ordre: item.ordre,
            };
        });
    };


    const exportCSV = () => {
        const csvData = record.map(row => ({
            Nom: row.nom,
            Description: truncateAndEscapeHTML(row.description, 30),
            Cout_minimal: row.cout_min,
            Duree: row.duration,
            sessionFormation: row.sessionFormation.length,
            Ordre: row.ordre,
        }));
        
        const csvHeaders = ['Nom', 'Description', 'Cout_minimal', 'Duree', 'sessionFormation', 'Ordre'];
        const csvRows = [csvHeaders.join(','), ...csvData.map(item => csvHeaders.map(header => item[header]).join(','))];
        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'type_formation.csv');
    };

    const exportPdf = () => {
        const doc = new jsPDF();
        doc.text('Formation', 10, 10);
        doc.autoTable({
            head: [['Nom', 'Description', 'Cout_minimal', 'Duree', 'sessionFormation', 'Ordre']],
            body: record.map(row => [
                row.nom,
                truncateAndEscapeHTMLExport(row.description, 70),
                row.cout_min,
                row.duration,
                row.sessionFormation.length,
                row.ordre,
            ]),
        });
        doc.save('type_formation.pdf');
    };


    const exportExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            record.map(row => ({
                Nom: row.nom,
                Description: truncateAndEscapeHTML(row.description, 30),
                Cout_minimal: row.cout_min,
                Duree: row.duration,
                sessionFormation: row.sessionFormation.length,
                Ordre: row.ordre,
            }))
        );
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });
    
        saveAs(new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' }), 'formation.xlsx');
    };

    const linkColor = {
        color: '#fdc800',
    }

    const header = (
        <div className="flex align-items-center justify-content-end gap-2 export-buttons">
            <Tooltip target=".export-buttons>button" position="bottom" />
            <Button
                type="button"
                icon="pi pi-file"
                rounded
                onClick={exportCSV}
                data-pr-tooltip="CSV"
            />
            <Button
                type="button"
                icon="pi pi-file-excel"
                severity="success"
                rounded
                onClick={exportExcel}
                data-pr-tooltip="XLS"
            />
            <Button
                type="button"
                icon="pi pi-file-pdf"
                severity="warning"
                rounded
                onClick={exportPdf}
                data-pr-tooltip="PDF"
            />
        </div>
    );


    const columns = [
       
        {
            field: 'nom',
            header: 'Nom',
            sortable: true,
            headerStyle: { textAlign: 'center' },

        },
        {

            field: 'description',
            header: 'Description',
            body: row =>  (
                <div dangerouslySetInnerHTML={{ __html: truncateAndEscapeHTML(row.description, 30) }} />

            ),
            sortable: true,
            headerStyle: { textAlign: 'center' },
        },
        {

              field: 'cout_min',
              header: 'Cout minimal',
              sortable: true,
              headerStyle: { textAlign: 'center' },
        },
        {
            field: 'duration',
            header: 'Duree',
            sortable: true,
            headerStyle: { textAlign: 'center' },
            
        },
       {
            field: 'sessionFormation',
            header: 'Nombre de sessions',
            body: row => (
                <Button
                    label={row.sessionFormation.length}
                    className="p-button-text"
                    style={linkColor}
                    onClick={() => window.location.href = `/session-formation/${row.id}`}
                />
            ),
            sortable: true,
            headerStyle: { textAlign: 'center' },

        
        },
        {
              field: 'ordre',
              header: 'Ordre affichage',
              body: row => (
                <b style={linkColor}>{row.ordre}</b>
              ),
              sortable: true,
              headerStyle: { textAlign: 'center' },
        },
        {
            field: 'Actions',
            header: 'Actions',
            body: row => (
                <>
                {(row.showformation === 1) ? 
                <>
                <i className="fa fa-eye" title='caché' style={colorEdit} onClick={() => handleHideConfirmation(row.id)}/>
                 </>: <>
                <i className="fa fa-eye-slash" title='affiché' style={colorEdit} onClick={() => handleShowConfirmation(row.id)}/>
                </>
                 }
                <i className="fa fa-edit" title='modifier' style={colorEdit} onClick={() => handleUpdate(row)}/>
                <i className="fa fa-trash" title='supprimer' style={colorDrop} onClick={() => handleDeleteConfirmation(row.id)}/>
                </>
            ),
            allowOverflow: true,
            button: true
        },
    ];

    const handleFilter = (e) => {
        const newData = formationDatas.filter(row => {
            return row.nom.toLowerCase().includes(e.target.value.toLowerCase());
        });
        setRecord(newData);
    };


    const searchStyles = {
        border: '2px solid',
        height: '45px',
        borderRadius: '26px',
      };
      const stylePadding_top = {
        paddingTop: '37px',
      }
      const [show,setShow] = useState(false)
      const colorDrop = {
        color: 'red',
      }

      const colorEdit = {
        color: '#fdc800',
      }

      const handleClose = () => {setShow(false)};
	  const handleShow = () => setShow(true);


    return (
        <div className="blog-area pd-bottom-120 go-top">
            <div className="container">
                <div className="row" style={stylePadding_top}>
                <div className="col-md-6">
                                <div className="single-input-inner">
                                <Button className="btn btn-secondary btn-radius"  size="lg" onClick={handleShow}>
                                    ajourer
                                </Button>
                                <Modal show={show} onHide={handleClose} handleUpdateFormation={handleUpdateFormation}  backdrop="static" >
									<Modal.Header closeButton>
									<Modal.Title>Ajouter une nouvelle formation</Modal.Title>
									</Modal.Header>
									<Modal.Body>
									   <FormationAddModal handleClose={handleClose} handleShow={handleShow} />
									</Modal.Body>
								</Modal>
                                <UpdateFormation   
                                                    formation={formation} 
                                                    setFormation={setFormation}
                                                    handleCloseUpdateFormation={handleCloseUpdateFormation}
                                                    showUpdateFormation={showUpdateFormation}
                                                    />
                                </div>
                            </div>
                            <div className="col-md-6 d-grid gap-2 d-flex justify-content-end">

                            <input type='text'   placeholder='     rechercher' onChange={handleFilter} style={searchStyles}/>

                            
                            </div>
                    <div className="col-lg-12">
                        <div className="single-blog-inner style-border">
                           
                            <div style={{ overflowX: 'auto' }}>
                            <DataTable
                                    ref={dt}
                                    value={record}
                                    paginator
                                    header={header}
                                    rows={10}
                                    dataKey="id"
                                    responsiveLayout="scroll"
                                    showGridlines
                                >
                                    {columns.map((col, index) => (
                                        <Column
                                            key={index}
                                            field={col.field}
                                            header={col.header}
                                            body={col.body}
                                            sortable={col.sortable}
                                            headerStyle={col.headerStyle}
                                        />
                                    ))}
                                </DataTable>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
