import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { RingLoader } from "react-spinners";
import { useCategorieContext } from '../../../../context/CategorieContext';
import { addSession, getsessionformationByFormation } from '../../../../services/session/service';
import Swal from 'sweetalert2';
import { useAdminContext } from '../../../../context/AdminContext';
import { addEvenementService, getEvenements } from '../../../../services/evenement/service';


const AddEvenement = (props) => {
    const { hancleCloseAddEvenement, showAddEvenement,id,setRecord } = props;
    const { incommingSession } = useAdminContext();
    const {evenementData,setEvenementData} = useCategorieContext();
    const [imageUrl,setImageUrl] = useState(null)
    const [imageUrlDetail,setImageUrlDetail] = useState(null)
    const [imageUrlArticle,setImageUrlArticle] = useState([])
    const [imageDetailName, setImageDetailName] = useState("");
    
    const [loading, setLoading] = useState(false);
    const [evenement, setEvenement] = useState({
        date_debut: "",
        date_fin: "",
        titre: "",
        lieu: "",
        heure: "",
        description: "",
        longitude: "",
        latittude: "",
        nombre_participant: "",
        image: "",
        image_details: "",
        articles: [{}],
        correctdatDebut: true,
        correctDateFin: true,
        CorrectCoutFormation: true,
        correctFormatiom: true,
        correctLieuFormation: true
    });
    const [isCreated, setIsCreated] = useState(false);
    const [isError, setIsError] = useState({value:false,message:""});

    const currentDate = new Date().toISOString().split('T')[0]; // Obtient la date actuelle au format "YYYY-MM-DD"
    const [dynamicFields, setDynamicFields] = useState([]);
    const [previousDynamicFieldValues, setPreviousDynamicFieldValues] = useState([
        {
            titre: '',
            content: '',
           
        }
    ]);
     // Nouvel état pour stocker les valeurs des champs dynamiques précédents
    const [canAdd, setCanAdd] = useState(true);
    const [imageName, setImageName] = useState("");
    const styleBlock = {
        border: '1px solid #ffc107',
        paddingTop: '13px' 
    }

    const handleAddHoraireChange = (index, e) => {
        const { name } = e.target;
        const updatedValues = [...previousDynamicFieldValues];
        updatedValues[index][name] = e.target.files[0]; // Assuming the file is directly stored in the state
        setPreviousDynamicFieldValues(updatedValues);
        const imagesValue = [...imageUrlArticle]
        imagesValue[index] = URL.createObjectURL(e.target.files[0])
        setImageUrlArticle(imagesValue)
    };
    // useEffect(() => {
    //     console.log('Previous Dynamic Field Values:', previousDynamicFieldValues);
    //     const canAdd = previousDynamicFieldValues.every(field => field.titre && field.content);
    //     setCanAdd(canAdd);
    // }, [previousDynamicFieldValues]);

    const handleAddField = () => {
        setPreviousDynamicFieldValues([...previousDynamicFieldValues, { titre: '', content: '' }]);
    };

    const handleSelectChange = (e) => {
        // Mettre à jour les valeurs du formulaire
        setEvenement({
            ...evenement,
            [e.target.name]: e.target.value
        });
    };

    const handleChange = (e) =>{
        // Mettre à jour les valeurs du formulaire
        setEvenement({
            ...evenement,
            [e.target.name]: e.target.value
        });
    }

    const handleAddArticle = (index, e) => {
        const { name, value } = e.target;
        const updatedValues = [...previousDynamicFieldValues];
        updatedValues[index][name] = value;
        setPreviousDynamicFieldValues(updatedValues);
    };
    

    const fetchSessionByFormation = async (id) =>{

        try {
             const response = await getsessionformationByFormation(id)
             setRecord(response)
        } catch (error) {
            
        }
    }

    const showSuccessAlert =  () => {
        hancleCloseAddEvenement()
        Swal.fire({
          icon: "success",
          title: "La session a été créée avec succès !",
          showConfirmButton: false,
          timer: 5000
        });
        setIsCreated(false)
        setPreviousDynamicFieldValues([
            {
                modalite: '',
                jour: '',
                heure: ''
            }
        ])

        

      };

      const showErrorAlert = () =>{
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: isError.message,
          showConfirmButton: false,
          timer: 5000
        });
        setIsError({...isError,value:false})
      }
    

    const handleAddSession = async () => {
        try {

            
            
            setLoading(true);
            const formData = new FormData();
            formData.append('date_debut', evenement.date_debut);
            formData.append('date_fin', evenement.date_fin);
            formData.append('titre', evenement.titre);
            formData.append('longitude', evenement.longitude);
            formData.append('latittude', evenement.latittude);
            formData.append('lieu', evenement.lieu);
            formData.append('heure', evenement.heure);
            formData.append('description', evenement.description);
            formData.append('lieu_formation_id', evenement.lieu_formation_id);
            formData.append('nombre_participant', evenement.nombre_participant);
            formData.append('image', evenement.image);
            formData.append('image_details', evenement.image_details);
            console.log('Previous Dynamic Field Values:', previousDynamicFieldValues);
            // formData.append(`articles`, ...previousDynamicFieldValues);
            previousDynamicFieldValues && previousDynamicFieldValues.forEach((field, index) => {
                if (field.content) {
                    // evenement.articles[index] = field.titre
                    // evenement.articles[index] = field.content
                    //formData.append(`articles[${index}][titre]`, field.titre);
                    formData.append(`articles[${index}][content]`, field.content);
                }
            });
            
            
            console.log("Données avant envoi formData:", formData);
            const response = await addEvenementService(formData);
            setIsCreated(true)

        } catch (error) {
            console.error('Erreur lors de l\'inscription à la session :', error);
            setIsError({value: true,message: `Erreur lors de l'envoi des données de formation : ${error}`})

            // Gérer les erreurs
        } finally {
            setLoading(false); 
            setPreviousDynamicFieldValues([
                {
                    modalite: '',
                    jour: '',
                    heure: ''
                }
            ])
            hancleCloseAddEvenement();
            const evenementResponse = await getEvenements();
            setEvenementData(evenementResponse);
        }
    };

    const buttonStyles = {
        width: '100%',
        border: '2px solid rgba(8, 76, 148, 0.2)',
        height: '58px',
        borderRadius: '26px',
        padding: '0 18px',
    };

    const addStyles = {
        borderRadius: '26px',
        padding: '0 3px',
        marginBottom: '9px',
    };

    const fileInputRef = useRef(null);
  const fileInputDetailsRef = useRef(null);
  const fileInputArticleRef = useRef([]);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileDetailButtonClick = () => {
    fileInputDetailsRef.current.click();
  };

  const handleFileArticleButtonClick = () => {
    fileInputArticleRef.current.click();
  };

  const handleFileChange = (e) => {
    setImageName(e.target.files[0].name);

    setEvenement({
        ...evenement,
        image: e.target.files[0]
    });
   
    setImageUrl(URL.createObjectURL(e.target.files[0]))
  };
  
  const handleFileDetailChange = (e) => {
    setImageDetailName(e.target.files[0].name);
    setEvenement({
        ...evenement,
        image_details: e.target.files[0]
    });
   
    setImageUrlDetail(URL.createObjectURL(e.target.files[0]))

  };


    return (
       <>
       { isCreated &&  showSuccessAlert()}
       {isError.value && showErrorAlert()}
        <Modal show={showAddEvenement} onHide={hancleCloseAddEvenement}  backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Création d'un Evenement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="border d-flex align-items-center justify-content-center">
                        <RingLoader color="#fdc800" size='140' loading={loading} />
                    </div>
                ) : (
                    <Form>
                    <div className="row">
                    <div className="col-md-6">
                        <Form.Group>
                            <Form.Label>Titre</Form.Label>
                            <div className="single-input-inner-custome">
                                <input type="text" name="titre" onChange={handleChange} className="form-control" />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group>
                            <Form.Label>Nombre de participant</Form.Label>
                            <div className="single-input-inner-custome">
                                <input type="number" name="nombre_participant" onChange={handleChange} className="form-control" />
                            </div>
                        </Form.Group>
                    </div>

                    <div className="col-md-6">
                        <Form.Group>
                            <Form.Label>Lieu</Form.Label>
                            <div className="single-input-inner-custome">
                                <input type="text" name="lieu" onChange={handleChange} className="form-control" />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group>
                            <Form.Label>Heure de debut</Form.Label>
                            <div className="single-input-inner-custome">
                                <input type="time" name="heure" onChange={handleChange} className="form-control" />
                            </div>
                        </Form.Group>
                    </div>

                    <div className="col-md-6">
                        <Form.Group>
                            <Form.Label>latittude</Form.Label>
                            <div className="single-input-inner-custome">
                                <input type="text" name="latittude" onChange={handleChange} className="form-control" />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group>
                            <Form.Label>longitude</Form.Label>
                            <div className="single-input-inner-custome">
                                <input type="text" name="longitude" onChange={handleChange} className="form-control" />
                            </div>
                        </Form.Group>
                    </div>

                    <div className="col-md-12">
                        <Form.Group>
                            <Form.Label>Description</Form.Label>
                            <div className="single-input-inner-custome">
                               <textarea placeholder="Description"  name="description" onChange={handleChange}/>
                            </div>
                        </Form.Group>
                    </div>

                        <div className="col-md-6">
                            <Form.Group>
                                <Form.Label>Date de début</Form.Label>
                                <div className={evenement.correctdatDebut ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                                    <input type="date" name="date_debut" onChange={handleChange}  className="form-control" />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group>
                                <Form.Label>Date de fin</Form.Label>
                                <div className={evenement.correctDateFin ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                                    <input type="date" name="date_fin" onChange={handleChange}  className="form-control" />
                                </div>
                            </Form.Group>
                        </div>

                        <div className="col-md-6">
                            <div className={"single-input-inner-custome"}>
                                <Button className="btn btn-base btn-radius" onClick={handleFileButtonClick} style={buttonStyles}>Choisir image 370X200</Button>
                                <input type="file" name="image" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
                                {imageName && <img src={imageUrl} alt="img" />}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={"single-input-inner-custome"}>
                                <Button className="btn btn-base btn-radius" onClick={handleFileDetailButtonClick} style={buttonStyles}>Choisir image 770X500</Button>
                                <input type="file" name="image_details" ref={fileInputDetailsRef} style={{ display: "none" }} onChange={handleFileDetailChange} />
                                {imageDetailName && <img src={imageUrlDetail} alt="img" />}
                            </div>
                        </div>
                       
                    </div>
                    {
                    previousDynamicFieldValues.map((input, index) => (
                         (
                            <div key={index} className="row" style={styleBlock}>
                            {/* <div className="col-md-12">
                                       <Form.Group>
                                           <div className={evenement.correctDateFin ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
           
                                           <Form.Label>Titre</Form.Label>
                                            <div className={evenement.correctdatDebut ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                                               <input type="text" name="titre" onChange={(e) => handleAddArticle(index, e)} className="form-control" />
                                            </div>
                                           </div>
                                       </Form.Group>
                                   </div> */}
                                   <div className="col-md-12">
                                        <div className={"single-input-inner-custome"}>
                                            <Button className="btn btn-base btn-radius" onClick={handleFileArticleButtonClick} style={buttonStyles}>Choisir image 370X270</Button>
                                            <input type="file" name="content" ref={fileInputArticleRef} style={{ display: "none" }} onChange={e => handleAddHoraireChange(index, e)} />
                                            {imageUrlArticle[index] && <img src={imageUrlArticle[index]} alt="img" />}
                                        </div>
                                       {/* <Form.Group>
                                           <Form.Label>Contenu de l'article</Form.Label>
                                           <div className={evenement.correctdatDebut ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                                               <input type="file" name="content" onChange={e => handleAddHoraireChange(index, e)} className="form-control" />
                                           </div>
                                       </Form.Group> */}
                                   </div>
                                  
                             </div>  
                        )
                         
                        ))}
                         <div className="col-md-12">
                        <Button className="btn btn-base btn-radius" size="xs" onClick={handleAddField} style={addStyles}>Ajouter</Button> Ajouter une nouvelle plage d'horaire
                        </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-left">
                                <Button className="single-input-inner btn btn-secondary btn-radius" size="lg" style={buttonStyles} onClick={hancleCloseAddEvenement}>
                                    Fermer
                                </Button>
                            </div>
                            <div className="col-md-6 text-right">
                                <Button className="btn btn-base btn-radius" size="lg" style={buttonStyles} onClick={handleAddSession}>
                                  Nouvelle session
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
                
                )}
            </Modal.Body>
        </Modal>
       </>
    );
};

export default AddEvenement;
