import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { RingLoader } from "react-spinners";
import { useCategorieContext } from '../../../../context/CategorieContext';
import { addSession, getsessionformationByFormation } from '../../../../services/session/service';
import Swal from 'sweetalert2';
import { useAdminContext } from '../../../../context/AdminContext';
import { sessionAvenirTypeFormationService, sessionPasseTypeFormationService } from '../../../../services/typeformation/service';


const AddSession = (props) => {
    const { handleCloseAddSsession, showAddSsession,id,setRecord,recort,formation,type } = props;
    const { incommingSession } = useAdminContext();

    const { formationDatas, setFormationDatas,lieuformation} = useCategorieContext();
    
    const [loading, setLoading] = useState(false);
    const [sessionformation, setSessionformation] = useState({
        date_debut: "",
        date_fin: "",
        cout_formation: "",
        formation_id: "",
        est_payant: 1,
        lieu_formation_id: "",
        modalites: [],
        paymentMode: {
            inscription: '',
            mensualite: '',
            nombreMoi: ''
        },
        correctdatDebut: true,
        correctDateFin: true,
        CorrectCoutFormation: true,
        correctFormatiom: true,
        correctLieuFormation: true
    });
    const [isCreated, setIsCreated] = useState(false);
    const [isError, setIsError] = useState({value:false,message:""});

    const currentDate = new Date().toISOString().split('T')[0]; // Obtient la date actuelle au format "YYYY-MM-DD"
    const [dynamicFields, setDynamicFields] = useState([]);
    const [previousDynamicFieldValues, setPreviousDynamicFieldValues] = useState([
        {
            modalite: '',
            jour: '',
            heure: ''
        }
    ]);
     // Nouvel état pour stocker les valeurs des champs dynamiques précédents
    const [canAdd, setCanAdd] = useState(true);

    const styleBlock = {
        border: '1px solid #ffc107',
        paddingTop: '13px',
        margin: '3px 3px 8px 3px' 
    }

    const handleAddHoraireChange = (index,e) => {
        // Mettre à jour les valeurs du formulaire

        let data = [...previousDynamicFieldValues];
        const { name, value } = e.target;
        data[index][e.target.name] = e.target.value;
        setPreviousDynamicFieldValues(data);
    };
    useEffect(() => {
        console.log('Previous Dynamic Field Values:', previousDynamicFieldValues);
        if (previousDynamicFieldValues.heure && previousDynamicFieldValues.modalite && previousDynamicFieldValues.jour) {
            setCanAdd(true)
        }
    }, [previousDynamicFieldValues]);

    const handleAddField = () => {
        console.log('Previous Dynamic Field Values when adding:', previousDynamicFieldValues);

        let newField = {
            modalite: '',
            jour: '',
            heure: ''
        }

        setPreviousDynamicFieldValues([...previousDynamicFieldValues,newField])
        
      
       
       
    };

    const handleSelectChange = (e) => {
        // Mettre à jour les valeurs du formulaire
        setSessionformation({
            ...sessionformation,
            [e.target.name]: e.target.value
        });
    };

    const handleChange = (e) =>{
        // Mettre à jour les valeurs du formulaire
        setSessionformation({
            ...sessionformation,
            [e.target.name]: e.target.value
        });
    }


    const handleChangePaymentMode = (e) =>{
        const { name, value } = e.target;
        setSessionformation({
            ...sessionformation,
            paymentMode: {
                ...sessionformation.paymentMode,
                [name]: value
            }
        });
    }

    const fetchSessionByFormation = async (id) =>{

        try {
             const response = await getsessionformationByFormation(id)
             setRecord(response)
        } catch (error) {
            
        }
    }

    const showSuccessAlert =  () => {
        handleCloseAddSsession()
        Swal.fire({
          icon: "success",
          title: "La session a été créée avec succès !",
          showConfirmButton: false,
          timer: 5000
        });
        setIsCreated(false)
        setPreviousDynamicFieldValues([
            {
                modalite: '',
                jour: '',
                heure: ''
            }
        ])

        

      };

      const showErrorAlert = () =>{
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: isError.message,
          showConfirmButton: false,
          timer: 5000
        });
        setIsError({...isError,value:false})
      }
    

    const handleAddSession = async () => {
        try {

            
            
            setLoading(true);
            const formData = new FormData();
            formData.append('date_debut', sessionformation.date_debut);
            formData.append('date_fin', sessionformation.date_fin);
            formData.append('cout_formation', sessionformation.cout_formation);
            formData.append('est_payant', sessionformation.est_payant);
            if (sessionformation.formation_id) {
               formData.append('formation_id', sessionformation.formation_id);
                
            }else{
                if (formation && formation.id) {
                    formData.append('formation_id', formation.id);
                }
                else{
                    formData.append('formation_id', formationDatas[0].id);
                }
            }
            formData.append('lieu_formation_id', sessionformation.lieu_formation_id);
            sessionformation.paymentMode.inscription && formData.append('inscription', sessionformation.paymentMode.inscription);
            sessionformation.paymentMode.mensualite && formData.append('mensualite', sessionformation.paymentMode.mensualite);
            sessionformation.paymentMode.nombreMoi && formData.append('nombreMoi', sessionformation.paymentMode.nombreMoi);
            previousDynamicFieldValues && previousDynamicFieldValues.forEach((modalite, index) => {
                if (modalite.modalite.length > 0) {
                    formData.append(`modalites[${index}][modalite]`, modalite.modalite);
                    formData.append(`modalites[${index}][jour]`, modalite.jour);
                    formData.append(`modalites[${index}][heure]`, modalite.heure);
                }
            });
            console.log("Données avant envoi formData:", formData);
            const response = await addSession(formData);
            if (type === 'avenir') {
                const response = await sessionAvenirTypeFormationService(id)
                 setRecord(response)
            }
            if (type === 'passe') {
                const response = await sessionPasseTypeFormationService(id)
                setRecord(response)
            }
            if (type === 'formation') {

                if (id) {
                    const response = await getsessionformationByFormation(id)

                     setRecord(response)
                }else{
                    setRecord(incommingSession);
                }
                
            }

        } catch (error) {
            console.error('Erreur lors de l\'inscription à la session :', error);
            setIsError({value: true,message: `Erreur lors de l'envoi des données de formation : ${error}`})

            // Gérer les erreurs
        } finally {
            setLoading(false); 
            handleCloseAddSsession();
        }
    };

    const buttonStyles = {
        width: '100%',
        border: '2px solid rgba(8, 76, 148, 0.2)',
        height: '58px',
        borderRadius: '26px',
        padding: '0 18px',
    };

    const addStyles = {
        borderRadius: '26px',
        padding: '0 3px',
        marginBottom: '9px',
    };

    return (
       <>
       { isCreated &&  showSuccessAlert()}
       {isError.value && showErrorAlert()}
        <Modal show={showAddSsession} onHide={handleCloseAddSsession}  backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Création d'une nouvelle session de formation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="border d-flex align-items-center justify-content-center">
                        <RingLoader color="#fdc800" size='140' loading={loading} />
                    </div>
                ) : (
                    <Form>
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group>
                                <Form.Label>Date de début</Form.Label>
                                <div className={sessionformation.correctdatDebut ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                                    <input type="date" name="date_debut" onChange={handleChange} min={currentDate} className="form-control" value={sessionformation && sessionformation.date_debut} />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group>
                                <Form.Label>Date de fin</Form.Label>
                                <div className={sessionformation.correctDateFin ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                                    <input type="date" name="date_fin" onChange={handleChange} min={currentDate} value={sessionformation && sessionformation.date_fin} className="form-control" />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group>
                                <Form.Label>Formation</Form.Label>
                                <div className={sessionformation.correctdatDebut ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                                <Form.Select aria-label="Default select example" onChange={handleSelectChange}  name="formation_id">
                                {formation && <option value={formation.id}>{formation.nom}</option>}
                                
                                {formationDatas &&
                                    formationDatas.map((formation, index) => (
                                    <option value={formation.id} key={`typetab${index}`}>
                                        {formation.nom}
                                    </option>
                                    ))}
                                </Form.Select>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group>
                                <Form.Label>Lieu de formation</Form.Label>
                                <div className={sessionformation.correctDateFin ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                                <Form.Select aria-label="Default select example" onChange={handleSelectChange}  name="lieu_formation_id">
                                <option value={0}>Sélectionnez l'emplacement de votre formation.</option>
                                {lieuformation &&
                                    lieuformation.map((lieu, index) => (
                                    <option value={lieu.id} key={`typetab${index}`}>
                                        {lieu.region}/{lieu.commune}
                                    </option>
                                    ))}
                                </Form.Select>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group>
                                <Form.Label>cout de la formation</Form.Label>
                                <div className={sessionformation.correctdatDebut ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                                    <input type="number" name="cout_formation" onChange={handleChange} className="form-control" />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group>
                                <Form.Label>Est payante</Form.Label>
                                <div className={sessionformation.correctDateFin ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                                <Form.Select aria-label="Default select example" onChange={handleSelectChange}  name="est_payant">
                                    <option value={1}>Oui</option>
                                    <option value={0}>Non</option>
                                </Form.Select>
                                </div>
                            </Form.Group>
                        </div>
                       
                    </div>
                    <div className="row" style={styleBlock}>
                            <div className="col-md-12">
                            <Form.Group>
                                <Form.Label>cout à l'inscription</Form.Label>
                                <div className={sessionformation.correctdatDebut ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                                    <input type="number" name="inscription" onChange={handleChangePaymentMode} value={sessionformation && sessionformation.inscription} className="form-control" />
                                </div>
                            </Form.Group>
                                   </div>
                                   <div className="col-md-6">
                                   <Form.Group>
                                        <Form.Label>Mensualité</Form.Label>
                                        <div className={sessionformation.correctdatDebut ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                                            <input type="number" name="mensualite" onChange={handleChangePaymentMode} value={sessionformation && sessionformation.mensualite} className="form-control" value={sessionformation && sessionformation.mensualite}/>
                                        </div>
                                    </Form.Group>
                                   </div>
                                   <div className="col-md-6">
                                   <Form.Group>
                                        <Form.Label>nombre de mois</Form.Label>
                                        <div className={sessionformation.correctdatDebut ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                                            <input type="number" name="nombreMoi" onChange={handleChangePaymentMode} value={sessionformation && sessionformation.nombreMoi} className="form-control" />
                                        </div>
                                    </Form.Group>
                                   </div>
                             </div>  
                    {
                    previousDynamicFieldValues.map((input, index) => (
                         (
                            <div key={index} className="row" style={styleBlock}>
                            <div className="col-md-12">
                                       <Form.Group>
                                           <div className={sessionformation.correctDateFin ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
           
                                               <Form.Select aria-label="Default select example" onChange={e => handleAddHoraireChange(index, e)}  name="modalite">
                                               <option value={0}>Choisir l'horaire du cours :</option>
                                               <option value="Cours du matin">Cours du matin</option>
                                               <option value="Cours du soir">Cours du soir</option>
                                               <option value="Cours week-end">Cours week-end</option>
                                               </Form.Select>
                                           </div>
                                       </Form.Group>
                                   </div>
                                   <div className="col-md-6">
                                       <Form.Group>
                                           <Form.Label>Saisir les jours de cours</Form.Label>
                                           <div className={sessionformation.correctdatDebut ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                                               <input type="text" name="jour" onChange={e => handleAddHoraireChange(index, e)} className="form-control" />
                                           </div>
                                       </Form.Group>
                                   </div>
                                   <div className="col-md-6">
                                       <Form.Group>
                                           <Form.Label>Plage horaire des cours</Form.Label>
                                           <div className={sessionformation.correctdatDebut ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                                               <input type="text" name="heure" onChange={e => handleAddHoraireChange(index, e)} className="form-control" />
                                           </div>
                                       </Form.Group>
                                   </div>
                             </div>  
                        )
                         
                        ))}
                         <div className="col-md-12">
                        <Button className="btn btn-base btn-radius" size="xs" onClick={handleAddField} style={addStyles}>Ajouter</Button> Ajouter une nouvelle plage d'horaire
                        </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-left">
                                <Button className="single-input-inner btn btn-secondary btn-radius" size="lg" style={buttonStyles} onClick={handleCloseAddSsession}>
                                    Fermer
                                </Button>
                            </div>
                            <div className="col-md-6 text-right">
                                <Button className="btn btn-base btn-radius" size="lg" style={buttonStyles} onClick={handleAddSession}>
                                  Nouvelle session
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
                
                )}
            </Modal.Body>
        </Modal>
       </>
    );
};

export default AddSession;
