import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
    baseURL: apiUrl,
  });

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
};
  

/**
 * get all fformation type
 */


export const getCurrentUser = async ()=>{
         try {
            const response = apiService.get('/user/currentUser',config).then(res =>{
                console.log(`current user is ${config.headers.Authorization}`);
                return res.data;
            });
            return response;
         } catch (error) {
            throw error;
         }
}


export const getInscriptionUser = async (id) =>{

  try {
    const response = await apiService.get(`user/getinscription/inscription/${id}`).then(res =>{
      console.log('user information',res.data);
      return res.data
    })
    return response.userSession
  } catch (error) {
    throw error;
  }
}


export const ProductService = {
  getProductsData() {
      return [
        {
          id: '1000',
          code: 'f230fh0g3',
          name: 'Ordinateurs',
          description: 'Product Description',
          image: 'assets/img/vente/5.jpg',
          price: 65,
          category: 'Accessories',
          quantity: 24,
          inventoryStatus: 'INSTOCK',
          link: 'https://bakhbade.com/categorie-produit/articles-de-type-informatique-3/pc-portable/',
          rating: 5
      },
          {
              id: '1001',
              code: 'f230fh0g3',
              name: 'Imprimantes',
              description: 'Product Description',
              image: 'assets/img/vente/1.jpg',
              price: 65,
              category: 'Accessories',
              quantity: 24,
              inventoryStatus: 'INSTOCK',
              link: 'https://bakhbade.com/categorie-produit/articles-de-type-informatique-3/imprimante/',
              rating: 5
          },
          {
            id: '1002',
            code: 'f230fh0g3',
            name: 'Materiels reseaux',
            description: 'Product Description',
            image: 'assets/img/vente/7.jpg',
            price: 65,
            category: 'Accessories',
            quantity: 24,
            inventoryStatus: 'INSTOCK',
            link: 'https://bakhbade.com/categorie-produit/internet/',
            rating: 5
          },
          {
            id: '1003',
            code: 'f230fh0g3',
            name: 'Telephones',
            description: 'Product Description',
            image: 'assets/img/vente/2.jpg',
            price: 65,
            category: 'Accessories',
            quantity: 24,
            inventoryStatus: 'INSTOCK',
            link: 'https://bakhbade.com/categorie-produit/telephonie-tablettes/telephones/',
            rating: 5
        },
        {
          id: '1004',
          code: 'f230fh0g3',
          name: 'Audiovisuels',
          description: 'Product Description',
          image: 'assets/img/vente/4.jpg',
          price: 65,
          category: 'Accessories',
          quantity: 24,
          inventoryStatus: 'INSTOCK',
          link: 'https://bakhbade.com/categorie-produit/camer',
          rating: 5
      },
        {
          id: '1005',
          code: 'f230fh0g3',
          name: 'Console et jeux',
          description: 'Product Description',
          image: 'assets/img/vente/3.jpg',
          price: 65,
          category: 'Accessories',
          quantity: 24,
          inventoryStatus: 'INSTOCK',
          link: 'https://bakhbade.com/categorie-produit/consoles-et-jeux/',
          rating: 5
      },
     
    
  {
    id: '1006',
    code: 'f230fh0g3',
    name: 'Coiffure',
    description: 'Product Description',
    image: 'assets/img/vente/6.jpg',
    price: 65,
    category: 'Accessories',
    quantity: 24,
    inventoryStatus: 'INSTOCK',
    link: 'https://bakhbade.com/categorie-produit/electromenager/petit-elecro/tondeuses/',
    rating: 5
},

{
  id: '1007',
  code: 'f230fh0g3',
  name: 'Anneaux Zikr inteligente',
  description: 'Product Description',
  image: 'assets/img/vente/8.jpg',
  price: 65,
  category: 'Accessories',
  quantity: 24,
  inventoryStatus: 'INSTOCK',
  link: 'https://bakhbade.com/?s=zikr',
  rating: 5
},
{
  id: '1008',
  code: 'f230fh0g3',
  name: 'Ecouteur Bluetooth',
  description: 'Product Description',
  image: 'assets/img/vente/9.jpg',
  price: 65,
  category: 'Accessories',
  quantity: 24,
  inventoryStatus: 'INSTOCK',
  link: 'https://bakhbade.com/categorie-produit/articles-de-type-televiseurs/audios/casque/',
  rating: 5
},
{
  id: '10009',
  code: 'f230fh0g3',
  name: 'Ecouteur Bluetooth',
  description: 'Product Description',
  image: 'assets/img/vente/10.jpg',
  price: 65,
  category: 'Accessories',
  quantity: 24,
  inventoryStatus: 'INSTOCK',
  link: 'https://bakhbade.com/categorie-produit/articles-de-type-televiseurs/audios/casque/',
  rating: 5
},
{
  id: '1010',
  code: 'f230fh0g3',
  name: 'Ecouteur Bluetooth',
  description: 'Product Description',
  image: 'assets/img/vente/11.jpg',
  price: 65,
  category: 'Accessories',
  quantity: 24,
  inventoryStatus: 'INSTOCK',
  link: 'https://bakhbade.com/categorie-produit/articles-de-type-televiseurs/audios/casque/',
  rating: 5
},
{
  id: '1011',
  code: 'f230fh0g3',
  name: 'Televison',
  description: 'Product Description',
  image: 'assets/img/vente/12.jpg',
  price: 65,
  category: 'Accessories',
  quantity: 24,
  inventoryStatus: 'INSTOCK',
  link: 'https://bakhbade.com/categorie-produit/articles-de-type-televiseurs/televiseur/television/',
  rating: 5
},
{
  id: '1012',
  code: 'f230fh0g3',
  name: 'Tablettes',
  description: 'Product Description',
  image: 'assets/img/vente/13.jpg',
  price: 65,
  category: 'Accessories',
  quantity: 24,
  inventoryStatus: 'INSTOCK',
  link: 'https://bakhbade.com/categorie-produit/telephonie-tablettes/tablettes/',
  rating: 5
},
          
      ];
  },

  getProductsWithOrdersData() {
      return [
          {
            id: '1000',
            code: 'f230fh0g3',
            name: 'Ordinateurs',
            description: 'Product Description',
            image: 'assets/img/vente/5.jpg',
            price: 65,
            category: 'Accessories',
            quantity: 24,
            inventoryStatus: 'INSTOCK',
            link: 'https://bakhbade.com/categorie-produit/articles-de-type-informatique-3/pc-portable/',
            rating: 5,
              orders: [
                  {
                      id: '1000-0',
                      productCode: 'f230fh0g3',
                      date: '2020-09-13',
                      amount: 65,
                      quantity: 1,
                      customer: 'David James',
                      status: 'PENDING'
                  },
                  {
                      id: '1000-1',
                      productCode: 'f230fh0g3',
                      date: '2020-05-14',
                      amount: 130,
                      quantity: 2,
                      customer: 'Leon Rodrigues',
                      status: 'DELIVERED'
                  },
                  {
                      id: '1000-2',
                      productCode: 'f230fh0g3',
                      date: '2019-01-04',
                      amount: 65,
                      quantity: 1,
                      customer: 'Juan Alejandro',
                      status: 'RETURNED'
                  },
                  {
                      id: '1000-3',
                      productCode: 'f230fh0g3',
                      date: '2020-09-13',
                      amount: 195,
                      quantity: 3,
                      customer: 'Claire Morrow',
                      status: 'CANCELLED'
                  }
              ]
          },
         
      ];
  },

  getProductsMini() {
      return Promise.resolve(this.getProductsData().slice(0, 5));
  },

  getProductsSmall() {
      return Promise.resolve(this.getProductsData().slice(0, 10));
  },

  getProducts() {
      return Promise.resolve(this.getProductsData());
  },

  getProductsWithOrdersSmall() {
      return Promise.resolve(this.getProductsWithOrdersData().slice(0, 10));
  },

  getProductsWithOrders() {
      return Promise.resolve(this.getProductsWithOrdersData());
  }
};

