import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { checkInscriptionSession, inscriptionSession } from '../../services/inscription/service';
import { ClipLoader, RingLoader } from "react-spinners";
import { Navigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import OrangeMoney from './paiement-section/orange-money';


const InscriptionSession = (props) => {
    const { formation, showSubscribe, handleCloseSubscribe,handleShowSucessInscription,handleShowPaymentChoise , handleClosePaymentChoise, showPaymentChoise,handleCloseOrangeMoney,handleShowOrangeMoney,showOrangeMoney  } = props;
    const [sessionformation, setSessionformation] = useState({ id: 0 });
    const [modaliteId, setModaliteId] = useState(0);
    const [modalite, setModalite] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inscription,setInscription] = useState('')
    const [inscriptionWave,setInscriptionWave] = useState('')
    const [isCreated, setIsCreated] = useState(false);
    const [isCreatedWave, setIsCreatedWave] = useState(false);
    const [isError, setIsError] = useState({value:false,message:""});
    const [showResume,setShowResume] = useState(false)
    const handleCloseResume = ()=>{setShowResume(false)}
    const handleShowResume = ()=>{setShowResume(true)}

    const handleSelectChange = (e) => {
        const value = parseInt(e.target.value, 10);
        const sessionf = formation.sessionFormation.find(session => session.id === value) || { id: 0, modalites: [] };
        console.log('sessionf : ',sessionf);
        setSessionformation(sessionf);
        setModalite(sessionf.modalites);
        setModaliteId(sessionf.modalites.length > 0 ? sessionf.modalites[0].id : 0);
    };

    const handleChangeModalite = (e) => {
        const value = parseInt(e.target.value, 10);
        setModaliteId(value);
    };
    const showSuccessAlert =  () => {
        Swal.fire({
          icon: "success",
          title: `Inscription à la formation ${formation.nom}`,
          text: "Votre inscription a été réalisée avec succès. Vous recevrez bientôt un email contenant des informations supplémentaires.",
          showConfirmButton: false,
          timer: 5000
        });
        setIsCreated(false)
      };

      const showErrorAlert = () =>{
        Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: isError.message,
          showConfirmButton: true,
          timer: 7000
        });
        setIsError({...isError,value:false})
      }

    const handleSubscribePayUlterieure = async (e) => {
        e.preventDefault();
        try {
            setLoading(true)
            const response = await inscriptionSession(sessionformation.id, modaliteId);
           
            setInscription(response)
            setIsCreated(true)
            console.log('Réponse de l\'API :', response.id);
            
        } catch (error) {
            console.error('Erreur lors de l\'inscription à la session :', error);
            setIsError({value: true,message: `${error.response.data}`})
            // Gérer les erreurs
        }finally{
            setLoading(false); 
            handleCloseSubscribe();
            
            //handleShowSucessInscription();
        }
    };

    const handleSubscribePayByWave = async (e) =>{
        e.preventDefault();
        try {
            setLoading(true)
            const response = await inscriptionSession(sessionformation.id, modaliteId);
           
            setInscriptionWave(response)
            setIsCreatedWave(true)
        } catch (error) {
            setIsError({value: true,message: `${error.response.data}`})
        }finally{
            setLoading(false); 
            handleCloseSubscribe();
            
            //handleShowSucessInscription();
        }
    }
    const handleCanSave = async (e) => {
        e.preventDefault();
        try {
            setLoading(true)
            const response = await checkInscriptionSession(sessionformation.id, modaliteId);
           
            handleShowPaymentChoise();
            
        } catch (error) {
            setIsError({value: true,message: `${error.response.data}`})
        }finally{
            setLoading(false); 
            
            //handleShowSucessInscription();
        }
    }


    const buttonStyles = {
        width: '100%',
        border: '2px solid rgba(8, 76, 148, 0.2)',
        height: '58px',
        borderRadius: '26px',
        padding: '0 18px',
    };
    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 5
            }}
        />
    );

    useEffect(() => {
       
      }, [inscription]);

      useEffect(() => {
       
      }, [inscriptionWave]);

      if (isCreatedWave) {
        
        //return window.location.replace(`https://khoulefreres.com/api/wave/geturlpaiement/35/5/20`);

       return window.location.replace(`https://khoulefreres.com/api/wave/geturlpaiement/${sessionformation.id}/${modaliteId}/${inscriptionWave.id}`);
    }
      if (isCreated) {
        return <Navigate to={`/inscription/session/${inscription.id}`}  replace={true}  />;
      }

   
    const buttonStylesOrange = {
        width: '100%',
        border: '2px solid rgba(8, 76, 148, 0.2)',
        height: '58px',
        borderRadius: '26px',
        padding: '0 18px',
        background: '#ff7f00',
    };
    const buttonStylesWave = {
        width: '100%',
        border: '2px solid rgba(8, 76, 148, 0.2)',
        height: '58px',
        borderRadius: '26px',
        padding: '0 18px',
    };
    const buttonStylesUlteriement = {
        width: '100%',
        border: '2px solid rgba(8, 76, 148, 0.2)',
        height: '58px',
        borderRadius: '26px',
        padding: '0 18px',
        background:'#fdc800'
    };
    
    return (
       <>
        { (isCreated || isCreatedWave) &&  showSuccessAlert()}
        {isError.value && showErrorAlert()}
        <OrangeMoney handleCloseOrangeMoney={handleCloseOrangeMoney}
                     showOrangeMoney={showOrangeMoney}
                     sessionformation={sessionformation.id}
                     modaliteId={modaliteId}
                     handleShowOrangeMoney={handleShowOrangeMoney}
                     formation={formation}

                     />
        <Modal show={showSubscribe} onHide={handleCloseSubscribe}>
            <Modal.Header closeButton>
                <Modal.Title>Inscription sur la formation {formation.nom}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                     loading ? 
                     <div  className="border d-flex align-items-center justify-content-center">
                     <RingLoader color="#fdc800" size='140' 
                     loading	={loading}
                    />
             
                     </div>
                     :
                     <Form>
                    <div className="col-md-12">
                        <div className="single-input-inner-custome">
                            <Form.Select
                                aria-label="Sélectionner une session"
                                onChange={handleSelectChange}
                                value={sessionformation.id}
                                name="session"
                            >
                                <option value={0}>Choisir votre espace</option>
                                {formation.sessionFormation && formation.sessionFormation.map((sessionItem, index) => (
                                    <option key={index} value={sessionItem.id}>
                                        {sessionItem.lieu_formation.region}/{sessionItem.lieu_formation.commune}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="single-input-inner-custome">
                            <Form.Select
                                aria-label="Sélectionner une modalité"
                                name="mode"
                                onChange={handleChangeModalite}
                                value={modaliteId}
                            >
                                {modalite.length > 0 ? (
                                    modalite.map((modal, index) => (
                                        <option key={index} value={modal.id}>
                                            {modal.modalite} - {modal.jour} - {modal.heure}
                                        </option>
                                    ))
                                ) : (
                                    <option value={0}>Vous devez choisir un espace de formation</option>
                                )}
                            </Form.Select>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-left">
                                <Button className="single-input-inner btn btn-secondary btn-radius" size="lg" style={buttonStyles} onClick={handleCloseSubscribe}>
                                    Fermer
                                </Button>
                            </div>
                            <div className="col-md-6 text-right">
                                <Button className="btn btn-base btn-radius" size="lg" style={buttonStyles} onClick={handleShowResume} disabled={sessionformation.id === 0}>
                                    S'inscrire
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
                }
                
            </Modal.Body>
        </Modal>
        <Modal show={showResume} onHide={handleCloseResume}>
									<Modal.Header closeButton>
										<Modal.Title>Details sur la formation</Modal.Title>
									</Modal.Header>
									<Modal.Body>
                                        <ul>
                                            <li>Nom formation:{formation.nom}</li>
                                            <li>Coût de la formation:{sessionformation && sessionformation.cout_formation}CFA</li>
                                            <li>Date de debut:{sessionformation && sessionformation.date_debut}</li>
                                            <li>Date de fin:{sessionformation && sessionformation.date_fin}</li>
                                            <li>Lieu de formation:{sessionformation.lieu_formation && <ul>
                                                 <li> {sessionformation.lieu_formation.region && sessionformation.lieu_formation.region}</li>
                                                 <li> {sessionformation.lieu_formation.commune && sessionformation.lieu_formation.commune}</li>
                                                 <li> {sessionformation.lieu_formation.adresse && sessionformation.lieu_formation.adresse}</li>
                                                </ul>}</li>
                                            {sessionformation.paymentMode && <li>mode de paiement
                                                <ul>
                                                    {sessionformation.paymentMode.inscription && <li>Inscription: {sessionformation.paymentMode.inscription} CFA</li>}
                                                    {sessionformation.paymentMode.mensualite && <li>Mensualité: {sessionformation.paymentMode.mensualite} CFA</li>}
                                                    {sessionformation.paymentMode.nombre_moi && <li>Nombre de mois: {sessionformation.paymentMode.nombre_moi}</li>}
                                                </ul>
                                                </li>}
                                        </ul>
                                    {sessionformation && sessionformation.modalites && sessionformation.modalites.map((modalite, indexmodel) => (
                                            <div key={`modal ${indexmodel}`} className="z-depth-5">
                                                <div className="single-list-inner style-check-box">
                                                    <i className="fa fa-calendar" /> {modalite.modalite}
                                                </div>
                                                <div className="single-list-inner style-check-box">
                                                    <i className="fa fa-calendar" /> <span>Jours :</span> {modalite.jour}
                                                </div>
                                                <div className="single-list-inner style-check-box">
                                                    <i className="fa fa-clock-o" /> <span>Heures :</span>  {modalite.heure}
                                                </div>
                                                {ColoredLine('')}
                                            </div>
                                        ))}

									</Modal.Body>
									<Modal.Footer>
										<Button variant="secondary" className="btn btn-secondary btn-radius" onClick={handleCloseResume}>
											Fermer
										</Button>
										<Button className="btn btn-warning btn-radius" 
										       onClick={(e) =>{
												handleCloseResume();
                                                handleCanSave(e);
												   }}
											 >
											Finaliser
										</Button>
									</Modal.Footer>
		</Modal>
         <Modal show={showPaymentChoise} onHide={handleClosePaymentChoise}>
            <Modal.Header closeButton>
                <Modal.Title>Merci de sélectionner votre mode de paiement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Button className="single-input-inner-custome"  size="lg" style={buttonStylesOrange}
                                                                            onClick={(e) =>{
                                                                                handleCloseSubscribe();
                                                                                handleClosePaymentChoise();

                                                                                handleShowOrangeMoney(e)
                                                                                   }}
                              >
                                Orange money
                            </Button>
                        </div>
                        <div className="col-md-12">
                            <Button className="single-input-inner-custome"  size="lg" style={buttonStylesWave} onClick={(e) =>{
												handleClosePaymentChoise();
                                                handleSubscribePayByWave(e)
												   }}>
                                Wave
                            </Button>
                        </div>
                        <div className="col-md-12">
                            <Button className="single-input-inner-custome"  size="lg" style={buttonStylesUlteriement} onClick={(e) =>{
												handleClosePaymentChoise();
                                                handleSubscribePayUlterieure(e)
												   }}>
                               Payer ultérieurement
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

       </>
    );
};

export default InscriptionSession;
