import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useCategorieContext } from "../../context/CategorieContext";
import { inscriptionSession } from "../../services/inscription/service";
import { ClipLoader, RingLoader } from "react-spinners";


const InscriptionModal = (props) => {
  const { handleClose,formation,handleSucess,handleShow} = props;
  //const { lieuformation } = useCategorieContext();

  const [sessionformation, setSessionformation] = useState({id: 0});
  const [modalite,setModalite] = useState({id: 0});
  const [modaliteId,setModaliteId] = useState(0);
  
  let [canSend,setCanSend] = useState(true)

  let [loading, setLoading] = useState(false);

  const [user, setUser] = useState({
    nom: "",
    prenom: "",
    numero_telephone: "",
    email: "",
    correctNom: null,
    correctPhoneNumber: null,
    correctPrenom: null,
    correctEmail: null,
    correctSpace: true
  });
  const phoneNumberRegex = /^(\+221|0)?(77|78|76|70|75)[0-9]{7}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name] : e.target.value,
    });

   
    console.log(`La valeur du ${user} console ${e.target.name} : ${e.target.value}`);

    if (e.target.name === "nom") {
        user.nom = e.target.value
        setUser({
            ...user
          });
       (user.nom.length > 0 && user.nom.length < 2) ?
            setUser({ ...user, correctNom: false }) :
            setUser({ ...user, correctNom: true })
       
    }
    
    if (e.target.name === "prenom") {
        user.prenom = e.target.value
        setUser({
            ...user
          });
       (user.prenom.length > 0 && user.prenom.length < 2) ?
            setUser({ ...user, correctPrenom: false }) :
            setUser({ ...user, correctPrenom: true })
       
    }

  

    if (e.target.name === "numero_telephone") {
        user.numero_telephone = e.target.value
        setUser({
            ...user
          });
        if (phoneNumberRegex.test(user.numero_telephone)) {
            setUser({ ...user, correctPhoneNumber: true });
        } else if (e.target.value.length == 0) {
            setUser({ ...user, correctPhoneNumber: true });
        } else {
            setUser({ ...user, correctPhoneNumber: false })
        }
      }
      if (e.target.name === "email") {
        user.email = e.target.value
        setUser({
            ...user
          });
        if (emailRegex.test(user.email)) {
            setUser({ ...user, correctEmail: true });
        } else if (e.target.value.length == 0) {
            setUser({ ...user, correctEmail: true });
        } else {
            setUser({ ...user, correctEmail: false });
        }
      }


  };

  async function handleIncription(nom,prenom,email,numero_telephone,session,modalite) {
    try {
      setLoading(true)
      const result = await inscriptionSession(nom,prenom,email,numero_telephone,session,modalite);
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }finally {
      setLoading(false); // Set loading to false after API call completes (whether success or failure)
      handleClose()
      handleSucess()
    }
  }

  
  const handleSubmit = () => {
    canSend = true;
    if (user.nom.length < 1) {
      //setUser({ ...user, correctNom: false });
      user.correctNom = false
      canSend = false
      console.log(`nom taille : ${user.nom.length}`);
    }
    if (user.prenom.length < 1) {
      //setUser({ ...user, correctPrenom: false });
      user.correctPrenom = false
      canSend = false
    }
  
    if (!phoneNumberRegex.test(user.numero_telephone)) {
      //setUser({ ...user, correctPhoneNumber: false });
      user.correctPhoneNumber = false
      canSend = false
    }
  
    if (!emailRegex.test(user.email)) {
      //setUser({ ...user, correctEmail: false });
      user.correctEmail = false
      canSend = false

    }
    if(sessionformation.id == 0){
        user.correctSpace = false
        canSend = false
    }
    setCanSend(canSend)
   // canSend = user.nom.length >= 1 && user.prenom.length >= 1 && phoneNumberRegex.test(user.numero_telephone) && emailRegex.test(user.email);
  if (canSend) {
    console.log(`il peut s'inscrire ${canSend}`);

    const response = handleIncription(user.nom,user.prenom,user.email,user.numero_telephone,sessionformation.id,modaliteId)
    
  }else{
    setUser({ ...user})
  }
  };
  
  


  const buttonStyles = {
    width: '100%',
    border: '2px solid rgba(8, 76, 148, 0.2)',
    height: '58px',
    borderRadius: '26px',
    padding: '0 18px',
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    console.log(`la valeur est ${value}`);
    if (value === "0") {
      setSessionformation({id: 0});
      setModalite(0);
    } else {
      const sessionf = formation.sessionFormation.find(session => session.id == value);
      setSessionformation(sessionf)
      console.log(`la session est ${sessionf.date_debut}`);
      setModalite(sessionf.modalites)
      setModaliteId(sessionf.modalites[0].id)
      user.correctSpace = true
        setUser({
            ...user
          });

     
    }
  };

  const handleChangeModalite = (event) => {
    const value = event.target.value;
    setModaliteId(value);
  }
  
  return (

    <>
    {
      loading ? 
        <div  className="border d-flex align-items-center justify-content-center">
        <RingLoader color="#fdc800" size='140' 
        loading	={loading}
       />

        </div>
      :

      <Form>
      <div className="row">
        <div className="col-md-6">
          <div  className={user.correctNom === false ? 'single-input-inner-custome-error' : 'single-input-inner-custome'}>
            <input type="text" name="nom" placeholder="Nom" onChange={handleChange} />
          </div>
        </div>
        <div className="col-md-6">
          <div className={user.correctPrenom === false ? 'single-input-inner-custome-error' : 'single-input-inner-custome'}>
            <input type="text" name="prenom" placeholder="Prenom" onChange={handleChange} />
          </div>
        </div>
        <div className="col-md-6">
          <div  className={user.correctPhoneNumber === false ? 'single-input-inner-custome-error' : 'single-input-inner-custome'}>
            <input type="text" name="numero_telephone" placeholder="Numero telephone" onChange={handleChange} />
          </div>
        </div>
        <div className="col-md-6">
          <div  className={user.correctEmail === false ? 'single-input-inner-custome-error' : 'single-input-inner-custome'}>
            <input type="text" name="email" placeholder="Email" onChange={handleChange} />
          </div>
        </div>

        <div className="col-md-12">
          <div className={user.correctSpace === false ? 'single-input-inner-custome-error' : 'single-input-inner-custome'}>
            <Form.Select
              aria-label="Default select example"
              onChange={handleSelectChange}
              value={sessionformation.id}
              name="session"
            >
              <option value={0}>Choisir votre espace</option>
              { formation.sessionFormation &&
              formation.sessionFormation.map((sessionItem, index) => (
                <option value={sessionItem.id} key={`lieutab${index}`}>
                  {sessionItem.lieu_formation.region}/{sessionItem.lieu_formation.commune}
                </option>
              ))
              }
            </Form.Select>
          </div>
        </div>

        <div className="col-md-12">
          <div className="single-input-inner-custome">
            <Form.Select aria-label="Default select example"
             name="mode"
             onChange={handleChangeModalite}
             value={modaliteId}
             >
              {modalite.length > 0 ? (
                modalite.map((modal, index) => (
                  <option value={modal.id} key={`modalite${index}`}>
                    {modal.modalite}--{modal.jour}--{modal.heure}
                  </option>
                ))
              ) : (
                <option value={0}>Vous devez choisir un espace de formation</option>
              )}
            </Form.Select>
          </div>
        </div>

        

        <div className="container">
        <div className="row">
          <div className="col-md-6 text-left">
          <Button className="single-input-inner btn btn-secondary btn-radius" onClick={handleClose} size="lg" style={buttonStyles}>
              Fermer
            </Button>
          </div>
          <div className="col-md-6 text-right">
          <Button className="btn btn-base btn-radius" onClick={handleSubmit} size="lg" style={buttonStyles}>
            S'inscrire
          </Button>
          </div>
        </div>
      </div>
      </div>
    </Form>
    }
     
    </>
  );
};

export default InscriptionModal;
