import { useParams } from "react-router-dom";
import NavbarAdmin from "./global-components/Navbar-admin";
import PageHeader from "./global-components/page-header";
import FormationSameTypeData from "./section-components/formation-same-type-data";


const FormationTypeFormation = () => {
    const { id } = useParams();

    return (
        <div>
    <NavbarAdmin />
    <PageHeader headertitle="Formations" />
    <FormationSameTypeData id={id} />
    </div>
    )
}

export default FormationTypeFormation;