import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CategorieContext } from '../../context/CategorieContext';


class NavbarAdmin extends Component {
  static contextType = CategorieContext;

  constructor(props) {
    super(props);

    this.toggle1 = this.toggle1.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.state = {
      dropdownOpen1: false,
      isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
    };
  }

   handleLogout = () => {
    // Remove the 'isLoggedIn' item from localStorage
    localStorage.removeItem('isLoggedIn');
    this.setState({isLoggedIn: false})
    window.location.reload()
    // Redirect the user to the login page or perform any other action
    // For example, you can use React Router's history object to navigate to the login page
  };
  
  toggle1() {
    this.setState(prevState => ({
      dropdownOpen1: !prevState.dropdownOpen1
    }));
  }

  onMouseEnter() {
    this.setState({ dropdownOpen1: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen1: false });
  }
    componentDidMount() {

     const $ = window.$;

     $( 'body' ).removeClass( 'home-3' );

   }
   
    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'logo'
        let anchor = '#'
        return (
       
        <nav  className="navbar bg-white navbar-area-1 navbar-area navbar-expand-lg go-top" >
          <div className="container nav-container">
            <div className="responsive-mobile-menu">
              <button className="menu toggle-btn d-block d-lg-none" data-target="#edumint_main_menu" aria-expanded="false" aria-label="Toggle navigation">
                <span className="icon-left" />
                <span className="icon-right" />
              </button>
            </div>
            <div className="logo">
              <Link to="/"><img src={publicUrl+"assets/img/logo.png"} alt="img" /></Link>
            </div>
            <div className="nav-right-part nav-right-part-mobile">
              {this.state.isLoggedIn ?  <button onClick={this.handleLogout}>Se deconnecter</button> :<>
              <Link className="signin-btn" to="/sign-in">Connexion</Link>
              <Link className="btn btn-base" to="/sign-up">Creer  un compte</Link>
              </>}
             
              <a className="search-bar" href="#"><i className="fa fa-search" /></a>
            </div>
            <div className="collapse navbar-collapse" id="edumint_main_menu">
              <ul className="navbar-nav menu-open">
               <li className="menu-item">
                 <Link to="/type-formation">Type de formation</Link>
                 </li>
                {/* <li className="current-menu-item">
                  <Link to="/admin-page">Formations</Link>
                </li> */}
                {/* <li className="menu-item">
                 <Link to="/session-formation">Session Formation</Link>
                 </li> */}
                 <li className="menu-item">
                 <Link to="/citation">Citation</Link>
                 </li>
                 <li className="menu-item">
                 <Link to="/temoignages">Temoignage</Link>
                 </li>
                 <li className="menu-item"><Link to="/contact">Utilisateur</Link></li>
                 <li className="menu-item"><Link to="/evenements">Evenements</Link></li>
              </ul>
            </div>
            <div className="nav-right-part nav-right-part-desktop">
            {this.state.isLoggedIn ?  <button onClick={this.handleLogout} className="btn btn-base">Se deconnecter</button> :<>
              <Link className="signin-btn" to="/sign-in">Connexion</Link>
              <Link className="btn btn-base" to="/sign-up">Creer  un compte</Link>
              </>}
            </div>
          </div>
        </nav>
    

        )
    }
}


export default NavbarAdmin