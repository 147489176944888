import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useCategorieContext } from '../../context/CategorieContext';
import FormationsData from '../global-components/formations-all';

const CourseFilter =  () => {

	  const { typeFormation} = useCategorieContext();
	  const [activeItem,setactIveItem] = useState(0)
	  const [showformation,setactShowformation] = useState(0)


   
        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="course-area  bg-gray">
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-xl-8 col-lg-10 col-md-11">
			        <div className="section-title style-white text-center">
			          {/* <h2 className="title">Nos formations les plus exceptionnelles à votre disposition</h2> */}
			        </div>
			      </div>
			    </div>
			    <div className="edmt-nav-tab style-white text-center">
			      <ul className="nav nav-tabs" id="myTab" role="tablist">
					{
						 typeFormation.map((item,index) => (
							<li className="nav-item " key={`formation${uuidv4()}`}>
							<Link
							  className={`nav-link ${index === activeItem && 'active'}`}
							  
							  
							  to={`/course/${item.id}`}
							  
							  
							  aria-selected={index === 0 ? "true" : "false"}
							  onClick={() => {
								if (index > 0) {
									setactShowformation(2)
								}
								setactIveItem(index)}}
							>
							  {item.nom}
							</Link>
						  </li>
						 ))
					}
			        
			      </ul>
			    </div>
			    <FormationsData />
			  </div>
			</div>
       
}

export default CourseFilter