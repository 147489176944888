import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './sidebar';
import ReactPlayer from 'react-player';
class BlogAbout extends Component {


  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'

    return (
		<div className="blog-area pd-top-120 pd-bottom-120 go-top">
		  <div className="blog-area">
        <div className="container">
          <div className="row justify-content-center">
                   <div className="col-lg-6 col-12">
                   <ReactPlayer
                    ref={this.videoRef}
                    url='https://www.youtube.com/watch?v=0l0yRSzNvy4'  
                    playing={false} // Set playing based on state
                    controls={true} // Show video controls
                    width="100%"
                    //playing={true}
                  />
                   <div className="details">
                      <h5>Présentation de Bakhbadé Academy</h5>
		            </div>
                   </div>
                   <div className="col-lg-6 col-12">
                   <ReactPlayer
                    ref={this.videoRef}
                    url='https://www.youtube.com/watch?v=x-2peCKYzNg'  
                    playing={false} // Set playing based on state
                    controls={true} // Show video controls
                    width="100%"
                    //playing={true}
                  />
                   <div className="details">
                    <h5>Open Day création de site web avec Wordpress et développement Web</h5>
		            </div>
                   </div>

                   <div className="col-lg-6 col-12">
                   <ReactPlayer
                    ref={this.videoRef}
                    url='https://www.youtube.com/watch?v=63U3QqC0OSk'  
                    playing={false} // Set playing based on state
                    controls={true} // Show video controls
                    width="100%"
                    //playing={true}
                  />
                   <div className="details">
                      <p> Lancez-vous dès aujourd'hui dans l'acquisition de nouvelles compétences numériques avec une formation 100% pratique,  à coût réduit grâce à une subvention, encadrée par des experts qualifiés, et obtenez une attestation de réussite à la fin de chaque parcours pour propulser votre carrière vers de nouveaux sommets ! 🌟</p>
		            </div>
                   </div>
                   <div className="col-lg-6 col-12">
                   <ReactPlayer
                    ref={this.videoRef}
                    url='https://www.youtube.com/watch?v=_lCOX95bzLU'  
                    playing={false} // Set playing based on state
                    controls={true} // Show video controls
                    width="100%"
                    //playing={true}
                  />
                   <div className="details">
		              <p>Venez découvrir nos Journées Portes Ouvertes dédiées aux formations, orchestrées par des experts qualifiés dotés d'une solide expérience dans le monde professionnel.</p>
		            </div>
                   </div>
             
          </div>
        </div>
      </div>
		</div>

    )
  }
}

export default BlogAbout;
