import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CategorieContext } from '../../context/CategorieContext';
class LatestNewsV2 extends Component {
    static contextType = CategorieContext;

	state = {
        currentPage: 1,
        itemsPerPage: 9, // Define the number of items per page
    };


  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'
	const apiUrl = process.env.REACT_APP_API_BASE;
	const convertData = (dateTo) => {
		const [year, month, day] = dateTo.split('-');
		const months = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'];
		const formattedDate = `${day} ${months[parseInt(month) - 1]} ${year}`;
		return formattedDate;
	}
	const { currentPage, itemsPerPage } = this.state;
		const { evenementData } = this.context; // Accessing directly from context
		// Calculate the index of the first and last item to display
		if (!evenementData) {
			return null; // Or you can return a loading indicator
		}
	
		const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;

        // Slice the array of items to display only those for the current page
        const displayedItems = evenementData.slice(indexOfFirstItem, indexOfLastItem);

		const renderedItems = displayedItems.map((evenement ,index) => (
			<Link to={`/event-details/${evenement.id}`} key={index}  className="col-lg-4 col-md-6">
					
					<div >
						<div className="single-blog-inner">
						<div className="thumb">
						    <img src={apiUrl+`${evenement.image}`} alt="img" />
							<span className="date"> {evenement.date_debut ? convertData(evenement.date_debut) : 'pas de date'} </span>
						</div>
						<div className="details">
							<ul className="blog-meta">
							{/* <li><i className="fa fa-user" /> BY ADMIN</li>
							<li><i className="fa fa-folder-open-o" /> Air transport</li> */}
							</ul>
							<h5><Link to={`/event-details/${evenement.id}`}>{evenement.titre} </Link></h5>
							<Link className="read-more-text" to={`/event-details/${evenement.id}`}>Voir plus <i className="fa fa-angle-right" /></Link>
						</div>
						</div>
		           </div>
		   </Link>
		))

		 // Pagination logic
		 const pageNumbers = [];
		 for (let i = 1; i <= Math.ceil(evenementData.length / itemsPerPage); i++) {
			 pageNumbers.push(
				 <li key={i} className={currentPage === i ? 'active' : ''}>
					 <Link to="#" onClick={() => this.setState({ currentPage: i })}>
						 {i}
					 </Link>
				 </li>
			 );
		 }

    return (
		<div className="blog-area pd-top-90 pd-bottom-30 go-top">
		  <div className="container">
		    <div className="row justify-content-center">
			<div className="col-xl-6 col-lg-7">
		        <div className="section-title text-center">
		          <h6 className="sub-title double-line">Dernières nouvelles </h6>
		          <h2 className="title">Nos événements</h2>
		        </div>
		      </div>
		    </div>
		    <div className="row">
			{renderedItems && renderedItems}
			<div className="col-12">
			        <nav className="td-page-navigation text-center">
			          <ul className="pagination">

					  <li className="pagination-arrow">
						<Link to="#" onClick={() => this.setState({ currentPage: currentPage - 1 })} disabled={currentPage === 1}>
								<i className="fa fa-angle-double-left" />
						</Link>
					  </li>
						{pageNumbers}
					  <li className="pagination-arrow">
						<Link to="#" onClick={() => this.setState({ currentPage: currentPage + 1 })} disabled={currentPage === Math.ceil(evenementData.length / itemsPerPage)}>
							<i className="fa fa-angle-double-right" />
						</Link>
					  </li>
			          </ul>

			        </nav>
			      </div>
		     
		    </div>
		  </div>
		</div>
    )
  }
}

export default LatestNewsV2;
