import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
    baseURL: apiUrl,
  });
  const authToken = localStorage.getItem('token');

const headers = {
    'Authorization': `Bearer ${authToken}`,
    'Content-Type': 'multipart/form-data'
  };

/**
 * 
 * @returns get all citaions
 */
export const getCitationsService = async ()=>{

    try {
        const response = await apiService.get("/citaions").then(res =>{
            console.log('citations :',res.data);
            return res.data
        })
        return response;
    } catch (error) {
        throw error
    }
}
//add the citations 

export  const addCitationService = async (data) =>{

    try {
        const response = await apiService.post("/citaion/store",data,{headers}).then(res =>{
            console.log('response for adding the: ',res.data);
            return res.data
        })
        return response
    } catch (error) {
        throw error
    }
}

//update the citation

export const updateCiationService = async(data) =>{
    try {
        const response = await apiService.put(`/citaion/update`,data,{headers}).then(res => {
            console.log('update response: ',res.data);
            return res.data
        })
        return response
    } catch (error) {
        throw error
    }
}

//delete the citation

export const deleteCiataionService = async (id) =>{
    try {
        const response = await apiService.delete(`/citaion/delete/${id}`,{headers}).then(res =>{
            console.log('delete response : ',res.data);
            return res.data
        })
        return response
    } catch (error) {
        throw error
    }
}