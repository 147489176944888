import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { CategorieContext } from '../../context/CategorieContext';

class TestimonialV2 extends Component {
	static contextType = CategorieContext;

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="testimonial-area pd-top-110 pd-bottom-120" style={{backgroundImage: 'url('+publicUrl+'assets/img/testimonial/bg.png)', backgroundSize: 'cover', backgroundPosition: 'center'}}>
	  <div className="container">
	    <div className="row justify-content-center">
	      <div className="col-xl-6 col-lg-7 col-md-11">
	        <div className="section-title text-center">
	          <h6 className="sub-title double-line">Témoignages de nos apprenants</h6>
	        </div>
	      </div>
	    </div>
	    <div className="testimonial-slider-2 owl-carousel">
			{this.context.temoignageData && this.context.temoignageData.map((item,index) =>(
				<div className="item" key={`temoignage${index}`}>
				<div className="single-testimonial-inner">
				  <span className="testimonial-quote"><i className="fa fa-quote-right" /></span>
				    {parse(item.temoignage)}
				  <div className="media testimonial-author">
					<div className="media-left">
					  <img src={publicUrl+"assets/img/testimonial/1.png"} alt="img" />
					</div>
					<div className="media-body align-self-center">
					  <h6>{item.nom}</h6>
					  <p>{item.fonction}</p>
					</div>
				  </div>
				</div>
			  </div>
			))}
	      
	    </div>
	  </div>
	</div>

        }
}

export default TestimonialV2