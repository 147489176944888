import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { CategorieProvider } from './context/CategorieContext';
import AppRoutes from './routes';
import reportWebVitals from './reportWebVitals';
import { AdminProvider } from './context/AdminContext';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
        

const root = document.getElementById("bakhbade");

createRoot(root).render(
  <React.StrictMode>
    <CategorieProvider>
      <AdminProvider>
        <PrimeReactProvider>
            <Router>
              <AppRoutes />
            </Router>
        </PrimeReactProvider>
      </AdminProvider>
    </CategorieProvider>
  </React.StrictMode>
);

reportWebVitals();
