import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
    baseURL: apiUrl,
  });
  

  const authToken = localStorage.getItem('token');

  const headers = {
      'Authorization': `Bearer ${authToken}`
    };
  

export const inscriptionSession = async (session,modalite) =>{

    const dataToSend = {
        "session": session,
        "modalite": modalite
    };
    try {
        const response = apiService.post("/user/inscription",dataToSend,{ headers }).then(res =>{
            console.log('Réponse de l\'API :', res.data);
            return res.data.inscription
        })
        return response
    } catch (error) {
        return error.message
    }
}

export const getInscriptionData = async () =>{
    try {
        const response = apiService.get('/user/getinscription',{ headers }).then(res =>{
            console.log(`les inscription sont ${res.data.userSession}`);
            return res.data.userSession
        })
        return response
    } catch (error) {
        console.error('inscription failed:', error.response.data);
    }
}


export const getInscriptionDataBySession = async (id) =>{
    try {
        const response = apiService.get(`/user/getinscription/${id}`,{ headers }).then(res =>{
            console.log(`les inscription sont ${res.data.message}`);
            return res.data.userSession
        })
        return response
    } catch (error) {
        console.error('inscription failed:', error.response.data);
    }
}

export const checkInscriptionSession = async (session,modalite) =>{

    const dataToSend = {
        "session": session,
        "modalite": modalite
    };
    try {
        const response = apiService.post("/user/check/inscription",dataToSend,{ headers }).then(res =>{
            console.log('Réponse de l\'API :', res.data);
            return res.data.message
        })
        return response
    } catch (error) {
        return error.message
    }
}

export const orangeMoneyApp = async (sessionformation,inscription) =>{

    const apiServicekf = axios.create({
        baseURL: 'https://khoulefreres.com/api',
        
      });

      try {
        const response = apiServicekf.get(`/orangeMoney/formation/${sessionformation}/${inscription}`).then(res =>{
            const responseData = JSON.parse(res.data);
            //console.log('Réponse de qrcode service:', responseData.qrCode);
            return responseData
        })
        return response
      } catch (error) {
        
      }
}

//
export const subscribeByAdmin = async (data) =>{
    
 try {
    const response = await apiService.post("/user/inscription/admin",data,{ headers }).then(res =>{
        console.log('inscription by admin',res.data);
        return res.data
    })
   return response
 } catch (error) {
    console.log(error);
    throw error;

 }
}

export const updateSubscribUserSession = async (data) =>{
    try {
        const response = await apiService.post("/user/inscription/update/admin",{userSession: data},{ headers }).then(res =>{
            console.log('Mise a jour inscription by admin',res.data);
            return res.data
        })
       return response
     } catch (error) {
        console.log(error);
        throw error;
    
     }
}

export const deleteSubscribUserSession = async (data) =>{
    console.log(headers);
    try {
        const response = await apiService.delete(`/user/inscription/delete/${data}`,{ headers }).then(res =>{
            console.log('Mise a jour inscription by admin',res.data);
            return res.data
        })
       return response
     } catch (error) {
        console.log(error);
        throw error;
    
     }
}

export const updateStatusSubscribe = async (data) =>{
    try {
        const response = await apiService.post("/user/status/setIncriptionSessionStatus",{id: data}).then(res =>{
            console.log('status update',res.data);
            return res
        })
        return response.data
    } catch (error) {
        console.log(error);
        throw error;
    }
}