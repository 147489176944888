import React, { Component ,CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { CategorieContext } from '../../context/CategorieContext';
import OrangeMoney from './paiement-section/orange-money';
import OrangeMoneyConfirm from './paiement-section/orangemoney-confirmation';

class InscriptionDetails extends Component {
	static contextType = CategorieContext;



	constructor(props) {
		super(props);
		this.state = {
			show: false,
			showHoraire: false,
			selectedSession: null,
			loading: true,
			color: "#ffffff",
			isSuccessfull: false,
			showlogin: false,
			showregister: false,
			showSubscribe: false,
			showSuccessInscription: false,
			showPayment: false,
			isCreatedWave: false,
			showOrangeMoney: false

		};
	  }


    render() {
		let sessionUser = this.props.sessionUser;
        let publicUrl = process.env.PUBLIC_URL+'/'
		const apiUrl = process.env.REACT_APP_API_BASE;


		const handleSubscribePayByWave = ()=>{ this.setState({isCreatedWave: true})}

		const handleShowSubscribe = () => this.setState({showSubscribe: true})
		const handleCloseSubscribe = () => this.setState({showSubscribe: false})

		const handleSucess = () => this.setState({ isSuccessfull: true  });


        const handleCloseHoraireModal = () => this.setState({ showHoraireModal: false });
	    const handleShowHoraireModal = (session) => {
            this.setState({ showHoraireModal: true, selectedSession: session });
        };

		const handleCloseSucessInscription = () => this.setState({ showSuccessInscription: false  });
        const handleShowSucessInscription = () => this.setState({ showSuccessInscription: true  });

		const handleShowPayment = () => this.setState({showPayment: true})
		const handleClosePayment = () => this.setState({showPayment: false})

		const handleCloseOrangeMoney = () => this.setState({ showOrangeMoney: false  });
		const handleShowOrangeMoney = () => this.setState({ showOrangeMoney: true});

		const stylebutton = {
			marginLeft: '5px',
			padding: 'inherit',
			fontSize: '14px',
            height: '26px',
            lineHeight: '27px',
            color: '#212529',
            backgroundColor: '#ffc600',
            borderRadius: '16px',
        
		}

		const stylesessioninformation = {
			fontSize: '14px',
            
        
		}
		const styleSession = {
			marginBottom: '5px' 
		}

		const styleDate ={
			fontFamily: 'monospace',
			fontWeight: 'normal'
		}
        const stylePayNow = {
			fontSize: '16px'

		}
		const ColoredLine = ({ color }) => (
			<hr
				style={{
					color: color,
					backgroundColor: color,
					height: 5
				}}
			/>
		);

		const styleHoraireDetails = {
			height:' 120px',
			width: '100%',
			backgroundColor: '#64b5f6'
		}

		const buttonStyles = {
			width: '100%',
			border: '2px solid rgba(8, 76, 148, 0.2)',
			height: '58px',
			borderRadius: '26px',
			padding: '0 18px',
		  };
		  const convertData = (dateTo) => {
			const [year, month, day] = dateTo.split('-');
			const months = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'];
			const formattedDate = `${day} ${months[parseInt(month) - 1]} ${year}`;
			return formattedDate;
		}

		const convertDay = (dateTo) => {
			const [year, month, day] = dateTo.split('-');
			const formattedDate = day;
			return formattedDate;
		}
		const convertMonth = (dateTo) => {
			const [year, month, day] = dateTo.split('-');
			const months = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'];
			const formattedDate = months[parseInt(month) - 1];
			return formattedDate;
		}

		const convertYear = (dateTo) => {
			const [year, month, day] = dateTo.split('-');
			const formattedDate = year;
			return formattedDate;
		}
        const ulStyle = {
            marginBottom: '0px',
            padding: '0 24px 0px'
        }
        const styleCard = {
            marginBottom: '10px'
        }

		const stylePayButton = {
			background: '#ffc600'
		}

		const stylePay = {
			background: '#32CD32'
		}

		const buttonStylesOrange = {
			width: '100%',
			border: '2px solid rgba(8, 76, 148, 0.2)',
			height: '58px',
			borderRadius: '26px',
			padding: '0 18px',
			background: '#ff7f00',
		};
		const buttonStylesWave = {
			width: '100%',
			border: '2px solid rgba(8, 76, 148, 0.2)',
			height: '58px',
			borderRadius: '26px',
			padding: '0 18px',
		};
		const buttonStylesUlteriement = {
			width: '100%',
			border: '2px solid rgba(8, 76, 148, 0.2)',
			height: '58px',
			borderRadius: '26px',
			padding: '0 18px',
			background:'#fdc800'
		};
		if (this.state.isCreatedWave) {
        
			//return window.location.replace(`https://khoulefreres.com/api/wave/geturlpaiement/35/5/20`);
	
		   return window.location.replace(`https://khoulefreres.com/api/wave/geturlpaiement/${sessionUser.session.id}/${sessionUser.modalite.id}/${sessionUser.id}`);
		}
		return (
			<div className="course-single-area pd-top-120 pd-bottom-90">
				<div className="container">
					<div className="row">
						<div className="col-lg-7">
							<div className="course-course-detaila-inner">
								<div className="thumb">
									<img src={apiUrl + `${sessionUser.session.formation.image_url_detail}`} alt="img" />
								</div>
								<div className="course-details-nav-tab text-center">
									<ul className="nav nav-tabs" id="myTab" role="tablist">
										<li className="nav-item">
											<a className="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">Description</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">Contenu</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" id="tab4-tab" data-toggle="tab" href="#tab4" role="tab" aria-controls="tab4" aria-selected="false">Prérequis</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" id="tab3-tab" data-toggle="tab" href="#tab3" role="tab" aria-controls="tab3" aria-selected="false">FAQ</a>
										</li>
									</ul>
								</div>
								<div className="tab-content" id="myTabContent">
									<div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
										<div className="course-details-content">
											<div dangerouslySetInnerHTML={{ __html: sessionUser.session.formation.description }} />
										</div>
									</div>
									<div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
										<div className="course-details-content">
											<div dangerouslySetInnerHTML={{ __html: sessionUser.session.formation.contenue }} />
										</div>
									</div>
									<div className="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
    <div className="course-details-content">
        <h4 className="title">Overview</h4>
        <p>
            The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog.
            Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz, vex nymphs.
            Waltz, bad nymph, for quick jigs vex! Fox nymphs grab
        </p>
        <div id="accordion-1" className="accordion-area mt-4">
            <div className="card single-faq-inner style-header-bg">
                <div className="card-header" id="ff-five">
                    <h5 className="mb-0">
                        <button className="btn-link" type="button" data-toggle="collapse" data-target="#f-five" aria-expanded="true" aria-controls="f-five">
                            01. What does you simply dummy in do ?
                            <i className="fa fa-eye" />
                        </button>
                    </h5>
                </div>
                <div id="f-five" className="collapse show" aria-labelledby="ff-five" data-parent="#accordion-1">
                    <div className="card-body">
                        What does you dummy text of free available in market printing has industry been industry's standard dummy text ever.
                    </div>
                </div>
            </div>
            <div className="card single-faq-inner style-header-bg">
                <div className="card-header" id="ff-six">
                    <h5 className="mb-0">
                        <button className="btn-link collapsed" type="button" data-toggle="collapse" data-target="#f-six" aria-expanded="true" aria-controls="f-six">
                            02. What graphics dummy of free design ?
                            <i className="fa fa-eye" />
                        </button>
                    </h5>
                </div>
                <div id="f-six" className="collapse" aria-labelledby="ff-six" data-parent="#accordion-1">
                    <div className="card-body">
                        What graphics simply dummy text of free available in market printing industry has been industry's standard dummy text ever.
                    </div>
                </div>
            </div>
            <div className="card single-faq-inner style-header-bg">
                <div className="card-header" id="ff-seven">
                    <h5 className="mb-0">
                        <button className="btn-link collapsed" type="button" data-toggle="collapse" data-target="#f-seven" aria-expanded="true" aria-controls="f-seven">
                            03. Why we are the best ?
                            <i className="fa fa-eye" />
                        </button>
                    </h5>
                </div>
                <div id="f-seven" className="collapse" aria-labelledby="ff-seven" data-parent="#accordion-1">
                    <div className="card-body">
                        Why we are dummy text of free available in market printing industry has been industry's standard dummy text ever.
                    </div>
                </div>
            </div>
            <div className="card single-faq-inner style-header-bg">
                <div className="card-header" id="ff-eight">
                    <h5 className="mb-0">
                        <button className="btn-link collapsed" type="button" data-toggle="collapse" data-target="#f-eight" aria-expanded="true" aria-controls="f-eight">
                            04. What industries dummy covered ?
                            <i className="fa fa-eye" />
                        </button>
                    </h5>
                </div>
                <div id="f-eight" className="collapse" aria-labelledby="ff-eight" data-parent="#accordion-1">
                    <div className="card-body">
                        What industries text of free available in market printing industry has been industry's standard dummy text ever.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

									<div className="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab4-tab">
										<div className="ratings-list-inner mb-4">
											<div dangerouslySetInnerHTML={{ __html: sessionUser.session.formation.prerequis }} />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-5">
							<div className="td-sidebar">
								<div className="widget widget_feature">
									<h4 className="widget-title">Détails sur la formation</h4>
									<div id="accordion-1" className="accordion-area mt-4">
										<div className="card single-faq-inner style-header-bg" style={styleCard}>
											<div className="card-header" id="ff-five-details">
												<h5 className="mb-0">
													<button className="btn-link" type="button" data-toggle="collapse" data-target="#f-five-details" aria-expanded="true" aria-controls="f-five-details">
														Détails sur la session
														<i className="fa fa-eye" />
													</button>
												</h5>
											</div>
											<div id="f-five-details" className="show collapse" aria-labelledby="ff-five-details" data-parent="#accordion-1">
												<div className="card-body">
													<ul>
														<li><i className="fa fa-clock-o" /><span>Durée :</span> {sessionUser.session.formation.duration}</li>
														<li><i className="fa fa-map-marker" /><span>Lieu de formation :</span> {sessionUser.session.lieu_formation.commune && `${sessionUser.session.lieu_formation.commune}/`}{sessionUser.session.lieu_formation.region}</li>
														<li><i className="fa fa-calendar" /><span style={styleDate}>du {convertData(sessionUser.session.date_debut)} au {convertData(sessionUser.session.date_fin)}</span></li>
														<li><i className="fa fa-calendar" /><span>Horaires:</span>
															<ul style={ulStyle}>
																<li><i className="fa fa-calendar-minus-o" />{sessionUser.modalite.modalite}</li>
																<li><i className="fa fa-calendar" />{sessionUser.modalite.jour}</li>
																<li><i className="fa fa-clock-o" />{sessionUser.modalite.heure}</li>
															</ul>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="card single-faq-inner style-header-bg" style={styleCard}>
											<div className="card-header" id="ff-seven-details">
												<h5 className="mb-0">
													<button className="btn-link collapsed" type="button" data-toggle="collapse" data-target="#f-seven-details" aria-expanded="true" aria-controls="f-seven-details">
														Détails sur le mode de paiement
														<i className="fa fa-eye" />
													</button>
												</h5>
											</div>
											<div id="f-seven-details" className="show collapse" aria-labelledby="ff-seven-details" data-parent="#accordion-1">
												<div className="card-body">
													<ul>
														<li><i className="fa fa-money" /><span>Coût total :</span> {sessionUser.session.cout_formation} CFA
															{sessionUser.session.paymentMode && <ul style={ulStyle}>
																<li><i className="fa fa-pencil-square-o" /><span>Inscription :</span> {sessionUser.session.paymentMode.inscription} CFA</li>
																<li><i className="fa fa-calendar-minus-o" /><span>Mensualité :</span> {sessionUser.session.paymentMode.mensualite} CFA</li>
																<li><i className="fa fa-calendar-plus-o" /><span>Nombre de mois :</span> {sessionUser.session.paymentMode.nombre_moi}</li>
															</ul>}
														</li>
														<li><i className="fa fa-tags" /><span>Status:</span> {sessionUser.status === 1 ? <span style={stylePay}>payé</span> : <span >Non payé <Button variant="primary" style={stylebutton} onClick={()=>{handleShowPayment()}}>Payer maintenant <i className="fa fa-money" /></Button></span>}
															{sessionUser.session.paymentMode && (
																<ul style={ulStyle}>
																<li style={stylePayNow}><i className="fa fa-pencil-square-o" /><span>Inscription :</span> {sessionUser.status_incription === 1 ? <span style={stylePay}>payée</span> : <span >Non payé <Button variant="primary" style={stylebutton} onClick={()=>{handleShowPayment()}}> Payer <i className="fa fa-money" /></Button></span>}</li>
																<li><i className="fa fa-calendar-minus-o" /><span>Mensualité :</span> {sessionUser.status_mensualite} mois payés</li>
															</ul>
															)
															}
														</li>
													</ul>
												</div>
											</div>
										</div>
										<Modal show={this.state.showPayment} onHide={handleClosePayment}>
            <Modal.Header closeButton>
                <Modal.Title>Merci de sélectionner votre mode de paiement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Button className="single-input-inner-custome"  size="lg" style={buttonStylesOrange}
                                                                            onClick={(e) =>{
                                                                                handleShowOrangeMoney()

                                                                                
                                                                                   }}
                              >
                                Orange money
                            </Button>
                        </div>
                        <div className="col-md-12">
                            <Button className="single-input-inner-custome"  size="lg" style={buttonStylesWave} onClick={(e) =>{
												
                                                handleSubscribePayByWave(e)
												   }}>
                                Wave
                            </Button>
                        </div>
                        
                    </div>
                </div>
            </Modal.Body>
        </Modal>

		<OrangeMoneyConfirm handleCloseOrangeMoney={handleCloseOrangeMoney}
                     showOrangeMoney={this.state.showOrangeMoney}
                     sessionformation={sessionUser.session.id}
                     modaliteId={sessionUser.modalite.id}
                     handleShowOrangeMoney={handleShowOrangeMoney}
                     formation={sessionUser.session.formation}
					 inscriptionOm={sessionUser}

                     />
										<div className="card single-faq-inner style-header-bg" style={styleCard}>
											<div className="card-header" id="ff-six-details">
												<h5 className="mb-0">
													<button className="btn-link collapsed" type="button" data-toggle="collapse" data-target="#f-six-details" aria-expanded="true" aria-controls="f-six-details">
														Information sur l'étudiant
														<i className="fa fa-eye" />
													</button>
												</h5>
											</div>
											<div id="f-six-details" className="show collapse" aria-labelledby="ff-six-details" data-parent="#accordion-1">
												<div className="card-body">
													<ul>
														<li><i className="fa fa-user-circle-o" aria-hidden="true"/><span>Prénom :</span> {sessionUser.user.prenom}</li>
														<li><i className="fa fa-user" aria-hidden="true" /><span>Nom :</span> {sessionUser.user.nom}</li>
														<li><i className="fa fa-envelope" aria-hidden="true"/><span>Email :</span> {sessionUser.user.email}</li>
														<li><i className="fa fa-phone" aria-hidden="true"/><span>Numéro de téléphone :</span> {sessionUser.user.numero_telephone}</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
		

        }
		  


}

export default InscriptionDetails