import React from 'react';
import Navbar from './global-components/navbar-v4';
import PageHeader from './global-components/page-header';
import Event from './section-components/event-page';
import Footer from './global-components/footer';
import LatestPost from './blog-components/latest-news-v2';


const AboutPage = () => {
    return <div>
        <Navbar />
        {/* <PageHeader headertitle="Event"  /> */}
        {/* <Event /> */}
        <LatestPost />
        <Footer />
    </div>
}

export default AboutPage

