import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar-v4';
import PageHeader from './global-components/page-header';
import EventDetailsPage from './section-components/event-details';
import Footer from './global-components/footer';
import { useParams } from 'react-router-dom';
import { getEvenById } from '../services/evenement/service';
import EventParticipationPage from './section-components/EventParticipationPage';

const EventParticipation = () => {
    const { id } = useParams();
    const [even, setEven] = useState(null); // Initialize even state with null

    useEffect(() => {
        const fetchEvent = async () => {
            try {
                const response = await getEvenById(id);
                setEven(response); // Update even state with fetched event
            } catch (error) {
                console.error('Error fetching event:', error);
            }
        };

        fetchEvent(); // Call fetchEvent function
    }, [id]); // Fetch event when id changes

    return (
        <div>
            <Navbar />
            <PageHeader headertitle={even && 'Participation à '+even.titre} /> {/* Use conditional rendering to prevent errors when even is null */}
            <EventParticipationPage id={id} />
            <Footer />
        </div>
    );
}

export default EventParticipation;
