import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
    baseURL: apiUrl,
  });
  const authToken = localStorage.getItem('token');

const headers = {
    'Authorization': `Bearer ${authToken}`
  };


/**
 * get all fformation type
 */


export const getIncommingSession = ()=>{

    try {
        const response = apiService.get('/sessionformation/incomming').then(res =>{
            console.log(`la liste des formations avenir sont les suivantes ${res.data}`);
            return res.data;
        })
        return response;
    } catch (error) {
        throw error;
    }
}

export const getsessionformationByFormation = (id)=>{

    try {
        const response = apiService.get(`/sessionformation/formation/${id}`).then(res =>{
            console.log(`la liste des formations avenir sont les suivantes ${res.data}`);
            return res.data;
        })
        return response;
    } catch (error) {
        throw error;
    }
}

export const addSession = async (sessionData) => {
    

    console.log("Données avant envoi :", sessionData);

    try {
        const response = await apiService.post('/sessionformation/store', sessionData, { headers });
        console.log('Réponse de la création de session :', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const updateSession = async (sessionData) => {

    try {
        const response  = await apiService.put(`/sessionformation/update/${sessionData.id}`,sessionData,{ headers }).then(res =>{
            console.log('update session: ',res.data);
            return res.data
        })
        return response
    } catch (error) {
        throw error;
    }
}

export const DeleteSessionFormation = async (id ) => {


    try {
        const response = await apiService.delete(`/sessionformation/delete/${id}`, {
            headers: {
                'Authorization': `Bearer ${authToken}`,
            }
        }).then( res =>{
            console.log(`session supprimer : ${res.data}`);
            return res.data
        });
        
        return response.formation;
    } catch (error) {
        throw error;
    }

}
