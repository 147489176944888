import NavbarAdmin from "./global-components/Navbar-admin";
import PageHeader from "./global-components/page-header";

import FormationComponent from "./section-components/formations";
        

const Admin_Page = () => {


    return (
        <div>
    <NavbarAdmin />
    <PageHeader headertitle="Formations" />
    <FormationComponent />
    </div>
    )
}

export default Admin_Page;