import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar-v4';
import PageHeader from './global-components/page-header';
import CourseDetails from './section-components/course-details';
import Footer from './global-components/footer';
import { useParams } from 'react-router-dom';
import { formation } from '../services/formation/service';

const AboutPage = (props) => {
  const { id } = useParams();
  const [fromation, setFormation] = useState(null);

  async function fetchFormation() {
    try {
      const result = await formation(id);
      console.log(result);
      setFormation(result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // useEffect block
  useEffect(() => {
    fetchFormation();
  }, [id]); // Dependency array with 'id' means it will re-run when 'id' changes

  useEffect(() => {
    console.log(`the data of the formation is: ${JSON.stringify(fromation)}`);
  }, [fromation]); // Dependency array with 'selectyppe' means it will re-run when 'selectyppe' changes

  return (
    <div>
      <Navbar />
      {fromation ? (
        <>
          <PageHeader headertitle={`${fromation.nom}`} />
          <CourseDetails formation={fromation} />
        </>
      ) : (
        <h1>DATA IS LOADING ....</h1>
      )}
      <Footer />
    </div>
  );
};

export default AboutPage;
