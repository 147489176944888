import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
    baseURL: apiUrl,
  });


/***
 * login
 */

export const login = async (email, password) =>{

    try {
        const response = apiService.post("/login",{"email": email,"password": password})
                                .then(res =>{
                                    console.log(`success login ${res.data.token}`);
                                    return res.data
                                })
        return response;
    } catch (error) {
        console.error('Login failed:', error.response.data);
    }
}

export const register = async (nom,prenom,email,numero_telephone,password) =>{

    try {
        const response = apiService.post("/register",{"nom": nom,"prenom": prenom,"email": email,"numero_telephone":numero_telephone,"password": password})
                                   .then(res =>{
                                    console.log(`response for registering ${res.data}`)
                                    return res.data
                                   })
        return response;
    } catch (error) {
        console.error('Login failed:', error.response.data);
    }

}

export const verifyEmail = async (email) =>{

    try {

        const response = apiService.post("/verifyEmail",{"email": email})
        .then(res =>{
         console.log(`response for verifyEmail ${res.data}`)
         return res.data
        })
        return response;
        
    } catch (error) {
        return error.message
    }
}

export const verifyResetToken  = async (token)=>{
    try {
        const response = apiService.post(`verifyResetToken/${token}`).then(res =>{
            return res.data
        })
        return response
    } catch (error) {
        return error.message
    }
}

export const resetPassword = async (token, password) => {
    try {
        const response = await apiService.post(`resetPassword/${token}`, { password });
        console.log('resetPassword:', response.data);
        return response.data;
    } catch (error) {
        console.error('Password reset failed:', error.response.data);
        throw error.response.data;
    }
}