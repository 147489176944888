import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { register } from '../../services/login/service';

const RegistrerInscription = (props) => {
    const { handleCloseRegister, showregister, handleCloseLogin, handleShowLogin, handleShowSubscribe } = props;
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [telephone, setTelephone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isCorrect, setIsCorrect] = useState({ correctPassword: true, correctEmail: true, correctNom: true, correctPrenom: true, correctTelephone: true });

    const handleNomChange = (e) => {
        const value = e.target.value;
        setNom(value);
        setIsCorrect({ ...isCorrect, correctNom: value.length >= 2 });
    };

    const handlePrenomChange = (e) => {
        const value = e.target.value;
        setPrenom(value);
        setIsCorrect({ ...isCorrect, correctPrenom: value.length >= 2 });
    };

    const handleTelephoneChange = (e) => {
        const value = e.target.value;
        setTelephone(value);
        setIsCorrect({ ...isCorrect, correctTelephone: /^(\+221|0)?(77|78|76|70|75)[0-9]{7}$/.test(value) });
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        setIsCorrect({ ...isCorrect, correctEmail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) });
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
        setIsCorrect({ ...isCorrect, correctPassword: value.length >= 8 });
    };

    const handleLogin = async () => {
        try {
            const result = await register(nom, prenom, email, telephone, password);
            const token = result.token;
            localStorage.setItem('token', token);
            localStorage.setItem('isLoggedIn', 'true');
            setIsCorrect({ correctEmail: true, correctPassword: true });
            handleCloseRegister();
            handleShowSubscribe();
        } catch (error) {
            console.error('Erreur de connexion:', error);
            setIsCorrect({ correctEmail: false, correctPassword: false });
        }
    };

    const buttonStyles = {
        width: '100%',
        border: '2px solid rgba(8, 76, 148, 0.2)',
        height: '58px',
        borderRadius: '26px',
        padding: '0 18px',
    };

    const styleLink = {
        color: '#FDC800',
    };

    return (
        <Modal show={showregister} onHide={handleCloseRegister}>
            <Modal.Header closeButton>
                <Modal.Title>Inscrivez-vous et développez de nouvelles compétences.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicNom" className={isCorrect.correctNom ? 'single-input-inner-custome' : 'single-input-inner-custome-error'}>
                        <Form.Label>Nom</Form.Label>
                        <Form.Control type="text" placeholder="Entrez votre nom" onChange={handleNomChange} />
                        {!isCorrect.correctNom &&
                            <Form.Text className="text-muted">
                                Le nom doit comporter au moins deux caractères.
                            </Form.Text>}
                    </Form.Group>

                    <Form.Group controlId="formBasicPrenom" className={isCorrect.correctPrenom ? 'single-input-inner-custome' : 'single-input-inner-custome-error'}>
                        <Form.Label>Prénom</Form.Label>
                        <Form.Control type="text" placeholder="Entrez votre prénom" onChange={handlePrenomChange} />
                        {!isCorrect.correctPrenom &&
                            <Form.Text className="text-muted">
                                Le prénom doit comporter au moins deux caractères.
                            </Form.Text>}
                    </Form.Group>

                    <Form.Group controlId="formBasicTelephone" className={isCorrect.correctTelephone ? 'single-input-inner-custome' : 'single-input-inner-custome-error'}>
                        <Form.Label>Numéro de téléphone</Form.Label>
                        <Form.Control type="text" placeholder="Entrez votre numéro de téléphone" onChange={handleTelephoneChange} />
                        {!isCorrect.correctTelephone &&
                            <Form.Text className="text-muted">
                                Veuillez saisir un numéro de téléphone valide.
                            </Form.Text>}
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail" className={isCorrect.correctEmail ? 'single-input-inner-custome' : 'single-input-inner-custome-error'}>
                        <Form.Label>Adresse Email</Form.Label>
                        <Form.Control type="email" placeholder="Entrez votre email" onChange={handleEmailChange} />
                        {!isCorrect.correctEmail &&
                            <Form.Text className="text-muted">
                                Veuillez saisir une adresse e-mail valide.
                            </Form.Text>}
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword" className={isCorrect.correctPassword ? 'single-input-inner-custome' : 'single-input-inner-custome-error'}>
                        <Form.Label>Mot de passe</Form.Label>
                        <Form.Control type="password" placeholder="Mot de passe" onChange={handlePasswordChange} />
                        {!isCorrect.correctPassword &&
                            <Form.Text className="text-muted">
                                Le mot de passe doit comporter au moins huit caractères.
                            </Form.Text>}
                    </Form.Group>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-left">
                                <Button className="single-input-inner btn btn-secondary btn-radius" onClick={handleCloseRegister} size="lg" style={buttonStyles}>
                                    Fermer
                                </Button>
                            </div>
                            <div className="col-md-6 text-right">
                                <Button className="btn btn-base btn-radius" onClick={handleLogin} size="lg" style={buttonStyles}>
                                    S'inscrire
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div>
                                    <span>Vous avez déjà un compte ?</span>
                                    <a className="ml-1" href="#" onClick={() => { handleCloseRegister(); handleShowLogin(); }}><strong style={styleLink}>Se connecter</strong></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default RegistrerInscription;
