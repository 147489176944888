import React, { useState, useRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useCategorieContext } from "../../../../context/CategorieContext";
import { addFormation, formations } from "../../../../services/formation/service";
import { RingLoader } from "react-spinners";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getInscriptionDataBySession, subscribeByAdmin } from "../../../../services/inscription/service";

const AddUserSessionModal = (props) => {
  const {
    adUserSession,
    handleCloseAddUserSession,
    sessionSelected,
    formation,
    id,
    modalites,
    setRecord,
    setModalites

  } = props;

  const {
    formationDatas,
    setFormationDatas,
    lieuformation,
  } = useCategorieContext();
  const [loading, setLoading] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const [sessionUser, setSessionUser] = useState({
    nom: "",
    prenom: "",
    email: "",
    session: id,
    modalite: modalites ? modalites[0]?.id : "",
    numero_telephone: "",
    status: "",
    niveau: "",
    date_naissance: null,
    source: "",
    lieu_formation_id: "",
    modalites: [],
    correctNom: true,
    correctPrenom: true,
    correctTelephone: true,
    correctEmail: true,
  });
  const [isCreated, setIsCreated] = useState(false);
  const [isError, setIsError] = useState({ value: false, message: "" });
  const [sessionformation, setSessionFormation] = useState({ id: 0 });
  const [modalite, setModalite] = useState([]);
  const [modaliteId, setModaliteId] = useState(0);

  const handleChange = (e) => {
    // Mettre à jour les valeurs du formulaire
    setSessionUser({
      ...sessionUser,
      [e.target.name]: e.target.value,
    });
    updateIsCorrectState();
  };

  const handleTelephoneChange = (e) => {
    const value = e.target.value;
    setSessionUser({
      ...sessionUser,
      [e.target.name]: value,
      correctTelephone: /^(\+221|0)?(77|78|76|70|75)[0-9]{7}$/.test(value),
    });
    updateIsCorrectState();
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setSessionUser({
      ...sessionUser,
      [e.target.name]: value,
      correctEmail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
    });
    updateIsCorrectState();
  };

  // const handleSelectChange = (e) => {
  //   const value = parseInt(e.target.value, 10);
  //   if (value === 0) {
  //       setIsDisabled(true)
  //   }else{
  //     setIsDisabled(false)
  //   }
  //   const sessionf =
  //   formation.sessionFormation.find((session) => session.id === value) || {
  //     id: 0,
  //     modalites: [],
  //   };
  // console.log("sessionf : ", sessionf);
  // setSessionUser((prevSessionUser) => ({
  //   ...prevSessionUser,
  //   session: value,
  //   modalite: sessionf.modalites.length > 0 ? sessionf.modalites[0].id : 0,
  // }));
  // setSessionFormation(sessionf);
  // setModalite(sessionf.modalites);
  // setModaliteId(sessionf.modalites.length > 0 ? sessionf.modalites[0].id : 0);

  //     console.log(sessionUser);

  //   updateIsCorrectState();
  // };

  const handleChangeModalite = (e) => {
    const value = parseInt(e.target.value, 10);
    setModaliteId(value);
    setSessionUser({
      ...sessionUser,
      modalite: value,
    });
    updateIsCorrectState();
  };

  const buttonStyles = {
    width: "100%",
    border: "2px solid rgba(8, 76, 148, 0.2)",
    height: "58px",
    borderRadius: "26px",
    padding: "0 18px",
  };

  const showSuccessAlert = () => {
    handleCloseAddUserSession();
    Swal.fire({
      icon: "success",
      title: "inscription a été faite avec succès !",
      showConfirmButton: false,
      timer: 5000,
    });
    setIsCreated(false);
  };

  const showErrorAlert = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: isError.message,
      showConfirmButton: false,
      timer: 5000,
    });
    setIsError({ ...isError, value: false });
  };
  const handleAddInscription = async () => {
    try {
      setLoading(true);
      if (sessionUser.modalite === "") {
        sessionUser.modalite = modalites[0].id
      }
      const response = await subscribeByAdmin(sessionUser);
      const responseIns = await getInscriptionDataBySession(id)
             setRecord(responseIns.sessionFormationUsers)
             setModalites(responseIns.modalites)
      if (response) {
        setIsCreated(true);
      }
    } catch (error) {
        console.log('error: ',error.response.data);
      setIsError({
        value: true,
        message: `${error.response.data}`,
      });
    } finally {
      setLoading(false);
      setIsCorrect(false);
    }
  };

  const updateIsCorrectState = () => {
    const isCorrectvalu =
      sessionUser.nom.length > 1 &&
      sessionUser.prenom.length > 1 &&
      /^(\+221|0)?(77|78|76|70|75)[0-9]{7}$/.test(sessionUser.numero_telephone) &&
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(sessionUser.email);
      setIsDisabled(!isCorrectvalu);
  };

  return (
    <>
    { isCreated &&  showSuccessAlert()}
       {isError.value && showErrorAlert()}
      <Modal show={adUserSession} onHide={handleCloseAddUserSession} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Inscription à la formation </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="border d-flex align-items-center justify-content-center">
              <RingLoader color="#fdc800" size="140" loading={loading} />
            </div>
          ) : (
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Nom</Form.Label>
                    <div className={sessionUser.correctNom ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                      <input type="text" name="nom" onChange={handleChange} className="form-control" />
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Prenom</Form.Label>
                    <div className={sessionUser.correctPrenom ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                      <input type="text" name="prenom" onChange={handleChange} className="form-control" />
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <div className={sessionUser.correctEmail ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                      <input type="email" name="email" onChange={handleEmailChange} className="form-control" />
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Téléphone</Form.Label>
                    <div className={sessionUser.correctTelephone ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                      <input type="tel" name="numero_telephone" onChange={handleTelephoneChange} className="form-control" />
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Status</Form.Label>
                    <div className={sessionUser.correctNom ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                      <input type="text" name="status" onChange={handleChange} className="form-control" />
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Niveau d'etude</Form.Label>
                    <div className={sessionUser.correctPrenom ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                      <input type="text" name="niveau" onChange={handleChange} className="form-control" />
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>date naissance</Form.Label>
                    <div className={sessionUser.correctNom ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                      <input type="date" name="date_naissance" onChange={handleChange} className="form-control" />
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Source d'information ?</Form.Label>
                    <div className={sessionUser.correctPrenom ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                      <input type="text" name="source" onChange={handleChange} className="form-control" />
                    </div>
                  </Form.Group>
                </div>
                {/* <div className="col-md-12">
                  <Form.Group>
                    <Form.Label>Formation</Form.Label>
                    <div className="single-input-inner-custome">
                      <Form.Select
                        aria-label="Sélectionner une session"
                        onChange={handleSelectChange}
                        value={sessionformation.id}
                        name="session"
                      >
                         <option value={0}>Choisir votre espace</option>
                        
                        {formation &&
                          formation.sessionFormation.map((sessionItem, index) => (
                            <option key={index} value={sessionItem.id}>
                              {sessionItem.lieu_formation.region}/{sessionItem.lieu_formation.commune}
                            </option>
                          ))}
                      </Form.Select>
                    </div>
                  </Form.Group>
                </div> */}
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Label>Plage Horaire</Form.Label>
                    <div className="single-input-inner-custome">
                      <Form.Select
                        aria-label="Sélectionner une modalité"
                        name="mode"
                        onChange={handleChangeModalite}
                        
                      >
                        {modalites && modalites.length > 0 ? (
                          modalites.map((modal, index) => (
                            <option key={index} value={modal.id}>
                              {modal.modalite} - {modal.jour} - {modal.heure}
                            </option>
                          ))
                        ) : (
                          <option value={0}>Vous devez choisir un espace de formation</option>
                        )}
                      </Form.Select>
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-6 text-left">
                    <Button className="single-input-inner btn btn-secondary btn-radius" size="lg" style={buttonStyles} onClick={handleCloseAddUserSession}>
                      Fermer
                    </Button>
                  </div>
                  <div className="col-md-6 text-right">
                    <Button className="btn btn-base btn-radius" size="lg" style={buttonStyles} onClick={handleAddInscription} disabled={isDisabled}>
                      Inscrire
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddUserSessionModal;
