import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { RingLoader } from "react-spinners";
import { useCategorieContext } from '../../../../context/CategorieContext';
import { addSession, getsessionformationByFormation } from '../../../../services/session/service';
import Swal from 'sweetalert2';
import { useAdminContext } from '../../../../context/AdminContext';
import { addEvenementService, deleteArticle, getEvenements, updateEven } from '../../../../services/evenement/service';
import { formation } from '../../../../services/formation/service';


const UpdateEven = (props) => {
    const { handleCloseUpdateEven, showUpdateEven,id,even,setEven,setRecord } = props;
    const { incommingSession } = useAdminContext();
    const {evenementData,setEvenementData} = useCategorieContext();
    const [imageUrl,setImageUrl] = useState(null)
    const [imageUrlDetail,setImageUrlDetail] = useState(null)
    const [imageUrlArticle,setImageUrlArticle] = useState([])
    const [imageDetailName, setImageDetailName] = useState("");
    
    const [loading, setLoading] = useState(false);
    const [evenement, setEvenement] = useState({
        date_debut: "",
        date_fin: "",
        titre: "",
        lieu: "",
        heure: "",
        description: "",
        longitude: "",
        latittude: "",
        nombre_participant: "",
        image: "",
        image_details: "",
        articles: [{}],
        correctdatDebut: true,
        correctDateFin: true,
        CorrectCoutFormation: true,
        correctFormatiom: true,
        correctLieuFormation: true
    });
    const [isCreated, setIsCreated] = useState(false);
    const [isError, setIsError] = useState({value:false,message:""});

    const currentDate = new Date().toISOString().split('T')[0]; // Obtient la date actuelle au format "YYYY-MM-DD"
    const [dynamicFields, setDynamicFields] = useState([]);
    const [previousDynamicFieldValues, setPreviousDynamicFieldValues] = useState([{}]);
     // Nouvel état pour stocker les valeurs des champs dynamiques précédents
    const [canAdd, setCanAdd] = useState(true);
    const [imageName, setImageName] = useState("");
    const styleBlock = {
        border: '1px solid #ffc107',
        paddingTop: '13px' 
    }

    const handleAddHoraireChange = (index, e) => {
       // e.preventDefault()
        const { name } = e.target;
        const updatedArticles = [...even.articles]; // Create a copy of the articles array
        updatedArticles[index].content = e.target.files[0]; // Update the content of the article at the specified index
    
        setEven((prevEven) => ({
            ...prevEven,
            articles: updatedArticles, // Update the articles state with the modified array
        }));
        console.log('even articles: ',even.articles);
        // If you want to display the image preview, you can update the imageUrlArticle state as well
        const updatedImageUrlArticle = [...imageUrlArticle];
        updatedImageUrlArticle[index] = URL.createObjectURL(e.target.files[0]);
        setImageUrlArticle(updatedImageUrlArticle);
    };
    
    useEffect(() => {
        //setPreviousDynamicFieldValues([even.articles])
        // console.log('Previous Dynamic Field Values:', previousDynamicFieldValues);
        // const canAdd = previousDynamicFieldValues.every(field => field.titre && field.content);
        // setCanAdd(canAdd);
        console.log('even: ',even);
    }, [even]);

    const handleAddField = () => {
        setPreviousDynamicFieldValues([...previousDynamicFieldValues, { titre: '', content: '' ,id: ''}]);

        let newField = {
            titre: '', 
            content: '' ,
            id: ''
        }
        setEven({
            ...even,
            articles: [...even.articles,newField]
        })
    };

    const handleSelectChange = (e) => {
        // Mettre à jour les valeurs du formulaire
        setEvenement({
            ...evenement,
            [e.target.name]: e.target.value
        });
    };

    const handleChange = (e) =>{
        // Mettre à jour les valeurs du formulaire
        setEvenement({
            ...evenement,
            [e.target.name]: e.target.value
        });

        setEven({
            ...even,
            [e.target.name]: e.target.value
        })

    }

    const handleAddArticle = (index, e) => {
        const { name, value } = e.target;
        const updatedValues = [...previousDynamicFieldValues];
        updatedValues[index][name] = value;
        setPreviousDynamicFieldValues(updatedValues);
    };
    

    const fetchSessionByFormation = async (id) =>{

        try {
             const response = await getsessionformationByFormation(id)
             setRecord(response)
        } catch (error) {
            
        }
    }

    const showSuccessAlert =  () => {
        handleCloseUpdateEven()
        Swal.fire({
          icon: "success",
          title: "La session a été créée avec succès !",
          showConfirmButton: false,
          timer: 5000
        });
        setIsCreated(false)
        setPreviousDynamicFieldValues([
            {
                modalite: '',
                jour: '',
                heure: ''
            }
        ])

        

      };

      const showErrorAlert = () =>{
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: isError.message,
          showConfirmButton: false,
          timer: 5000
        });
        setIsError({...isError,value:false})
      }
    

    const handleAddSession = async (e) => {
        e.preventDefault()
        try {

            
            
            setLoading(true);
            const formData = new FormData();
            formData.append('id', even.id);
            formData.append('date_debut', even.date_debut);
            formData.append('date_fin', even.date_fin);
            formData.append('titre', even.titre);
            formData.append('longitude', even.longitude);
            formData.append('latittude', even.latittude);
            formData.append('lieu', even.lieu);
            formData.append('heure', even.heure);
            formData.append('description', even.description);
            formData.append('nombre_participant', even.nombre_participant);
            if (even.image instanceof File) {
                formData.append('image', even.image);

            } 
            if (even.image_details instanceof File) {
                formData.append('image_details', even.image_details);
            } 
            console.log('Previous Dynamic Field Values:', previousDynamicFieldValues);
            //formData.append(`articles`, JSON.stringify(even.articles));
            
            even.articles.forEach((article, index) => {
                if (article.content instanceof File) {
                    // Append the file
                    formData.append(`articles[${index}].content`, article.content);
            
                    // Append the article ID as a separate field
                    formData.append(`articles[${index}].id`, article.id); 

                    console.log('formatData: ',formData);
                } 
            });
            
    
            console.log("Data before sending formData:", even);
    
            const response = await updateEven(even);
            
            
            // console.log("Données avant envoi formData:", even);
            // const response = await updateEven(even);
            setIsCreated(true)

        } catch (error) {
            console.error('Erreur lors de l\'inscription à la session :', error);
            setIsError({value: true,message: `Erreur lors de l'envoi des données de formation : ${error}`})

            // Gérer les erreurs
        } finally {
            setLoading(false); 
            setPreviousDynamicFieldValues([
                {
                    modalite: '',
                    jour: '',
                    heure: ''
                }
            ])
            handleCloseUpdateEven();
            const evenementResponse = await getEvenements();
            setEvenementData(evenementResponse);
        }
    };

    const buttonStyles = {
        width: '100%',
        border: '2px solid rgba(8, 76, 148, 0.2)',
        height: '58px',
        borderRadius: '26px',
        padding: '0 18px',
    };

    const addStyles = {
        borderRadius: '26px',
        padding: '0 3px',
        marginBottom: '9px',
    };

    const fileInputRef = useRef(null);
  const fileInputDetailsRef = useRef(null);
  const fileInputArticleRef = useRef([]);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };
  
  const handleFileDetailButtonClick = () => {
    fileInputDetailsRef.current.click();
  };

  const handleFileArticleButtonClick = (index) => {
    fileInputArticleRef.current[index].current.click();
    //fileInputArticleRef.current.click();
  };

  const handleFileChange = (e) => {
    setImageName(e.target.files[0].name);

    setEvenement({
        ...evenement,
        image: e.target.files[0]
    });
   setEven({
    ...even,
    image: e.target.files[0],
    imaged: e.target.files[0]
   })
    setImageUrl(URL.createObjectURL(e.target.files[0]))
  };
  
  const handleFileDetailChange = (e) => {
   
    setImageDetailName(e.target.files[0].name);
    setEvenement({
        ...evenement,
        imageDetails: e.target.files[0],
        imageDetailsd: e.target.files[0]
    });

    setEven({
        ...even,
        image_details: e.target.files[0]
       });
   
    setImageUrlDetail(URL.createObjectURL(e.target.files[0]))

  };
  const convertDate = (dates)=>{
    const [day, month, year] = dates.split('-');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
}

const apiUrl = process.env.REACT_APP_API_BASE;

if (even.articles && even.articles.length > 0) {
    fileInputArticleRef.current = even.articles.map(
        (ref, index) =>   fileInputArticleRef.current[index] = React.createRef()
    );
}

const deleteButton =  {
    /* width: 100%; */
    border: '2px solid rgba(8, 76, 148, 0.2)',
    height: '39px',
    borderRadius: '26px',
    padding: '1px 2px 45px 0px',
    backgroundColor: 'crimson',
    color: 'aliceblue',
    margin: '1px 0px 2px',
}

const handleFileArticleButtonClic = async (index,id) => {
    if (id) {
    const response = await deleteArticle(id)
        
    }
    setEven((prevEven) => ({
        ...prevEven,
        articles: prevEven.articles.filter((_, i) => i !== index), // Remove the article at the specified index
    }));
};

    return (
       <>
       { isCreated &&  showSuccessAlert()}
       {isError.value && showErrorAlert()}
        <Modal show={showUpdateEven} onHide={handleCloseUpdateEven}  backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Modification: {even && even.titre} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="border d-flex align-items-center justify-content-center">
                        <RingLoader color="#fdc800" size='140' loading={loading} />
                    </div>
                ) : (
                    <Form>
                    <div className="row">
                    <div className="col-md-6">
                        <Form.Group>
                            <Form.Label>Titre</Form.Label>
                            <div className="single-input-inner-custome">
                                <input type="text" name="titre" onChange={handleChange} className="form-control" value={even && even.titre}/>
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group>
                            <Form.Label>Nombre de participant</Form.Label>
                            <div className="single-input-inner-custome">
                                <input type="number" name="nombre_participant" onChange={handleChange} className="form-control"  value={even && even.nombre_participant}/>
                            </div>
                        </Form.Group>
                    </div>

                    <div className="col-md-6">
                        <Form.Group>
                            <Form.Label>Lieu</Form.Label>
                            <div className="single-input-inner-custome">
                                <input type="text" name="lieu" onChange={handleChange} className="form-control" value={even && even.lieu}/>
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group>
                            <Form.Label>Heure de debut</Form.Label>
                            <div className="single-input-inner-custome">
                                <input type="time" name="heure" onChange={handleChange} className="form-control" value={even && even.heure} />
                            </div>
                        </Form.Group>
                    </div>

                    <div className="col-md-6">
                        <Form.Group>
                            <Form.Label>latittude</Form.Label>
                            <div className="single-input-inner-custome">
                                <input type="text" name="latittude" onChange={handleChange} className="form-control" value={even && even.latittude}  />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group>
                            <Form.Label>longitude</Form.Label>
                            <div className="single-input-inner-custome">
                                <input type="text" name="longitude" onChange={handleChange} className="form-control" value={even && even.longitude} />
                            </div>
                        </Form.Group>
                    </div>

                    <div className="col-md-12">
                        <Form.Group>
                            <Form.Label>Description</Form.Label>
                            <div className="single-input-inner-custome">
                               <textarea placeholder="Description"  name="description" onChange={handleChange} value={even && even.description} />
                            </div>
                        </Form.Group>
                    </div>

                        <div className="col-md-6">
                            <Form.Group>
                                <Form.Label>Date de début</Form.Label>
                                <div className={evenement.correctdatDebut ? "single-input-inner-custome" : "single-input-inner-custome-error"} >
                                    <input type="date" name="date_debut" onChange={handleChange} className="form-control" value={even.date_debut && (even.date_debut)}/>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group>
                                <Form.Label>Date de fin</Form.Label>
                                <div className={evenement.correctDateFin ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                                    <input type="date" name="date_fin" onChange={handleChange} className="form-control" value={even && even.date_fin}/>
                                </div>
                            </Form.Group>
                        </div>

                        <div className="col-md-6">
                            <div className={"single-input-inner-custome"}>
                                <Button className="btn btn-base btn-radius" onClick={handleFileButtonClick} style={buttonStyles}>Choisir image 370X200</Button>
                                <input type="file" name="image" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
                                {imageName ? <img src={imageUrl} alt="img" />: <img src={apiUrl+`${even.image}`} alt="img" />}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={"single-input-inner-custome"}>
                                <Button className="btn btn-base btn-radius" onClick={handleFileDetailButtonClick} style={buttonStyles}>Choisir image 770X500</Button>
                                <input type="file" name="image_details" ref={fileInputDetailsRef} style={{ display: "none" }} onChange={handleFileDetailChange} />
                                {imageDetailName ? <img src={imageUrlDetail} alt="img" /> : <img src={apiUrl+`${even.image_details}`} alt="img" />}
                            </div>
                        </div>
                       
                    </div>
                    {
                   even.articles && even.articles.map((input, index) => (
                         (
                            <div key={index} className="row" style={styleBlock}>
                            {/* <div className="col-md-12">
                                       <Form.Group>
                                           <div className={evenement.correctDateFin ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
           
                                           <Form.Label>Titre</Form.Label>
                                            <div className={evenement.correctdatDebut ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                                               <input type="text" name="titre" onChange={(e) => handleAddArticle(index, e)} className="form-control" />
                                            </div>
                                           </div>
                                       </Form.Group>
                                   </div> */}
                                   <div className="col-md-12">
                                        <div className={"single-input-inner-custome"}>
                                            <Button className="btn btn-base btn-radius" onClick={() => handleFileArticleButtonClick(index)} style={buttonStyles}>Choisir image 370X270</Button>
                                            <input type="file" name="content" ref={fileInputArticleRef.current[index]} style={{ display: "none" }} onChange={e => handleAddHoraireChange(index, e)} />
                                            {imageUrlArticle[index] ? <> <Button className="btn btn-base btn-radius" size="xs" style={deleteButton} onClick={() => handleFileArticleButtonClic(index,input.id)} >supprimer</Button> <img src={imageUrlArticle[index]} alt="img" /> </> : input.content && <><Button className="btn btn-base btn-radius" size="xs" style={deleteButton} onClick={() => handleFileArticleButtonClic(index,input.id)}>supprimer</Button> <img src={apiUrl+`${input.content}`} alt="img" /></>}
                                        </div>
                                       {/* <Form.Group>
                                           <Form.Label>Contenu de l'article</Form.Label>
                                           <div className={evenement.correctdatDebut ? "single-input-inner-custome" : "single-input-inner-custome-error"}>
                                               <input type="file" name="content" onChange={e => handleAddHoraireChange(index, e)} className="form-control" />
                                           </div>
                                       </Form.Group> */}
                                   </div>
                                  
                             </div>  
                        )
                         
                        ))}
                         <div className="col-md-12">
                        <Button className="btn btn-base btn-radius" size="xs" onClick={handleAddField} style={addStyles}>Ajouter</Button> Ajouter une nouvelle plage d'horaire
                        </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-left">
                                <Button className="single-input-inner btn btn-secondary btn-radius" size="lg" style={buttonStyles} onClick={handleCloseUpdateEven}>
                                    Fermer
                                </Button>
                            </div>
                            <div className="col-md-6 text-right">
                                <Button className="btn btn-base btn-radius" size="lg" style={buttonStyles} onClick={handleAddSession}>
                                  Modifier
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
                
                )}
            </Modal.Body>
        </Modal>
       </>
    );
};

export default UpdateEven;
