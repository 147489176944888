import React, { Component } from 'react';
//import parse from 'html-react-parser';
import { getEvenById, participation } from '../../services/evenement/service';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import Team from './team-v2';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';


class EventParticipationPage extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			evenData:{},
			center: {},
			showPayment: false,
			isAnonymous: true,
			payByOrangeMoney: false,
			payByWave: false,
			participation: '',
			subscribe: {
				nom: '',
				prenom: '',
				email: '',
				telephone: '',
				status: '',
				correctNom: true, 
				correctPrenom: true, 
				correctStatus: true, 
				correctEmail: true, 
				correctTelephone: true 
			}
			
		};
	  }
	  handleCheckboxChange = (event) => {
		this.setState({ isAnonymous: event.target.name === 'anonymous' });
	  };
     
	  async componentDidMount(){
		try {
			const response = await getEvenById(this.props.id);
			this.setState({
				 evenData: response ,
				 center : {
					//14.682203, -17.447907   14.952660932955471, -16.812738099814116  
					lat: parseFloat(response.latittude),
					lng: parseFloat(response.longitude)
				}
				});
		} catch (error) {
			console.error('Error fetching event data:', error);
		}
	  }
	

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
		
		const handleParticipatePayByWave = ()=>{ this.setState({payByWave: true})}
		const handleParticipatePayByOrangeMoney = ()=>{ this.setState({payByOrangeMoney: true})}
		const {position} = this.state
		const { center } = this.state;
		const buttonStyles = {
			width: '100%',
			border: '2px solid rgba(8, 76, 148, 0.2)',
			height: '58px',
			borderRadius: '26px',
			padding: '0 18px',
		};
		const convertData = (dateTo) => {
			const [year, month, day] = dateTo.split('-');
			const months = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'];
			const formattedDate = `${day} ${months[parseInt(month) - 1]} ${year}`;
			return formattedDate;
		}
        const apiUrl = process.env.REACT_APP_API_BASE;

		const mapStyles = {
			height: '400px',
			width: '100%'
		};
	

		
		const googleMapsApiKey = "AIzaSyBnEQeOmOm6N4mi5SEk6_Kk1AyCUa12WnA";

		const styleImage = {
			with: '130px'
		}
		

		const handleOnChange = (e) => {
			const { name, value } = e.target;
			let correctValue = false;
			if (name === 'nom' || name === 'status') {
				correctValue = value.length >= 2;
			} else if (name === 'email') {
				correctValue = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
			} else if (name === 'telephone') {
				correctValue = /^(\+221|0)?(77|78|76|70|75)[0-9]{7}$/.test(value);
			}
			this.setState({
				subscribe: {
					...this.state.subscribe,
					[name]: value,
					[`correct${name.charAt(0).toUpperCase() + name.slice(1)}`]: correctValue
				}
			});
		}

		const handleShowPayment = () => this.setState({showPayment: true})
		const handleClosePayment = () => this.setState({showPayment: false})


		const buttonStylesOrange = {
			width: '100%',
			border: '2px solid rgba(8, 76, 148, 0.2)',
			height: '58px',
			borderRadius: '26px',
			padding: '0 18px',
			background: '#ff7f00',
		};
		const buttonStylesWave = {
			width: '100%',
			border: '2px solid rgba(8, 76, 148, 0.2)',
			height: '58px',
			borderRadius: '26px',
			padding: '0 18px',
		};

		const handleSubscribe = async (e) =>{
			if (this.state.subscribe.correctEmail && this.state.subscribe.correctTelephone && this.state.subscribe.correctNom && this.state.subscribe.correctStatus && this.state.subscribe.email.length > 0 && this.state.subscribe.telephone.length > 0 && this.state.subscribe.nom.length > 0 && this.state.subscribe.status.length > 0) {
				try {
					const response = await participation(this.props.id,this.state.subscribe)
					  this.setState({
						participation: response.id,
						subscribe: {
							nom: '',
							prenom: '',
							email: '',
							telephone: '',
							status: '',
							correctNom: true,
							correctPrenom: true,
							correctStatus: true,
							correctEmail: true,
							correctTelephone: true
						}
					});
					if (response) {
						
						handleShowPayment()
					}

					document.querySelectorAll('input').forEach(input => input.value = '');

				} catch (error) {

					
					Swal.fire({
						icon: "warning",
						title: error.response.data,
						showConfirmButton: false,
						timer: 4000
					  });

				}

				
			} else {
				
			}
			
		}


		if (this.state.payByOrangeMoney) {
        
			//return window.location.replace(`https://khoulefreres.com/api/wave/geturlpaiement/35/5/20`);
	
		   return window.location.replace(`https://khoulefreres.com/orangeMoney/participation`);
		}

		if (this.state.payByWave) {
        
			//return window.location.replace(`https://khoulefreres.com/api/wave/geturlpaiement/35/5/20`);

		if (this.state.participation != '') {
			return window.location.replace(`https://khoulefreres.com/wave/participant/${this.state.participation}`);
		} else {
			return window.location.replace(`https://khoulefreres.com/wave/participant/`);
			
		}
	
		}


		const buttonContribution = {
			    /* width: 109%; */
				border: '-11px solid rgba(8, 76, 148, 0.2)',
				height: '43px',
				borderRadius: '26px',
				padding: '0px 12px'
		  };

		  const styleBlock = {
			border: '1px solid #ffc107',
			paddingTop: '13px',
			margin: '3px 3px 8px 3px' 
		}

		const { isAnonymous } = this.state;

    return  <div className="course-single-area pd-top-120 pd-bottom-110">
		  <div className="container">
		    <div className="row">

            <div className="col-lg-8 order-lg-1">
		        <div className="td-sidebar">
		          <div className="widget widget_event">
		            <h4 className="widget-title text-white">Participer à l'evenement</h4> 
					<p>
					   Pour garantir le bon déroulement de notre événement, votre participation sera d'une grande importance.
					</p>
					
					<div className="widget widget_checkbox_list">
			            <h4 className="widget-title text-white">Choisissez : Anonyme ou en Renseignant Vos Informations</h4> 
			            <label className="single-checkbox text-white">
			              <input type="checkbox"  checked={isAnonymous}
							name='anonymous'
							onChange={this.handleCheckboxChange} />
			              <span className="checkmark" />
			              Anonyme
			            </label> 
			            <label className="single-checkbox text-white">
			              <input type="checkbox" checked={!isAnonymous}
              name='notAnonymous'
              onChange={this.handleCheckboxChange} />
			              <span className="checkmark" />
			              Renseignez Vos Informations
			            </label> 
			          </div> 

					  {isAnonymous ? (<div className="row" style={styleBlock}>
					<p className="text-white">
					Participez à Notre Événement : Anonymement 
					</p>
						<div className= "single-input-inner-custome col-md-6">
						<Button className="single-input-inner-custome"  size="lg" style={buttonStylesWave}  onClick={()=>{  handleParticipatePayByWave()}}>
                                Wave
                            </Button>
						</div>
						<div className="single-input-inner-custome col-md-6">
						<Button className="single-input-inner-custome"  size="lg" style={buttonStylesOrange}
                                   onClick={()=>{handleParticipatePayByOrangeMoney()}}                                          
                              >
                                Orange money
                            </Button>
						</div>
					</div>): (<form  style={styleBlock}>
					<p className="text-white">
					   Participez à Notre Événement : Renseignez Vos Informations 
					</p>
		            <div className= "single-input-inner-custome style-right-icon">
		              <input type="text" placeholder="prenom" name='prenom' onChange={handleOnChange}  required/>
		              <img src={publicUrl+"assets/img/icon/25.png"} alt="img" />
		            </div>
					<div className={this.state.subscribe.correctNom ? "single-input-inner-custome style-right-icon" : "single-input-inner-custome-error style-right-icon"}>
		              <input type="text" placeholder="nom" name='nom'  onChange={handleOnChange}/>
		              <img src={publicUrl+"assets/img/icon/25.png"} alt="img" />
		            </div>
					<div className={this.state.subscribe.correctStatus ? "single-input-inner-custome style-right-icon" : "single-input-inner-custome-error style-right-icon"}>
		              <input type="text" placeholder="votre status" name='status' onChange={handleOnChange} required/>
		              <img src={publicUrl+"assets/img/icon/29.png"} alt="img" />
		            </div>
		            <div className={this.state.subscribe.correctEmail ? "single-input-inner-custome style-right-icon" : "single-input-inner-custome-error style-right-icon"}>
		              <input type="email" placeholder="Email Address"  name='email' onChange={handleOnChange} required/>
		              <img src={publicUrl+"assets/img/icon/26.png"} alt="img" />
		            </div>
					<div className={this.state.subscribe.correctTelephone ? "single-input-inner-custome style-right-icon" : "single-input-inner-custome-error style-right-icon"}>
		              <input type="text" placeholder="telephone" name='telephone'  onChange={handleOnChange} required/>
		              <img src={publicUrl+"assets/img/icon/28.png"} alt="img" />
		            </div>
		            
		            <Button className="btn btn-base" onClick={handleSubscribe} style={buttonStyles}>Contribuez </Button>
					</form>)}
					
					
		          </div>
		        </div>
		      </div>
		      <div className="col-lg-4 order-lg-12">
		        <div className="event-detaila-inner">
		          <div className="thumb mb-4">
		            <img src={apiUrl+`${this.state.evenData.image_details}`} alt="img" />
		          </div>
		          <ul className="event-meta">
		            <li><i className="fa fa-clock-o" /> {this.state.evenData.date_debut && convertData(this.state.evenData.date_debut)}  a {this.state.evenData.date_fin && convertData(this.state.evenData.date_fin)} </li>
		            <li><i className="fa fa-map-marker" />{this.state.evenData.lieu} </li>
		          </ul>
		         
				 
		        </div>
				
				    
				   
			  </div>
		     <Modal show={this.state.showPayment} onHide={handleClosePayment}>
            <Modal.Header closeButton>
                <Modal.Title>Merci de sélectionner votre mode de paiement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Button className="single-input-inner-custome"  size="lg" style={buttonStylesOrange}
                                   onClick={()=>{handleParticipatePayByOrangeMoney()}}                                          
                              >
                                Orange money
                            </Button>
                        </div>
                        <div className="col-md-12">
                            <Button className="single-input-inner-custome"  size="lg" style={buttonStylesWave}  onClick={()=>{  handleParticipatePayByWave()}}>
                                Wave
                            </Button>
                        </div>
                        
                    </div>
                </div>
            </Modal.Body>
        </Modal>
		      
			  
		    </div>
		  </div>
		</div>

        }
}

export default EventParticipationPage