import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Modal } from 'react-bootstrap';
import { useAdminContext } from '../../../context/AdminContext';
import FormationAddModal from '../formation-add-flow';
import { getInscriptionDataBySession } from '../../../services/inscription/service';
import AddEvenement from './modal/add-evenement';
import { useCategorieContext } from '../../../context/CategorieContext';
import UpdateEven from './modal/update-even';
import { Link } from 'react-router-dom';

const EvenementData = (props) => {
    const {id} = props
    const {evenementData,setEvenementData} = useCategorieContext();
    const [record, setRecord] = useState([]);
    const [even,setEven] = useState({});
    const [showUpdateEven, setShowUpdateEven] = useState(false);

    const fetchInscriptionBySession = async (id) =>{

        try {
             const response = await getInscriptionDataBySession(id)
             setRecord(response)
        } catch (error) {
            
        }
    }

   

    useEffect(() => {
         setRecord(evenementData);
    }, [evenementData]);

    const handleDeleteEven = (id) =>{

    }

    const handleUpdateEven = (evenSeleted) =>{

        setEven(evenSeleted);

        handleShowUpdateEven();

    }
    const colorEdit = {
        color: '#fdc800',
      }

    const columns = [
       
        {
            name: 'titre',
            selector: row => row.titre,
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, titre) => {
                return { textAlign: "center" };   // removed partial line here
              },

        },
        {
            name: 'description',
            selector: row => row.description,
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, description) => {
                return { textAlign: "center" };   // removed partial line here
              },
        },
        {
            name: 'dateDebut',
            selector: row => row.date_debut,
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, date_debut) => {
                return { textAlign: "center" };   // removed partial line here
              },
        },
        {
            name: 'date Fin',
            selector: row => row.date_fin,
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, date_fin) => {
                return { textAlign: "center" };   // removed partial line here
              },
        },
        {
            name: 'nombre max Participant',
            selector: row => row.nombre_participant,
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, nombre_participant) => {
                return { textAlign: "center" };   // removed partial line here
              },
        }
        ,
        {
            name: 'nombre Participant',
            selector: row =>(
                <Link to={`/even-participant/${row.id}`} style={colorEdit}>
                { row.participants.length}
            </Link>
            ),
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, nombre_participant) => {
                return { textAlign: "center" };   // removed partial line here
              },
        }
        ,
        {
            name: 'Actions', // Title of the column
            cell: row => (
                <>
                    <i className="fa fa-edit" title='modifier' style={colorEdit} onClick={() => handleUpdateEven(row)}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <i className="fa fa-trash" title='supprimer' style={colorDrop} onClick={() => handleDeleteEven(row.id)}/>
                </>
            ),
            allowOverflow: true,
            button: true
        },
    ];

    const handleFilter = (e) => {
        const newData = evenementData.filter(row => {
            return row.titre.toLowerCase().includes(e.target.value.toLowerCase());
        });
        setRecord(newData);
    };


    const searchStyles = {
        border: '2px solid',
        height: '45px',
        borderRadius: '26px',
      };
      const stylePadding_top = {
        paddingTop: '37px',
      }
      const [showAddEvenement,setShowAddEvenement] = useState(false)
      const colorDrop = {
        color: 'red',
      }

      const hancleCloseAddEvenement = () => {setShowAddEvenement(false)};
	  const handleShowAddEvenement = () => setShowAddEvenement(true);

      const handleShowUpdateEven = () => setShowUpdateEven(true);
      const handleCloseUpdateEven = () => setShowUpdateEven(false);


    return (
        <div className="blog-area pd-bottom-120 go-top">
            <div className="container">
                <div className="row" style={stylePadding_top}>
                <div className="col-md-6">
                                <div className="single-input-inner">
                                <Button className="btn btn-secondary btn-radius"  size="lg" onClick={handleShowAddEvenement}>
                                    ajourer
                                </Button>
                                <AddEvenement showAddEvenement={showAddEvenement}
                                              hancleCloseAddEvenement={hancleCloseAddEvenement}
                                />
                                <UpdateEven   showUpdateEven={showUpdateEven}
                                              handleCloseUpdateEven={handleCloseUpdateEven}
                                              even={even}
                                              setEven={setEven}
                                />
                               
                                </div>
                            </div>
                            <div className="col-md-6 d-grid gap-2 d-flex justify-content-end">

                            <input type='text'   placeholder='     rechercher' onChange={handleFilter} style={searchStyles}/>

                            
                            </div>
                    <div className="col-lg-12">
                        <div className="single-blog-inner style-border">
                           
                            <div style={{ overflowX: 'auto' }}>
                            <DataTable
                                columns={columns} 
                                data={record}
                                pagination
                               
                                
                            />
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default EvenementData