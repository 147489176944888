import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar-v4';
import PageHeader from './global-components/page-header';
import CourseDetails from './section-components/course-details';
import Footer from './global-components/footer';
import { useParams } from 'react-router-dom';
import { getInscriptionUser } from '../services/user/service';
import InscriptionDetails from './section-components/inscription-details';

const InscriptionPage = (props) =>{
    const { id } = useParams();
    const [sessionUser,setSessionUser] = useState('')

    async function fetchUserSession() {
        try {
          const result = await getInscriptionUser(id);
          console.log(result);
          setSessionUser(result);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }

      // useEffect block
  useEffect(() => {
    fetchUserSession();
    console.log(id);
  }, [id]); // Dependency array with 'id' means it will re-run when 'id' changes
  useEffect(() => {
    console.log(`the data of the sessionUser is: ${JSON.stringify(sessionUser)}`);
  }, [sessionUser]); // Dependency array with 'selectyppe' means it will re-run when 'selectyppe' changes


  return (
    <div>
      <Navbar />
      {sessionUser ? (
        <>
          <PageHeader headertitle={`${sessionUser.session.formation.nom}`} />
          <InscriptionDetails sessionUser={sessionUser} />
        </>
      ) : (
        <h1>DATA IS LOADING ....</h1>
      )}
      <Footer />
    </div>
  );
}

export default InscriptionPage;