import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useCategorieContext } from '../../context/CategorieContext';
import { Button, Modal } from 'react-bootstrap';
import FormationAddModal from './formation-add-flow';
import { DeleteFormation, formations } from '../../services/formation/service';
import { Link } from 'react-router-dom';
import UpdateFormation from './administration/modal/update-formation';
import Swal from 'sweetalert2';

export default function Formations() {
    const { formationDatas,setFormationDatas } = useCategorieContext();
    const [record, setRecord] = useState([]);
    const [showUpdateFormation,setShowUpdateFormation] = useState(false)
    const [selectedFormation,setSelectedFormation] = useState(undefined)

    const handleCloseUpdateFormation = () =>{
        setShowUpdateFormation(false)
    }

    const handleShowUpdateFormation = () =>{
        setShowUpdateFormation(true)
    }
    const [formation, setFormation] = useState({
        id: "" ,
        nom:  "",
        description: "",
        duration: "",
        general_objectif: "",
        specifique_objectif: "",
        image_url: "",
        image_url_detail:  "",
        type_formation_id: "",
        type_formation: "",
        cout_min: "",
        prerequis: "",
        contenue: "",
        ordre: "",
        correctNom: true,
        correctDuration: true,
        correctCoutMin: true,
        correctImageUrl: true,
        correctImageUrlDetail: true,
        correctTypeFormationId: true,
      });
      
    useEffect(() => {
        setRecord(formationDatas);
    }, [formationDatas]);

    const handleUpdateFormation = async () => {
        // Logique pour mettre à jour les données de formation
        // Suppose que les nouvelles données sont stockées dans newFormationData
        // Passer les nouvelles données à la fonction de mise à jour du contexte
        const formationData = await formations();
        setFormationDatas(formationData);
      };

     

    const handleDeleteConfirmation = (id) =>{
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
          });
        swalWithBootstrapButtons.fire({
            title: "Êtes-vous sûr(e) ?",
            text: "Vous ne pourrez pas revenir en arrière !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui, supprimez !",
            cancelButtonText: "Non, annulez !",
            confirmButtonColor: "#fdc800",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                // Gérer la logique de suppression ici
                handleDeleteFormation(id);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire({
                    title: "Annulé",
                    text: "Votre fichier imaginaire est en sécurité :)",
                    icon: "error"
                });
            }
        });
      
    }

    const handleDeleteFormation = async (id) => {
        try {
            // Effectuer la logique de suppression ici, comme appeler un service deleteFormation
            console.log(`Suppression de la formation avec l'ID ${id}`);
            // Une fois la suppression réussie, mettez à jour les données
            const updatedFormationData = await DeleteFormation(id);
            const formationData = await formations();
            setFormationDatas(formationData);
            // Facultatif : afficher un message de réussite
            Swal.fire({
                icon: "success",
                title: "Formation supprimée avec succès !",
                showConfirmButton: false,
                timer: 2000
            });
        } catch (error) {
            console.error("Erreur lors de la suppression de la formation :", error);
            // Afficher un message d'erreur si la suppression échoue
            Swal.fire({
                icon: "error",
                title: "Oups...",
                text: "Une erreur s'est produite lors de la suppression de la formation.",
                showConfirmButton: false,
                timer: 3000
            });
        }
    };

    const handleUpdate = (formationss) =>{
        
        setFormation({
            id:  formationss.id ,
            nom: formationss.nom,
            description: formationss.description,
            duration: formationss.duration,
            general_objectif: formationss.specifique_objectif,
            specifique_objectif: formationss.specifique_objectif,
            image_url: formationss.image_url,
            image_url_detail: formationss.image_url_detail,
            type_formation_id: formationss.type_formation_id,
            type_formation: formationss.type_formation,
            mode_paiement: formationss.mode_paiement,
            cout_min: formationss.cout_min,
            prerequis: formationss.prerequis,
            contenue: formationss.contenue,
            ordre: formationss.ordre,
            correctNom: true,
            correctDuration: true,
            correctCoutMin: true,
            correctImageUrl: true,
            correctImageUrlDetail: true,
            correctTypeFormationId: true,
          })
        handleShowUpdateFormation()
    }

    
 

    const linkColor = {
        color: '#fdc800',
    }

    const columns = [
       
        {
            name: 'nom',
            selector: row => row.nom,
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, nom) => {
                return { textAlign: "center" };   // removed partial line here
              },

        },
        {
            name: 'description',
            selector: row => row.description,
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, description) => {
                return { textAlign: "center" };   // removed partial line here
              },
        },
        {
            name: 'cout_min',
            selector: row => row.cout_min,
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, cout_min) => {
                return { textAlign: "center" };   // removed partial line here
              },
        },
        {
            name: 'duration',
            selector: row => row.duration,
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, duration) => {
                return { textAlign: "center" };   // removed partial line here
              },
        },
       {
            name: 'Nombre de sessions',
            cell: row => (
                <Link to={`/session-formation/${row.id}`} style={linkColor}>
                    {row.sessionFormation.length}
                </Link>
            ),
            sortable: true,
            maxWidth: "270px",
            headerStyle: { textAlign: "center"}
        },
        {
            name: 'Ordre affichage',
            selector: row => (
                <b style={linkColor}>{row.ordre}</b>
            ),
            sortable: true,
            maxWidth: "270px" ,
            headerStyle: (selector, ordre) => {
                return { textAlign: "center",
             };   // removed partial line here
              },
        },
        {
            name: 'Actions', // Title of the column
            cell: row => (
                <>
                <i className="fa fa-edit" title='modifier' style={colorEdit} onClick={() => handleUpdate(row)}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <i className="fa fa-edit" title='modifier' style={colorEdit} onClick={() => handleUpdate(row)}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <i className="fa fa-trash" title='supprimer' style={colorDrop} onClick={() => handleDeleteConfirmation(row.id)}/>
                </>
            ),
            allowOverflow: true,
            button: true
        },
    ];

    const handleFilter = (e) => {
        const newData = formationDatas.filter(row => {
            return row.nom.toLowerCase().includes(e.target.value.toLowerCase());
        });
        setRecord(newData);
    };


    const searchStyles = {
        border: '2px solid',
        height: '45px',
        borderRadius: '26px',
      };
      const stylePadding_top = {
        paddingTop: '37px',
      }
      const [show,setShow] = useState(false)
      const colorDrop = {
        color: 'red',
      }

      const colorEdit = {
        color: '#fdc800',
      }

      const handleClose = () => {setShow(false)};
	  const handleShow = () => setShow(true);


    return (
        <div className="blog-area pd-bottom-120 go-top">
            <div className="container">
                <div className="row" style={stylePadding_top}>
                <div className="col-md-6">
                                <div className="single-input-inner">
                                <Button className="btn btn-secondary btn-radius"  size="lg" onClick={handleShow}>
                                    ajourer
                                </Button>
                                <Modal show={show} onHide={handleClose} handleUpdateFormation={handleUpdateFormation}  backdrop="static" >
									<Modal.Header closeButton>
									<Modal.Title>Ajouter une nouvelle formation</Modal.Title>
									</Modal.Header>
									<Modal.Body>
									   <FormationAddModal handleClose={handleClose} handleShow={handleShow} />
									</Modal.Body>
								</Modal>
                                <UpdateFormation   
                                                    formation={formation} 
                                                    setFormation={setFormation}
                                                    handleCloseUpdateFormation={handleCloseUpdateFormation}
                                                    showUpdateFormation={showUpdateFormation}
                                                    />
                                </div>
                            </div>
                            <div className="col-md-6 d-grid gap-2 d-flex justify-content-end">

                            <input type='text'   placeholder='     rechercher' onChange={handleFilter} style={searchStyles}/>

                            
                            </div>
                    <div className="col-lg-12">
                        <div className="single-blog-inner style-border">
                           
                            <div style={{ overflowX: 'auto' }}>
                            <DataTable
                                columns={columns} 
                                data={record}
                                pagination
                                
                            />
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
