import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
    baseURL: apiUrl,
  });
  const authToken = localStorage.getItem('token');

const headers = {
    'Authorization': `Bearer ${authToken}`,
    'Content-Type': 'multipart/form-data'
  };

/**
 * 
 * @returns get all citaions
 */
export const getTemoignagesService = async ()=>{

    try {
        const response = await apiService.get("/temoignages").then(res =>{
            console.log('temoignages :',res.data);
            return res.data
        })
        return response;
    } catch (error) {
        throw error
    }
}
//add the citations 

export  const addTemoignageService = async (data) =>{

    try {
        const response = await apiService.post("/temoignage/store",data,{headers}).then(res =>{
            console.log('response for adding the temoignage/store: ',res.data);
            return res.data
        })
        return response
    } catch (error) {
        throw error
    }
}

//update the citation

export const updateTemoignageService = async(data) =>{
    try {
        const response = await apiService.put(`/temoignage/update`,data,{headers}).then(res => {
            console.log('update response temoignage/update : ',res.data);
            return res.data
        })
        return response
    } catch (error) {
        throw error
    }
}

//delete the citation

export const deleteTemoignageService = async (id) =>{
    try {
        const response = await apiService.delete(`/temoignage/delete/${id}`,{headers}).then(res =>{
            console.log('delete response temoignage/delete/ : ',res.data);
            return res.data
        })
        return response
    } catch (error) {
        throw error
    }
}