import React from 'react'
import NavbarAdmin from './global-components/Navbar-admin'
import PageHeader from './global-components/page-header'
import TemoignageData from './section-components/temoignage-data'

const  Temoignages =  ()=> {
  return (
    <div>
        <NavbarAdmin />
        <PageHeader headertitle="Temoignages" />
        <TemoignageData />
    </div>
  )
}

export default Temoignages