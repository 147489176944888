import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
    baseURL: apiUrl,
  });

const authToken = localStorage.getItem('token');

const headers = {
    'Authorization': `Bearer ${authToken}`,
    'Content-Type': 'multipart/form-data'
  };
  
export const formations = async () =>{
    try {
        const reponse = await apiService.get('/formations')
                                        .then(res =>{
                                            console.log(res.data);
                                            return res.data;
                                        })
        return reponse;
    } catch (error) {
        throw error;
    }
}
/**
 * retourn un formation avec les sessions de formations et le type de formation correspondant 
 * @param {*} id 
 * @returns 
 */
export const formation = async (id) => {
    try {
        const response = await apiService.get(`/formation/${id}`)
                                   .then(res => {
                                    console.log(`formation est ${res.data}`);
                                    return res.data;
                                   })
        return response;
    } catch (error) {
        throw error;
    }
}

/**
 * get formation by the session id
 */
export const formationSession = async (id) => {
    try {
        const response = await apiService.get(`/formation/session/${id}`)
                                   .then(res => {
                                    console.log(`formation est ${res.data}`);
                                    return res.data;
                                   })
        return response;
    } catch (error) {
        throw error;
    }
}

/**
 * retourn un formation avec les sessions de formations et le type de formation correspondant 
 * @param {*} id 
 * @returns 
 */
export const addFormation = async (formationData) => {
    const formData = new FormData();
    formData.append('nom', formationData.nom);
    formData.append('description', formationData.description);
    formData.append('duration', formationData.duration);
    formData.append('general_objectif', formationData.general_objectif);
    formData.append('specifique_objectif', formationData.specifique_objectif);
    formData.append('image_url', formationData.image_url);
    formData.append('image_url_detail', formationData.image_url_detail);
    formData.append('type_formation_id', formationData.type_formation_id);
    formData.append('cout_min', formationData.cout_min);

    try {
        const response = await apiService.post(`/formation/store`, formData, {
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'multipart/form-data' 
            }
        });
        console.log(`Formation ajoutée : ${response.data}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const updateFormation = async (formation ) => {

    const formData = new FormData();
    formData.append('id', formation.id);
    formData.append('nom', formation.nom);
    formData.append('description', formation.description);
    formData.append('duration', formation.duration);
    formData.append('general_objectif', formation.general_objectif);
    formData.append('specifique_objectif', formation.specifique_objectif);
    formData.append('image_url', formation.image_url);
    formData.append('image_url_detail', formation.image_url_detail);
    formData.append('type_formation_id', formation.type_formation_id);
    formData.append('cout_min', formation.cout_min);
    console.log("Valeur de formation dans handleSubmit :", formation);

    try {
        const response = await apiService.put(`/formation/update/${formation.id}`,formation, {
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'multipart/form-data' 
            }
        }).then( res =>{
            console.log(`Formation modifier : ${res.data}`);
            return res.data
        });
        
        return response.formation;
    } catch (error) {
        throw error;
    }

}






export const DeleteFormation = async (id ) => {


    try {
        const response = await apiService.delete(`/formation/delete/${id}`, {
            headers: {
                'Authorization': `Bearer ${authToken}`,
            }
        }).then( res =>{
            console.log(`Formation modifier : ${res.data}`);
            return res.data
        });
        
        return response.formation;
    } catch (error) {
        throw error;
    }

}

//type formation

export const getFormationByTypeformation = async (id) =>{
    try {
        const response = apiService.get(`formation/typeformation/${id}`).then(res =>{
            console.log('response type de formation: ',res.data);
            return res.data
        })
        return response
    } catch (error) {
        throw error;
    }
}




export const hideFormationService = async (id) => {
    

    try {
        const response = await apiService.post(`formation/hidden/hide/${id}`, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        });
        console.log(`Formation ajoutée : ${response.data}`);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const showFormationService = async (id) => {
    

    try {
        const response = await apiService.post(`/formation/show/${id}`, {headers});
        console.log(`Formation ajoutée : ${response.data}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};