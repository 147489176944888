import React, { Component } from "react";
import ReactPlayer from "react-player";

class BlogPresentation extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.state = {
      videoPlayed: false
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const video = this.videoRef.current;
    if (video && !this.state.videoPlayed) {
      this.setState({ videoPlayed: true });
    }
  };

  render() {
    return (
      <div className="blog-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-12">
            <ReactPlayer
            ref={this.videoRef}
            url='https://www.youtube.com/watch?v=63U3QqC0OSk'  
            playing={false} // Set playing based on state
            controls={true} // Show video controls
            width="100%"
            //playing={true}
            />
            <div className="details">
                <p> Lancez-vous dès aujourd'hui dans l'acquisition de nouvelles compétences numériques avec une formation 100% pratique,  à coût réduit grâce à une subvention, encadrée par des experts qualifiés, et obtenez une attestation de réussite à la fin de chaque parcours pour propulser votre carrière vers de nouveaux sommets ! 🌟</p>
            </div>
            </div>
            <div className="col-lg-6 col-12">
            <ReactPlayer
            ref={this.videoRef}
            url='https://www.youtube.com/watch?v=_lCOX95bzLU'  
            playing={false} // Set playing based on state
            controls={true} // Show video controls
            width="100%"
            //playing={true}
            />
            <div className="details">
                <p>Venez découvrir nos Journées Portes Ouvertes dédiées aux formations, orchestrées par des experts qualifiés dotés d'une solide expérience dans le monde professionnel.</p>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BlogPresentation;
