import React, { createContext, useContext, useState, useEffect } from 'react';
import { typeFormationData } from '../services/typeformation/service';
import { lieuFormation } from '../services/lieuformation/service';
import { formations } from '../services/formation/service';
import { getEvenements } from '../services/evenement/service';
import { getCitationsService } from '../services/citation/service';
import { getTemoignagesService } from '../services/temoignage/service';

export const CategorieContext = createContext(undefined);

export const useCategorieContext = () => {
  return useContext(CategorieContext);
};

export const CategorieProvider = ({ children }) => {
  const [typeFormation, setTypeFormations] = useState([]);
  const [lieuformation, setLieuformation] = useState([]);
  const [formationDatas, setFormationDatas] = useState([]);
  const [evenementData, setEvenementData] = useState([]);
  const [citaionData,setCitationData] = useState([])
  const [temoignageData,setTemoignageData] = useState([])

  const fetchData = async () => {
    try {
      const typeFormationResponse = await typeFormationData();
      setTypeFormations(typeFormationResponse);

      const lieuformationResponse = await lieuFormation();
      setLieuformation(lieuformationResponse);

      const formationData = await formations();
      setFormationDatas(formationData);

      const evenementResponse = await getEvenements();
      setEvenementData(evenementResponse);

      const citationResponse = await getCitationsService()
      setCitationData(citationResponse)

      const temoignageResponse = await getTemoignagesService()
      setTemoignageData(temoignageResponse)

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateTypeFormation = async () => {
    await fetchData();
  };

  const updateFormationDatas = async () => {
    await fetchData();
  };

  const contextValue = {
    typeFormation,
    updateTypeFormation,
    lieuformation,
    formationDatas,
    updateFormationDatas,
    setFormationDatas,
    evenementData,
    setEvenementData,
    citaionData,
    setCitationData,
    temoignageData,
    setTemoignageData
  };

  return (
    <CategorieContext.Provider value={contextValue}>
      {children}
    </CategorieContext.Provider>
  );
};
