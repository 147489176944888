
import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { getInscriptionDataBySession, inscriptionSession, orangeMoneyApp } from '../../../services/inscription/service';
import { FadeLoader, RingLoader } from 'react-spinners';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { Navigate } from 'react-router-dom';

const OrangeMoney = (props) => {
    const { handleCloseOrangeMoney, showOrangeMoney,handleShowOrangeMoney,sessionformation,modaliteId,formation} = props;
    const [orangeOtp,setOranggeOtp] = useState(false)
    const [loading, setLoading] = useState(false);
    const [orangeQrcode,setOrangeQrcode] = useState('')
    const [telephone, setTelephone] = useState('');
    const [otpCode, setOtpCode] = useState('');
    const [otpCodeCorrect, setOtpCodeCorrect] = useState(true);
    const [telephoneCorrect, setTelephoneCorrect] = useState(true);
    const [inscriptionOm,setInscriptionOm] = useState('')
    const [isCreatedOm,setIscreatedOm] = useState(false)
    const [isCreated, setIsCreated] = useState(false);
    const [isError, setIsError] = useState({value:false,message:""});
    const [isMobile, setIsMobile] = useState(false);
    const [isWeb, setIsWeb] = useState(false);
    const [reloadQrcode,setReloadQrcode] = useState(false)
    const [isSubscribe,setIsSubscribe] = useState(false)

    const [canRedirect,setCanRedirect] = useState(false)
    
    const handleCloseIsWeb = ()=>{
        setIsWeb(false)
    }
    const handleCloseOrangeOtp = ()=>{
        setOranggeOtp(false)
    }
    const handleShowOrangeOtp = ()=> setOranggeOtp(true)

    const handleSubscribePayOtpOrange = async (e) =>{
        e.preventDefault();
        try {
            setLoading(true)
            const response = await inscriptionSession(sessionformation, modaliteId);
           
            setInscriptionOm(response)
            setIscreatedOm(true)
        } catch (error) {
            setIsError({value: true,message: `${error.response.data}`})
        }finally{
            setLoading(false); 
            handleCloseOrangeOtp();
            handleCloseOrangeMoney();
            //handleShowSucessInscription();
        }
    }

    const handleSubscribePayQrcodeOrange = async (e) =>{
        e.preventDefault();
        try {
            setLoading(true)
            setIsSubscribe(true)
            const response = await inscriptionSession(sessionformation, modaliteId);
           
            setInscriptionOm(response)
            const reponseqrcode = await orangeMoneyApp(sessionformation, response.id)
            setOrangeQrcode(reponseqrcode)
           console.log('reponse de qrcode: ',reponseqrcode);
        } catch (error) {
            setIsError({value: true,message: `${error.response.data}`})
        }finally{
            setLoading(false); 
            handleCloseOrangeOtp();
            handleCloseOrangeMoney();
            setIsSubscribe(false)
            //handleShowSucessInscription();
        }
    }

    const handleReloadQrcode = async (e) =>{
        e.preventDefault();
        try {
            setReloadQrcode(true)
            const responseStatus = await getInscriptionDataBySession(inscriptionOm.id)
            if (responseStatus) {
                if (responseStatus.status || responseStatus.status_incription ) {
                    setCanRedirect(true)
                }
            }
            const reponseqrcode = await orangeMoneyApp(sessionformation, inscriptionOm.id)
            //const reponseqrcode = await orangeMoneyApp(44, 125)
            setOrangeQrcode(reponseqrcode)
           
        } catch (error) {
            setIsError({value: true,message: `${error.response.data}`})
        }finally{
            setReloadQrcode(false) 
            
        }
    }
    useEffect(() => {
       
    }, [inscriptionOm]);

    useEffect(() => {
       if (orangeQrcode) {
        (window.innerWidth < 768 ) ? setIsMobile(true) : setIsWeb(true)
       }
    }, [orangeQrcode]);
    const buttonStylesOrange = {
        width: '100%',
        border: '2px solid rgba(8, 76, 148, 0.2)',
        height: '58px',
        borderRadius: '26px',
        padding: '0 18px',
        background: '#ff7f00',
    };
    const buttonStylesWave = {
        width: '100%',
        border: '2px solid rgba(8, 76, 148, 0.2)',
        height: '58px',
        borderRadius: '26px',
        padding: '0 18px',
    };
    const buttonStyles = {
        width: '100%',
        border: '2px solid rgba(8, 76, 148, 0.2)',
        height: '58px',
        borderRadius: '26px',
        padding: '0 18px',
    };
    const showSuccessAlert =  () => {
        Swal.fire({
          icon: "success",
          title: `Inscription à la formation ${formation.nom}`,
          text: "Votre inscription a été réalisée avec succès. Vous recevrez bientôt un email contenant des informations supplémentaires.",
          showConfirmButton: false,
          timer: 5000
        });
        setIsCreated(false)
      };

      const showErrorAlert = () =>{
        Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: isError.message,
          showConfirmButton: true,
          timer: 7000
        });
        setIsError({...isError,value:false})
      }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'telephone') {
            setTelephone(value);
            if (value.length > 0) {
                setTelephoneCorrect(/^(\+221|0)?(77|78)[0-9]{7}$/.test(value))
            }else{
                setTelephoneCorrect(true)
            }
        } else if (name === 'otpCode') {
            setOtpCode(value);
           if (value.length === 6) {
            setOtpCodeCorrect(true)
           }else{
            setOtpCodeCorrect(false)
           }
        }
    };

    if (isCreatedOm) {
        
        //return window.location.replace(`https://khoulefreres.com/api/wave/geturlpaiement/35/5/20`);

       return window.location.replace(`https://khoulefreres.com/api/orangeMoney/paiement/formation/${sessionformation}/${inscriptionOm.id}/${telephone}/${otpCode}`);
    }

    if (canRedirect) {
        return <Navigate to={`/inscription/session/${inscriptionOm.id}`} replace />;
    }

    if (isMobile) {
        return window.location.replace(`${orangeQrcode.deepLink}`);

    }
    
    return (
       <>
        { (isCreated || isCreatedOm) &&  showSuccessAlert()}
        {isError.value && showErrorAlert()}
     

        
        <Modal show={orangeOtp} 
               onHide={handleCloseOrangeOtp} 
               aria-labelledby="contained-modal-title-vcenter"
               centered>
            <Modal.Header closeButton>
                <Modal.Title>Pour payer via votre compte Orange money, vous devez avoir un code d'autorisation temporaire.Pour l'avoir tapez avec votre téléphone
#144#391*code_secret#</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            { loading ? 
                     <div  className="border d-flex align-items-center justify-content-center">
                     <RingLoader color="#fdc800" size='140' 
                     loading	={loading}
                    />
                    </div>:
                    <>
    <Form>
                    <Form.Group controlId="formBasicEmail" className={!telephoneCorrect ? 'single-input-inner-custome-error' : 'single-input-inner-custome'}>
                        <Form.Label>Numero telephone</Form.Label>
                        <Form.Control
                            type="tel"
                            name="telephone" // Correction: Le nom devrait être "email"
                            placeholder="Saisir le numero telphone"
                            value={telephone}
                            onChange={handleInputChange}
                        />
                        {!otpCodeCorrect &&
                            <Form.Text className="text-muted">
                                le numero telephone que vous avez saisi est incorrect.
                            </Form.Text>}
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword" className={!otpCodeCorrect ? 'single-input-inner-custome-error' : 'single-input-inner-custome'}>
                        <Form.Label>Code généré </Form.Label>
                        <Form.Control
                            type="text"
                            name="otpCode"
                            placeholder="Code généré"
                            value={otpCode}
                            onChange={handleInputChange}
                        />
                        {!otpCodeCorrect.correctPassword &&
                            <Form.Text className="text-muted">
                                Le Code généré que vous avez saisi est incorrect.
                            </Form.Text>}
                    </Form.Group>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-left">
                                <Button className="single-input-inner btn btn-secondary btn-radius"  size="lg" style={buttonStyles} onClick={(e) =>{
                                    handleCloseOrangeOtp()
                                    handleShowOrangeMoney()
                                    }}>
                                    Fermer
                                </Button>
                            </div>
                            <div className="col-md-6 text-right">
                                <Button className="btn btn-base btn-radius"  size="lg" style={buttonStyles} onClick={ (e) =>{
                                  handleSubscribePayOtpOrange(e)
                                }}
                                disabled={!(otpCodeCorrect && telephoneCorrect && otpCode.length > 0 && telephone.length > 0)}
                                
                                >
                                    Payer
                                </Button>
                            </div>
                        </div>
                    </div>

                   
                </Form>
               
                    </>
            }
        
            </Modal.Body>
        </Modal>
       
        <Modal show={showOrangeMoney} 
               onHide={handleCloseOrangeMoney} 
               size="lg"
               aria-labelledby="contained-modal-title-vcenter"
               centered>
            <Modal.Header closeButton>
                <Modal.Title>Merci de sélectionner votre mode de paiement parmi les options suivantes:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Button className="single-input-inner-custome"   style={buttonStylesOrange}
                                                                              onClick={(e) => {
                                                                                handleCloseOrangeMoney()
                                                                                handleShowOrangeOtp()} }>
                             Générer un code de paiement temporaire en composant #144#391# 
                            </Button>
                        </div>
                        <div className="col-md-12">
                            <Button className="single-input-inner-custome"  size="lg" style={buttonStylesWave}
                            onClick={(e) =>{
                                handleCloseOrangeMoney()
                                handleSubscribePayQrcodeOrange(e);
                            }}
                            >
                            Complétez votre paiement en utilisant l'application Orange Money.
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

        <Modal show={isWeb} 
               onHide={handleCloseIsWeb} 
               size="sm"
               aria-labelledby="contained-modal-title-vcenter"
               centered>
                {reloadQrcode ? 
                <>
                <Modal.Header closeButton>
                <Modal.Title>Chargement...</Modal.Title>
               </Modal.Header>
               <Modal.Body>
            <div className="container">
            <div className="row">
              <div className="col-sm-12 align-self-center">
              &nbsp;&nbsp;&nbsp;<FadeLoader
                                            color="rgba(255, 248, 56, 0.75)"
                                            height={22}
                                            margin={20}
                                            radius={10}
                                            speedMultiplier={1}
                                            width={51}
                                            />
              </div>
            </div>
          </div>
            </Modal.Body>
               
                </>:<>
                <Modal.Header closeButton>
                <Modal.Title> Pour payer les frais de formation,
                     utilisez l'application Orange Money pour scanner 
                     le code QR ci-dessous.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="page-content container">
            <div className="row">
              <div className="col-sm-12">
                <div className="panel panel-default">
                  <div className="panel-body" id="containerid">
                  
                    <div
                      className="d-flex"
                      style={{ justifyContent: 'center', display: 'flex' }}
                    >
                      <img src={`data:image/png;base64, ${orangeQrcode.qrCode}`} alt="QR Code" />
                    </div>
                    <Button className="single-input-inner-custome" onClick={(e) =>{handleReloadQrcode(e)}}>
                      Rafraîchir pour vérifier
                      <i className="fa fa-save"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
            </Modal.Body>
                </>}
            
        </Modal>

        <Modal show={isSubscribe} 
               aria-labelledby="contained-modal-title-vcenter"
               size="sm"
               centered>
            <Modal.Header closeButton>
                <Modal.Title>Veuillez patienter ...</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <FadeLoader
                                        color="rgba(255, 248, 56, 0.75)"
                                        height={22}
                                        margin={20}
                                        radius={10}
                                        speedMultiplier={1}
                                        width={51}
                                        />
        
            </Modal.Body>
        </Modal>
       </>
    );
}


export default OrangeMoney;
